import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import * as Yup from 'yup'
import { LinearProgress, Stack, TextField } from '@mui/material'
import { ErrorOutlined } from '@mui/icons-material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import axios from 'axios'

import GenericTable from '../../shared/GenericTable/GenericTable'
import ButtonComponent from '../../shared/ButtonComponent'
import EmailEditor from '../Editor/EmailEditor'
import {
	emailCheckPrintStyles,
	getCheckPrintContent,
	getChecks,
	htmlToPdf
} from '../../../utils/helper'
import { getEmailLinks, sendEmail } from '../../../API/EmailAPI'
import TextError from '../../../hoc/TextError'
import FormModalMUI from '../../shared/Modals/FormModalMUI'
import { useDispatch, useSelector } from 'react-redux'
import { updateSnackbar } from '../../../redux/snackbarState'

const EmailChecks = ({ selectedData, onClose, setSelectedRows }) => {
	const initialValues = {
		email: '',
		subject: 'You just got paid!'
	}

	const userData = useSelector((state) => state.appData.userData)

	const dispatch = useDispatch()

	const [signImagestr, setSignImageStr] = useState('')

	const [value, setValue] = React.useState('Template')
	const [modifiedData, setModifiedData] = React.useState([])
	const [isLoading, setIsLoading] = React.useState(true)
	const [totalCost, setTotalCost] = React.useState(0)
	const [pdfs, setPdfs] = React.useState([])
	const [emailsData, setEmailsData] = React.useState([])
	const [isConfirmSendModal, setIsConfirmSendModal] = React.useState(false)
	const [checkIds, setcheckIds] = React.useState([])
	const [alreadyUploadedFiles, setAlreadyUploadedFiles] = React.useState([])
	const [needToUploadFiles, setNeedToUploadFiles] = React.useState([])

	const [isSending, setIsSending] = React.useState(false)
	const [touched, setTouched] = React.useState(false)

	const [formValues, setformValues] = React.useState({ ...initialValues })

	const [content, setContent] = React.useState('')

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const validationSchema = Yup.object({
		email: Yup.string().email('Email Incorrect.').required('Required!'),
		subject: Yup.string().required('Required!')
	})

	React.useEffect(() => {
		if (selectedData.length !== 0 && signImagestr !== '') {
			setIsLoading(true)
			makeTableData(selectedData)
		} else {
			setIsLoading(false)
			setModifiedData([])
		}
	}, [selectedData, signImagestr])

	useEffect(() => {
		getImageBlob()
	}, [userData])

	const getImageBlob = () => {
		console.log('use4rdata signature url', userData?.data?.signatureUrl)
		axios
			.get(userData?.data?.signatureUrl, { responseType: 'blob' })
			.then((response) => {
				const imageBlob = response.data

				const reader = new FileReader()

				reader.onloadend = () => {
					const base64Image = reader.result.split(',')[1]

					setSignImageStr(base64Image)
				}

				reader.readAsDataURL(imageBlob)
			})
			.catch((err) => {
				getImageBlob()
			})
		// Convert the image blob to a base64 data URI
	}

	/// setting data for emails for To field
	React.useEffect(() => {
		if (selectedData.length !== 0 && signImagestr !== '') {
			let temp = []
			let tempcheckIds = []
			selectedData.forEach((item) => {
				if (!temp.includes(item?.payee?.email)) {
					temp.push(item?.payee?.email)
				}
				tempcheckIds.push(item._id)
			})

			setcheckIds([...tempcheckIds])

			let tempEmails = []
			let tempPdfs = []
			temp.forEach((email) => {
				let checks = []

				selectedData.forEach((item) => {
					let data = userData?.data
					if (email === item?.payee?.email) {
						let pdfRef = getCheckPrintContent({
							...item,
							userData: data,
							signImagestr
						})

						const jsxExp = ReactDOMServer.renderToString(pdfRef.current)

						const combinedHtml = `<style>${emailCheckPrintStyles}</style>${jsxExp}`

						checks.push(item._id)
						tempPdfs.push({ html: combinedHtml, checkId: item._id })
					}
				})
				tempEmails.push({ email: email, checks: [...checks] })
			})

			setPdfs([...tempPdfs])
			setEmailsData([...tempEmails])
			setformValues((prev) => ({
				...prev,
				email: temp && temp.length !== 0 ? temp.join(', ') : ''
			}))
		}
	}, [selectedData, userData, signImagestr])

	const columnData = [
		{
			key: '#',
			value: 'sno',
			colWidth: '2%',
			align: 'center',
			type: 'html'
		},
		{
			key: 'Check No.',
			value: 'checkNumber',
			colWidth: '10%',
			align: 'center',
			isSort: true,
			sortType: 'number'
		},
		{
			key: 'Amount',
			value: 'amount',
			colWidth: '10%',
			align: 'center',
			isSort: true,
			sortType: 'number'
		},
		{
			key: 'Issued date',
			value: 'issuedDate',
			colWidth: '13%',
			align: 'center',
			isSort: true,
			sortType: 'date'
		},
		{
			key: 'Bank NickName',
			value: 'bankNickname',
			colWidth: '12%',
			type: 'html',
			align: 'center'
		}
	]

	const makeTableData = (data) => {
		const temp = []
		let total = 0
		if (data && data.length !== 0) {
			data.forEach((item, index) => {
				let obj = {}
				let date = new Date(item?.issuedDate)
				obj.sno = index + 1
				obj.checkNumber = item.checkNumber
				obj.amount = '$' + item.amount
				total += item.amount
				obj.issuedDate = `${date.getDate()}/${
					date.getMonth() + 1
				}/${date.getFullYear()}`
				obj.bankNickname = item.bankDetails.accountNickName
				temp.push(obj)
				index++
			})
			setTotalCost(total)
			setModifiedData([...temp])
			setIsLoading(false)
		} else {
			setModifiedData([])
			setIsLoading(false)
		}
	}

	const processFiles = async (values) => {
		let pdfsResult = []
		setIsSending(true)

		dispatch(
			updateSnackbar({
				open: true,
				message: 'Processing your checks.',
				severity: 'info'
			})
		)

		for (let i = 0; i < pdfs.length; i++) {
			let pdfBlob = await htmlToPdf(pdfs[i])

			pdfsResult.push({ pdfBlob: pdfBlob, checkId: pdfs[i].checkId })
		}

		validateFiles(pdfsResult, values)
	}

	const validateFiles = async (files, values) => {
		let existingfiles =
			needToUploadFiles && needToUploadFiles.map((file) => file.checkId)

		const alreadyUpload = [...alreadyUploadedFiles]
		const needToUpload = [...needToUploadFiles]

		try {
			let res

			res = await getEmailLinks(
				files.map((file) => ({ checkId: file.checkId, forceUpdate: false }))
			)

			const { data } = res
			const { backendRes } = data

			const mergedArray = files.map((obj1) => {
				const obj2 = backendRes.find((obj) => obj.checkId === obj1.checkId)
				return { ...obj1, ...obj2 }
			})
			console.log('merged array', mergedArray)
			uploadAndSendEmail(mergedArray, values)
		} catch (err) {
			const { response } = err
			const { data } = response
			const { error } = data

			updateSnackbar({
				open: true,
				message: error?.userMessage
					? `${error?.userMessage}`
					: 'Unable process files.',
				severity: 'error'
			})
			setIsSending(false)
			return false
		}
	}

	const uploadAndSendEmail = async (files, values) => {
		const uploadRes = files.map((file, index) => {
			try {
				const res = axios.put(file.message, file.pdfBlob, {
					headers: {
						'Content-Type': file.pdfBlob.type
					}
				})

				return res
			} catch (error) {
				debugger
			}
		})

		Promise.all(uploadRes)
			.then(async (results) => {
				dispatch(
					updateSnackbar({
						open: true,
						message: 'File uploaded successfully.',
						severity: 'success'
					})
				)
				let sendResp = emailsData.map(async (data) => {
					console.log('unknown data', data)
					try {
						const res = await sendEmail({
							email: data?.email,
							checkIds: [...data?.checks],
							subject: values?.subject,
							content: `${content}`,
							ccEmail: []
						})

						return true
					} catch (err) {
						return false
					}
				})

				Promise.all(sendResp)
					.then((res) => {
						if (res?.includes(false)) {
							dispatch(
								updateSnackbar({
									open: true,
									message: 'Unable to send email.',
									severity: 'error'
								})
							)

							setIsSending(false)
						} else {
							dispatch(
								updateSnackbar({
									open: true,
									message: 'Email send successfully.',
									severity: 'success'
								})
							)

							setIsSending(false)
							setSelectedRows([])
							getChecks(dispatch)
							onClose()
						}
					})
					.catch((err) => {
						const { response } = err
						const { data } = response
						const { error } = data

						updateSnackbar({
							open: true,
							message: error?.userMessage
								? `${error?.userMessage}`
								: 'Unable to send email.',
							severity: 'error'
						})

						setIsSending(false)
					})
			})
			.catch((err) => {
				updateSnackbar({
					open: true,
					message: 'Unable to upload files.',
					severity: 'error'
				})
				setIsSending(false)
				return false
			})
	}

	return (
		<>
			<TabContext
				value={value}
				sx={{
					width: '100%',
					'& .MuiDialogContent-root': {
						width: '100%',
						backgroundColor: 'red'
					}
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label="lab API tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: '#3f7568'
							}
						}}
						variant="fullWidth"
					>
						<Tab
							label="Template"
							value="Template"
							style={{ color: '#3f7568' }}
							className="fw-bold"
						/>
						<Tab
							label="Checks"
							value="Checks"
							style={{ color: '#3f7568' }}
							className="fw-bold"
						/>
					</TabList>
				</Box>
				<TabPanel value="Template">
					<div
						className="mail-container"
						style={{ maxWidth: 'inherit', width: '100%' }}
					>
						<div
							className="mail-container-inner"
							style={{ maxWidth: 'inherit', width: '100%' }}
						>
							<Formik
								initialValues={formValues}
								validationSchema={validationSchema}
								onSubmit={processFiles}
								enableReinitialize={true}
							>
								{({ values, dirty, isValid }) => {
									return (
										<Form>
											<Field name="email" value={values?.email}>
												{({ field, form }) => {
													const { setFieldValue } = form

													console.log(
														'email form values',
														values,
														form.errors,
														field.name
													)

													return (
														<>
															<div className="form-div preffix d-flex align-items-center justify-content-start mb-3 position-relative">
																<div className="input-prefix">To</div>
																<TextField
																	placeholder="payee@organisation.com"
																	label=""
																	name={field.name}
																	className="custom-text-field"
																	value={field?.value}
																	onChange={(e) => {
																		setFieldValue(field.name, e.target.value)
																	}}
																	sx={{
																		width: '100%',
																		'& input': {
																			padding: '12px 0px',
																			fontSize: '12px',
																			color: '#757575'
																		},
																		'& fieldset': {
																			border: 'none'
																		}
																	}}
																/>
																{form?.errors && form?.errors?.subject ? (
																	<TextError>
																		{form.errors[field.name]}{' '}
																	</TextError>
																) : null}
															</div>
														</>
													)
												}}
											</Field>

											{/* <Field>
											{({ }) => {
												return (
													<>
													<div className="form-div preffix d-flex align-items-center justify-content-start mb-3">
							<div className="input-prefix">CC</div>
							<TextField
								placeholder="To CC"
								label=""
								className="custom-text-field"
								sx={{
									width: '100%',
									'& input': {
										padding: '15px 0px',
										fontSize: '14px',
										color: '#757575'
									},
									'& fieldset': {
										border: 'none'
									}
								}}
							/>
						</div></>
												)
											}}
										</Field> */}

											<Field name="subject" value={values.subject}>
												{({ field, form }) => {
													const { setFieldValue } = form

													return (
														<>
															<div className="form-div preffix d-flex align-items-center justify-content-start mb-3 position-relative">
																<div className="input-prefix d-flex align-items-center justify-content-start">
																	Subject{' '}
																	<span
																		className="ms-1"
																		style={{ color: 'red' }}
																	>
																		*
																	</span>
																</div>
																<TextField
																	placeholder="Your email subject"
																	label=""
																	className="custom-text-field"
																	name={field.name}
																	value={field.value}
																	onChange={(e) => {
																		setFieldValue(field.name, e.target.value)
																	}}
																	sx={{
																		width: '100%',
																		'& input': {
																			padding: '12px 0px',
																			fontSize: '12px',
																			color: '#757575'
																		},
																		'& fieldset': {
																			border: 'none'
																		}
																	}}
																/>
																{form?.errors && form?.errors?.subject ? (
																	<TextError>Required!</TextError>
																) : null}
															</div>
														</>
													)
												}}
											</Field>

											<EmailEditor
												content={content}
												setContent={setContent}
												setTouched={setTouched}
											/>
											<div className="d-flex align-items-center justify-content-end mt-3">
												<ButtonComponent
													text="Send"
													variant="dark"
													disabled={!isValid}
													//icon={<AlternateEmailOutlined />}
													type="submit"
													//click={() => processFiles(values)}
													//onClick={() => processFiles(values)}
													extraClass="me-3"
													//disabled={selectedRows.length === 0 ? true : false}
												/>
											</div>
										</Form>
									)
								}}
							</Formik>
						</div>
					</div>
				</TabPanel>
				<TabPanel value="Checks">
					<div className="d-block">
						<div className="fw-bold text-secondary float-start">
							<small>Selected Checks</small>
						</div>
						<div
							className="alert alert-success p-0 fw-bold"
							style={{ width: 'fit-content', margin: '0 auto' }}
						>
							<small className="px-2 fs-12">
								Total Cost : $ {totalCost.toFixed(2)}
							</small>
						</div>
						<div className="fw-bold text-secondary" />
					</div>
					<div
						className="generic-table-container"
						style={{ maxWidth: 'inherit', width: '100%', height: '55vh' }}
					>
						<GenericTable
							columnData={columnData}
							modifiedData={modifiedData}
							count={selectedData?.data?.length || 0}
							isLoading={isLoading}
							initialfilter={''}
							height={'45vh'}
						/>
					</div>
					<div className="d-flex justify-content-end">
						<ButtonComponent text="Save" variant="dark" type="button" />
					</div>
				</TabPanel>
				{isConfirmSendModal && (
					<FormModalMUI
						onClose={() => {
							setIsConfirmSendModal(!isConfirmSendModal)
						}}
						open={isConfirmSendModal}
						maxWidth="sm"
						// onSave={onSubmit}
					>
						<div className="container">
							<div className="">
								<div className="d-flex align-items-center justify-content-center txt-danger mt-3 mb-2">
									<ErrorOutlined sx={{ fontSize: '80px' }} />
								</div>
								<div className="col d-flex justify-content-center">
									<div className="row">
										<h3>
											<p>
												<b>Confirm Deletion?</b>
											</p>
										</h3>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col d-flex justify-content-center">
									<div className="row">
										<p className="text-center m-0 mb-2">
											{alreadyUploadedFiles.length}{' '}
											{alreadyUploadedFiles?.length > 1 ? 'checks' : 'check'}{' '}
											already exist. You want to re-upload that?
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
							<ButtonComponent
								text="Cancel"
								type="button"
								variant="light"
								click={() => {
									setIsConfirmSendModal(!isConfirmSendModal)
								}}
								extraClass="me-3"
							/>
							<ButtonComponent
								text={'Continue'}
								type="submit"
								variant="danger"
								//click={deleteUserTag}
							/>
						</div>
					</FormModalMUI>
				)}
			</TabContext>
			{isSending ? (
				<div className="modal-loader">
					<div className="modal-loader-inner">
						<Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
							<LinearProgress color="success" />
						</Stack>
					</div>
				</div>
			) : null}
		</>
	)
}

export default EmailChecks
