import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router'
import ButtonComponent from '../components/shared/ButtonComponent'
import {
	IconButton,
	Menu,
	MenuItem,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import { useReactToPrint } from 'react-to-print'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

// import PrintableComponent from '../components/views/PrintInvoice'
import { Button, Card, CardContent, Typography, Divider } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
// import PrintableComponent1 from '../components/views/PrintPDF'

// ContextMenu component as before

const InvoiceChecks = () => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)

	// Static data
	const staticOrganizationsData = [
		{
			organizationName: '#162738',
			entityType: 'Mar 15 2024',
			industryType: 'Industry 1',
			dueOn: 'Mar 18 2024',
			entityType: 'Mar 15 2024',
			status: 'Paid',
			total: '($ 14.4) usd'
		},
		{
			organizationName: '#162739',
			entityType: 'Mar 16 2024',
			industryType: 'Industry 2',
			dueOn: 'Mar 18 2024',
			status: 'Open',
			total: '($ 14.4) usd'
		},
		{
			organizationName: '#162738',
			entityType: 'Mar 15 2024',
			industryType: 'Industry 1',
			dueOn: 'Mar 18 2024',
			entityType: 'Mar 15 2024',
			status: 'Paid',
			total: '($ 14.6) usd'
		},
		{
			organizationName: '#162739',
			entityType: 'Mar 16 2024',
			industryType: 'Industry 2',
			dueOn: 'Mar 18 2024',
			status: 'Open',
			total: '($ 14.6) usd'
		},
		{
			organizationName: '#162738',
			entityType: 'Mar 15 2024',
			industryType: 'Industry 1',
			dueOn: 'Mar 18 2024',
			entityType: 'Mar 15 2024',
			status: 'Paid',
			total: '($ 14.1) usd'
		},
		{
			organizationName: '#162739',
			entityType: 'Mar 16 2024',
			industryType: 'Industry 2',
			dueOn: 'Mar 18 2024',
			status: 'Open',
			total: '($ 14.2) usd'
		}
	]

	const columnData = [
		{
			key: 'Invoice',
			value: 'organizationName',
			colWidth: '5%'
		},
		{
			key: 'Issued On',
			value: 'entityType'
		},
		{
			key: 'Due On',
			value: 'dueOn'
		},
		{
			key: 'Status',
			value: 'status'
		},
		{
			key: 'Total',
			value: 'total'
		}
	]

	const makeTableData = (data) => {
		let temp = []
		data.forEach((item, index) => {
			let obj = {}
			obj.organizationName = (
				<span style={{ color: 'green', fontWeight: 'bold' }}>
					{item.organizationName || ''}
				</span>
			)

			obj.entityType = item.entityType || ''
			obj.dueOn = item.dueOn || ''
			obj.status = item.status || ''
			// obj.total = item.total || ''

			obj.total =
				item.organizationLogo && item.organizationLogo !== '' ? (
					<img
						className="org-logo-small"
						src={item.organizationLogo}
						alt="org-logo"
					/>
				) : (
					<div className="no-org-logo">
						{(obj.total = item.total || '')}&nbsp;&nbsp;
						<FileDownloadIcon
							sx={{
								width: '1rem',
								height: '1rem',
								color: '#204464'
							}}
						/>{' '}
					</div>
				)

			obj.industryType = item.industryType || ''
			temp.push(obj)
		})

		return temp
	}

	// Process static data
	const modifiedData = makeTableData(staticOrganizationsData)

	// const printRef = useRef(null)
	const componentRef = useRef()
	const componentRef1 = useRef()
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	const handleDownloadPDF = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
		});

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'normal');

		doc.html(componentRef1.current, {
			async callback(doc) {
				await doc.save('document');
			},
		});
	};

	const downloadPDF = () => {
		const input = document.getElementById('invoice');
		html2canvas(input, { scale: 3 }).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'mm', 'a4');

			// Adjust the dimensions and position of the image in the PDF
			const imgProps = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

			// If the height of the rendered image is greater than the page height, scale down
			const ratio = Math.min(pdfWidth / imgProps.width, pdf.internal.pageSize.getHeight() / imgProps.height);
			const width = imgProps.width * ratio;
			const height = imgProps.height * ratio;

			// Add the image to the PDF and save it
			pdf.addImage(imgData, 'PNG', 0, 0, width, height);
			pdf.save('invoice.pdf');
		});
	};

	const printData = {
		title: 'Invoice',
		date: 'March 19, 2024',
		amount: '$119.88',
	  };

	return (
		<>
			<div
				className="container"
				style={{
					borderColor: '#bbbbbb',
					borderWidth: '0.5px',
					padding: '20px 0px 0'
				}}
			>
				<div className="page-header d-flex align-items-center justify-content-between mb-4 pb-4">
					<h3
						className="fs-5 fw-semibold m-0"
						style={{
							color: '#12221fbf'
						}}
					>
						Invoice
					</h3>
				</div>
				<div
					className="row"
					style={{ marginTop: '50px', marginBottom: '40px' }}
				>
					<div className="col-12">
						<div
							className="container"
							style={{
								borderRadius: '12px',
								borderStyle: 'solid',
								borderColor: '#bbbbbb',
								borderWidth: '0.5px',
								padding: '20px 20px 0'
							}}
						>
							
							<div className="row">
								<div className="content">
									<div className="subscriptionsIn">
												<div className='mb-5'>
													<Typography variant="body2">
                                                    <Typography variant="body1">
												<strong>Email Subject: </strong>	
                                                Check Shipment Request Received from [ORG_NAME] On [MM/DD/YYYY]
											</Typography>
                                          
													</Typography>
                                                    <Typography variant="body1">
												<strong>Email Body:</strong><br/><br/>Below are the check shipment request received. Please find attached PDF to print the check.	
											</Typography>
												</div>

												{/* <div style={{ marginTop: '30px' }}>
													<Typography variant="body2">Bill To</Typography>
													<Typography variant="body2">
														<strong>Leon Dev</strong>
													</Typography>
													<Typography variant="body2">
														1369 Woodland Avenue,USA New York
													</Typography>
												</div> */}
									</div>
									{/* <div
										className="subscriptions"
										style={{
											borderRadius: '12px',
											borderWidth: '0.5px',
											padding: '20px 20px 0',
											height: '80%'
										}}
									>
										<div className="flex-container">
											<Typography variant="body1">
												<strong>Invoice</strong>
											</Typography>
										</div>
										<div
											className="flex-container"
											style={{ marginTop: '10px' }}
										>
											<Typography variant="body2">Invoice #</Typography>
											<Typography variant="body2" style={{ marginLeft: '40%' }}>
												#7665746
											</Typography>
										</div>
										<div className="flex-container">
											<Typography variant="body2">Billed On</Typography>
											<Typography variant="body2" style={{ marginLeft: '40%' }}>
												Jun 21, 2024
											</Typography>
										</div>
										<div className="flex-container">
											<Typography variant="body2">Terms</Typography>
											<Typography variant="body2" style={{ marginLeft: '43%' }}>
												On Receipt
											</Typography>
										</div>
										<div className="flex-container">
											<Typography variant="body2">Duo On</Typography>
											<Typography variant="body2" style={{ marginLeft: '41%' }}>
												Jul 21, 2024
											</Typography>
										</div>
										<Card
											style={{
												backgroundColor: '#ffffff',
												padding: '16px',
												width: '65%',
												border: '2px solid #ddd',
												borderRadius: '10px',
												marginTop: '2%'
											}}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													marginBottom: '8px'
													// backgroundColor: 'green'
												}}
											>
												<div
													style={{
														backgroundColor: '#A6CE39',
														color: 'white',
														padding: '4px 8px',
														borderRadius: '4px',
														fontWeight: 'bold'
													}}
												>
													PAID
												</div>
												<Typography
													variant="body2"
													style={{ marginLeft: 'auto' }}
												>
													on Feb 19, 2024
												</Typography>
											</div>
											<Divider
												style={{
													margin: '8px 0',
													height: '1px',
													backgroundColor: '#ddd'
												}}
											/>
											<Typography
												variant="h5"
												style={{
													fontWeight: 'bold',
													display: 'flex',
													justifyContent: 'center'
												}}
											>
												$30.00 USD
											</Typography>
										</Card>
									</div> */}
								</div>
								{/* <Divider
									style={{
										margin: '10px 10px 16px 0px',
										height: '2px',
										backgroundColor: 'black'
									}}
								/> */}
                                <div className="flex-container">
											{/* <Typography variant="body1">
												<strong>Total Checks : 3 </strong>
											</Typography> */}
                                            {/* <div
													style={{
														backgroundColor: '#A6CE39',
														color: 'white',
														padding: '4px 8px',
														borderRadius: '4px',
														fontWeight: 'bold',
                                                        borderRadius:"50%"
													}}
												>
													3
												</div> */}
										</div>
                                {/* <Card
					style={{
						backgroundColor: '#ffffff',
						padding: '16px',
						width: '100%', // {{ edit_3 }} Adjusted width
						// border: '2px solid #ddd',
						// borderRadius: '10px',
						marginTop: '2%',
						// boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // {{ edit_4 }} Added shadow effect
					}}
				> */}
                    <div className="flex-container">
											<Typography variant="body1">
												<strong>Payee Name : 	
                                                Jason</strong>
											</Typography>
										</div>
								<TableContainer style={{ maxWidth: "60%"}}>
									<Table>
										<TableHead>
											<TableRow>
												{/* <TableCell>
													<b>Sr No.</b>
												</TableCell> */}
												<TableCell>
													<b>Check No</b>
												</TableCell>
												{/* <TableCell >
													<b>Payee Name</b>
												</TableCell> */}
												<TableCell >
													<b>Address</b>
												</TableCell>
												<TableCell >
													<b>Amount</b>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
                                            {/* <TableCell>1</TableCell> */}
												<TableCell>00003</TableCell>
												{/* <TableCell >Leon</TableCell> */}
												<TableCell >123 Broadway Street, New York, NY 10019 United States</TableCell>
												<TableCell >$30.00</TableCell>
											</TableRow>
                                            {/* <TableCell>2</TableCell> */}
												<TableCell>00004</TableCell>
												{/* <TableCell >Leon</TableCell> */}
												<TableCell >	
                                                123 Broadway Street, New York, NY 10019 United States</TableCell>
												<TableCell >$30.00</TableCell>
										</TableBody>
									</Table>
								</TableContainer>
                                {/* </Card> */}
                                {/* <Card
					style={{
						// backgroundColor: '#ffffff',
						padding: '16px',
						width: '100%', // {{ edit_3 }} Adjusted width
						// border: '1px solid #ddd',
						// borderRadius: '10px',
						marginTop: '2%',
						// boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // {{ edit_4 }} Added shadow effect
					}}
				> */}
                    <div className="flex-container" style={{marginTop:"30px"}}>
											<Typography variant="body1">
												<strong>Payee Name : Peter</strong>
											</Typography>
										</div>
								<TableContainer style={{ maxWidth: "60%"}}>
									<Table>
										<TableHead>
                                        {/* <TableCell>
													<b>Sr No.</b>
												</TableCell> */}
												<TableCell>
													<b>Check No</b>
												</TableCell>
												{/* <TableCell >
													<b>Payee Name</b>
												</TableCell> */}
												<TableCell >
													<b>Address</b>
												</TableCell>
												<TableCell >
													<b>Amount</b>
												</TableCell>
										</TableHead>
										<TableBody>
											<TableRow>
												{/* <TableCell>1</TableCell> */}
												<TableCell>00006</TableCell>
												{/* <TableCell >Leon</TableCell> */}
												<TableCell >456 John Street, Brooklyn, NY 10025 United States</TableCell>
												<TableCell >$30.00</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
                                {/* </Card> */}

								<Divider style={{ margin: '20px 0' }} />

								{/* <div
									style={{
										display: 'flex',
										justifyContent: 'end',
										marginBottom: '10px'
									}}
								>
									<Typography variant="body2" style={{ marginRight: '10%' }}>
										Subtotal
									</Typography>
									<Typography variant="body2">$30.00</Typography>
								</div> */}
								<div
									style={{
										display: 'flex',
										justifyContent: 'start',
										marginBottom: '10px'
									}}
								>
									<Typography variant="body2" style={{ marginRight: '50px',fontSize:"18px",marginTop:"1px" }}>
										<b>Shipping Type</b>
									</Typography>
									{/* <Typography variant="body1"><b>Express</b></Typography> */}
                                    <div
													style={{
														backgroundColor: 'rgb(139 225 60)',
														color: 'white',
														padding: '4px 8px',
														borderRadius: '4px',
														fontWeight: 'bold',
                                                        // borderRadius:"50%"
													}}
												>
													Express
												</div>
								</div>
								{/* <div
									style={{
										display: 'flex',
										justifyContent: 'end',
										marginBottom: '10px'
									}}
								>
									<Typography variant="body2" style={{ marginRight: '139px' }}>
										Paid
									</Typography>
									<Typography variant="body2">($30.00)</Typography>
								</div> */}
								{/* <Divider
									style={{
										margin: '0px 0px 0px 80%',
										height: '2px',
										backgroundColor: 'black',
										width: '20%'
									}}
								/>
								<div
									style={{
										display: 'flex',
										justifyContent: 'end',
										fontWeight: 'bold',
										marginTop: '10px'
									}}
								>
									<Typography variant="body2" style={{ marginRight: '115px' }}>
										Amount Due
									</Typography>
									<Typography variant="body2">$0.00</Typography>
								</div> */}

								<Divider style={{ margin: '20px 0' }} />

								{/* <Typography variant="body2" style={{ marginBottom: '10px' }}>
									<strong>Payments</strong>
								</Typography>
								<Typography variant="body2" style={{ marginBottom: '20px' }}>
									Jun 21, 2024 $30.00 Payment from American Express ... 1040
								</Typography>

								<Typography variant="body2">
									<strong>Notes</strong>
								</Typography>
								<Typography variant="body2" style={{ paddingBottom: '20px' }}>
									All amounts in United States Dollars (USD)
								</Typography> */}
							</div>
                            <div className='mb-4'>
                        Thanks,<br></br>
                        Synccos Team
                    </div>
						</div>
					</div>
                    
				</div>
			</div>
		</>
	)
}

export default InvoiceChecks
