import React,{useEffect} from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Home from '../pages/Home'
import Terms from '../pages/Terms'
import AuthLayout from '../components/Layout/AuthLayout'
import DashboardLayout from '../components/Layout/DashboardLayout'
import Cookies from 'js-cookie'
import PrivateRoute from './PrivateRoutes'
import Privacy from '../pages/Privacy'
import ErrorBoundary from '../utils/hoc/ErrorBoundary'
import NotFound from '../pages/NotFound'
import CreateOrganization from '../pages/CreateOrganization'
import Subscription from '../pages/Subscription'
import SubscriptionFailure from '../pages/SubscriptionFailure'
import { useDispatch, useSelector } from 'react-redux'

const AppRoutes = () => {
	const history = useHistory()
	let isLoggedIn = useSelector((state) => state.loginLogout.isLoggedIn)
	// console.log(isLoggedIn,"LoggedIn")


	

	
	return (
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/terms" component={Terms} />
			<Route exact path="/privacy" component={Privacy} />
			<Route path="/auth" component={AuthLayout} />
			{/* <Route path="/auth" render={() => (
				isLoggedIn ? <Redirect to="/dashboard" /> : <AuthLayout />
			)} /> */}
			<Route exact path="/subscription" component={Subscription} />
			<Route exact path="/subscription/failure" component={SubscriptionFailure} />
			<PrivateRoute exact path="/create-organization" component={CreateOrganization} />
			<PrivateRoute path="/dashboard" component={DashboardLayout} />
			<Route path="/not-found" component={NotFound} />
			<Redirect to="/not-found" />
		</Switch>
	)
}

export default AppRoutes
