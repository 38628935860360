import React, { useState } from 'react'
import {
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Icon,
	Divider,
	IconButton,
	Tooltip
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import {
	AccountBalanceOutlined,
	AccountCircleOutlined,
	AddCircleOutline,
	ApartmentOutlined,
	BusinessOutlined,
	ChevronRight,
	DashboardOutlined,
	DescriptionOutlined,
	ErrorOutline,
	ErrorOutlined,
	ExitToAppOutlined,
	FormatListBulletedOutlined,
	LocalShippingOutlined,
	LogoutOutlined,
	PaidOutlined,
	Payment,
	ReceiptLong
} from '@mui/icons-material'
import { checkIcon, checkWriterLogo ,checkWriterLogo1,onlyLogo } from '../../assets/svg'
import { useHistory, useLocation } from 'react-router'

import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { logOut } from '../../redux/loginLogout'
import { emptyAppData } from '../../redux/appData'
import FormModalMUI from '../shared/Modals/FormModalMUI'
import ButtonComponent from '../shared/ButtonComponent'

const Sidebar = ({ open, setOpen }) => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()

	const [logoutModal, setLogoutModal] = useState(false)

	const drawerWidth = 220

	const openedMixin = (theme) => ({
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		overflowX: 'hidden'
	})

	const closedMixin = (theme) => ({
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up('sm')]: {
			width: `calc(${theme.spacing(8)} + 1px)`
		}
	})

	const Drawer = styled(MuiDrawer, {
		shouldForwardProp: (prop) => prop !== 'open'
	})(({ theme, open }) => {
		return {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
			boxSizing: 'border-box',

			...(open && {
				...openedMixin(theme),
				'& .MuiDrawer-paper': openedMixin(theme)
			}),
			...(!open && {
				...closedMixin(theme),
				'& .MuiDrawer-paper': closedMixin(theme)
			})
		}
	})

	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	}))

	const logout = () => {
		dispatch(logOut())
		dispatch(emptyAppData())
		Cookies.remove('refresh-token')
		Cookies.remove('access-token')
		Cookies.remove('user-status')
		window.sessionStorage.removeItem('user-memory')
		history.push('/')
	}

	const sidebarList = [
		{
			icon: <DashboardOutlined />,
			title: 'Dashboard',
			path: '/dashboard/main'
		},
		{
			icon: <AddCircleOutline />,
			title: 'Create check',
			path: '/dashboard/create-check'
		},
		{
			icon: checkIcon,
			title: 'My checks',
			path: '/dashboard/my-checks'
		},
		// {
		// 	icon: <DescriptionOutlined />,
		// 	title: 'Reports',
		// 	path: '/dashboard/reports'
		// },
		{
			icon: <FormatListBulletedOutlined />,
			title: 'Payee List',
			path: '/dashboard/payees'
		},
		{
			icon: <AccountBalanceOutlined />,
			title: 'Bank Accounts',
			path: '/dashboard/bank-accounts'
		},
		// {
		// 	icon: <AccountCircleOutlined />,
		// 	title: 'Profile',
		// 	path: '/dashboard/profile'
		// },
		{
			icon: <LocalOfferOutlinedIcon />,
			title: 'My Tags',
			path: '/dashboard/my-tags'
		},
		{
			icon: <ReceiptLong />,
			title: 'Check Register',
			path: '/dashboard/check-register'
		},
		{
			icon: <LocalShippingOutlined />,
			title: 'All Mails',
			path: '/dashboard/all-orders'
		},
		{
			title: 'Divider'
		},
		{
			title: 'Module Head',
			header: 'Receivables'
		},
		{
			icon: <Payment />,
			title: 'Payment Links',
			path: '/dashboard/payment-links'
		},
		{
			title: 'Divider'
		},
		{
			title: 'Module Head',
			header: 'Integrations'
		},
		{
			icon: <PaidOutlined />,
			title: 'Payments',
			path: '/dashboard/integrations/payments'
		}
	]

	return (
		<>
			<Drawer
				variant="permanent"
				open={open}
				className="app-sidebar"
				sx={{
					zIndex: '20'
				}}
			>
				<div className="drawer-header">
					<DrawerHeader
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
					>
						<div
							className="sidebar-logo d-flex align-items-center flex-row"
							onClick={() => {
								setOpen(!open)
							}}
							// style={{width:"60%"}}
						>
							  {checkWriterLogo1}<div className='sidebar-logo1'>{open && onlyLogo} </div>
						</div>
						<IconButton
							onClick={() => {
								setOpen(!open)
							}}
							sx={{
								display: open ? 'flex' : 'none'
							}}
						>
							{open ? <ChevronLeftIcon /> : <ChevronRight />}
						</IconButton>
					</DrawerHeader>
				</div>

				<List className="app-sidebar-list">
					{sidebarList.map((item, index) => {
						if (item.title === 'Divider') {
							return (
								<Divider
									key={index}
									sx={{
										margin: '0px 0 15px',
										borderColor: 'rgba(0,0,0,0.5)'
									}}
								/>
							)
						} else if (item?.title === 'Module Head') {
							return (
								<h3 className="fs-12 ms-3 mb-3 text-secondary fw-semibold">
									{item?.header}
								</h3>
							)
						} else {
							return (
								<ListItem
									key={index}
									className={`sidebar-list-item ${
										location.pathname.includes(item.path) ? 'active' : ''
									}`}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'centere'
									}}
								>
									<Tooltip
										disableHoverListener={open}
										title={item.title}
										placement="right"
									>
										<ListItemButton
											sx={{
												justifyContent: open ? 'center' : 'center',
												alignItems: 'center'
											}}
											className="sidebar-list-icon-btn"
											onClick={() => {
												history.push(item.path)
											}}
										>
											<Icon
												sx={{
													minWidth: 0,
													mr: open ? 2 : 'auto',
													display: 'inline-flex',
													alignItems: 'center',
													width: '18px',
													height: '18px',
													'& svg': {
														width: '18px',
														height: '18px'
													}
												}}
												className="sidebar-list-icon"
											>
												{item.icon}
											</Icon>
											<ListItemText
												primary={item.title}
												sx={{
													opacity: open ? 1 : 0,
													'&.MuiListItemText-root': {
														'& span': { fontSize: '12px' }
													}
												}}
												className="sidebar-list-text"
											/>
										</ListItemButton>
									</Tooltip>
								</ListItem>
							)
						}
					})}
					{/* <ListItem
						className="sidebar-list-item"
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'centere'
						}}
					>
						<Tooltip
							disableHoverListener={open}
							title="Logout"
							placement="right"
						>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									alignItems: 'center',
									px: 2.5
								}}
								className="sidebar-list-icon-btn"
								onClick={() => {
									setLogoutModal(true)
								}}
							>
								<Icon
									sx={{
										minWidth: 0,
										mr: open ? 2 : 'auto',
										display: 'inline-flex',
										alignItems: 'center'
									}}
									className="sidebar-list-icon"
								>
									<ExitToAppOutlined />
								</Icon>
								<ListItemText
									primary="Logout"
									sx={{
										opacity: open ? 1 : 0,
										'&.MuiListItemText-root': {
											'& span': { fontSize: '14px' }
										}
									}}
									className="sidebar-list-text"
								/>
							</ListItemButton>
						</Tooltip>
					</ListItem> */}
				</List>
			</Drawer>

			<FormModalMUI
				open={logoutModal}
				onClose={() => {
					setLogoutModal(false)
				}}
				maxWidth="sm"
			>
				<div class="container" style={{ width: '450px' }}>
					<div class="row">
						<div class="d-flex align-items-center justify-content-center txt-danger mt-3 mb-2">
							<ErrorOutline sx={{ fontSize: '80px' }} />
						</div>
						<div class="col d-flex justify-content-center">
							<div class="row">
								<h3>
									<p>
										<b>Logout</b>
									</p>
								</h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col d-flex justify-content-center">
							<div class="row text-center">
								<p>Are you sure you want to logout?</p>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
					<ButtonComponent
						text="Cancel"
						type="button"
						variant="light"
						click={() => {
							setLogoutModal(false)
						}}
						extraClass="me-3"
					/>
					<ButtonComponent
						text={'Logout'}
						type="submit"
						variant="danger"
						click={logout}
					/>
				</div>
			</FormModalMUI>
		</>
	)
}

export default Sidebar
