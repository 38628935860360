import React from 'react'
import { useHistory } from 'react-router'

import notFoundImg from '../assets/images/notFoundImg.png'
import ButtonComponent from '../components/shared/ButtonComponent'

const NotFound = () => {
	const history = useHistory()

	return (
		<div className="container-fluid p-0 m-0 position-relative">
			<div
				className="d-flex align-items-center justify-content-center flex-column"
				style={{
					height: '100vh'
				}}
			>
				<img className="not-found-img mb-4" src={notFoundImg} alt="not-found" />
				<h2
					className="fs-3"
					style={{
						color: '#212121'
					}}
				>
					Page Not found
				</h2>
				<p
					className="fs-6"
					style={{
						color: '#757575'
					}}
				>
					The page/data you were searching for couldn’t be found.
				</p>
				<ButtonComponent
					text={'Return to dashboard'}
					variant="dark"
					type="button"
					click={() => {
						history.push('/dashboard/main')
					}}
				/>
			</div>
		</div>
	)
}

export default NotFound
