import axios from 'axios'
import Cookies from 'js-cookie'

let token

let instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 100000,
	headers: {
		Authorization: token
	}
})

instance.interceptors.request.use(async(config) => {
	let token = ''

	if (config.url.includes('refresh-token')) {
		token = Cookies.get('refresh-token')
		// console.log(token)
	} else {
		token = Cookies.get('access-token')
		// console.log(token)
	}

	config.headers.Authorization = token

	if (Cookies.get('organization')) {
		config.headers.organizationId = Cookies.get('organization')
	}

	return config
	
})

const getRequest = (API, body) => instance.get(API, body)

const postRequest = (API, body) => instance.post(API, body)

const putRequest = (API, body) => instance.put(API, body)

const deleteRequest = (API, body) => instance.delete(API, body)

export { getRequest, postRequest, instance, putRequest, deleteRequest }
