import { useEffect, useState } from 'react'
import { Snackbar, Alert } from '@mui/material'
import Cookies from 'js-cookie'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useLocation } from 'react-router-dom'
import AppRoutes from './routes'
import AppLayout from './components/Layout/AppLayout'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './App.css'
import './styles/ContentPageStyles.css'
import './styles/CompanyFormStyles.css'
import './styles/IntegrationsStyle.css'

import 'react-phone-input-2/lib/material.css'
import { useDispatch, useSelector } from 'react-redux'
import { closeSnackbar } from './redux/snackbarState'
import { refreshAccessToken } from './API/AuthAPI'
import { checkData, logOut, login } from './redux/loginLogout'
import {
	getAddresses,
	getBanks,
	getChecks,
	getPayees,
	getUserData,
	getTagsData,
	getTransactions,
	fetchTags,
	fetchGroups,
	getOrganizations
} from './utils/helper'
import Tidio from './utils/Tidio'
import ProgressWorkModal from './components/shared/Modals/ProgressWorkModal'
import { emptyAppData, updateSelectedOrganization } from './redux/appData'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getAllGroups } from './API/GroupAPI'
import ErrorBoundary from './utils/hoc/ErrorBoundary'
import NoInternetConnection from './pages/NoInternetConnection'

const App = () => {
	const { open, message, severity } = useSelector(
		(state) => state.snackbarState
	)
	const dispatch = useDispatch()
	const history = useHistory()

	let isLoggedIn = useSelector((state) => state.loginLogout.isLoggedIn)
	let token = useSelector((state) => state.loginLogout.token)

	const [isOnline, setIsOnline] = useState(navigator.onLine)

	useEffect(() => {
		const handleOnline = () => setIsOnline(true)
		const handleOffline = () => setIsOnline(false)

		window.addEventListener('online', handleOnline)
		window.addEventListener('offline', handleOffline)

		return () => {
			window.removeEventListener('online', handleOnline)
			window.removeEventListener('offline', handleOffline)
		}
	}, [])

	const getAccessToken = async () => {
		try {
			const res = await refreshAccessToken()
			const { data } = res

			Cookies.set('access-token', data.accessToken, {
				expires: 1 / 24
			})

			dispatch(login({ token: data.accessToken }))
		} catch (err) {
			dispatch(logOut())
			dispatch(emptyAppData())
			Cookies.remove('refresh-token')
			Cookies.remove('access-token')
			Cookies.remove('user-status')
			Cookies.remove('subscription')
			window.sessionStorage.removeItem('user-memory')

			history.push('/')
		}
	}

	useEffect(() => {
		dispatch(checkData())
	}, [dispatch])

	useEffect(() => {
		let interval

		if (isLoggedIn && isOnline) {
			getAccessToken()
			interval = setInterval(() => {
				if (isOnline) {
					getAccessToken()
				}
			}, 1000 * 60 * 10)
		}

		return () => clearInterval(interval)
	}, [isLoggedIn, isOnline])

	useEffect(() => {
		let dataInterval

		const fetchData = () => {
			getChecks(dispatch)
			getBanks(dispatch)
			getAddresses(dispatch)
			getPayees(dispatch)
			getUserData(dispatch)
			getTransactions(dispatch)
			getOrganizations(dispatch)
			fetchTags(dispatch)
			fetchGroups(dispatch)
			dispatch(
				updateSelectedOrganization(
					Cookies?.get('organization') ? Cookies?.get('organization') : 'No Org'
				)
			)
		}

		if (isLoggedIn && isOnline) {
			fetchData()
			dataInterval = setInterval(() => {
				if (isOnline) {
					fetchData()
				}
			}, 1000 * 60 * 30)
		}

		return () => clearInterval(dataInterval)
	}, [isLoggedIn, token, isOnline])

	const location = useLocation()
	useEffect(() => {
        if (location.pathname === '/dashboard/my-checks') {
            getChecks(dispatch)
        }
    }, [location.pathname, dispatch])
   
	useEffect(() => {
		if (Cookies.get('direct-token-ref') !== 'checkwriter' && Cookies.get('direct-token') && !Cookies.get('access-token')) {
			const fetchData = async () => {
				try {
					const directToken = Cookies.get('direct-token');
	
					const requestOptions = {
						method: 'POST', // Change the method as needed
						headers: {
							'Authorization': `${directToken}`,
							'Content-Type': 'application/json'
						}
					};
					// Make API call to get new tokens
					const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/verifyuser-and-getnewtoken`, requestOptions);
					
	
					// Update tokens and user data
					if (res.status === 200) {
						const data = await res.json();

						// Update tokens and user data
						Cookies.set('access-token', data.accessToken, { expires: 1 / 24 });
						Cookies.set('refresh-token', data.refreshToken, { expires: 12 / 24 });
						localStorage.setItem('user', JSON.stringify(data));
						Cookies.set('user-status', true, { expires: 12 / 24 });

						// Redirect to /dashboard/main
						history.push({
							pathname: '/dashboard/main',
							state: {
								showModal: true
							}
						});
						window.location.reload()
					} else {
						console.error('Error: API response status is not 200');
					}
				} catch (error) {
					console.error('Error fetching new tokens:', error);
				}
			};
	
			fetchData();
		}
	}, []);


	if (isOnline) {
		return (
			<ErrorBoundary>
				<GoogleOAuthProvider
					clientId={`${
						process.env?.REACT_APP_GOOGLE_CLIENT_ID ||
						'787275588098-tjg5gnbta3u0k0vlktko61sapfq2ki1e.apps.googleusercontent.com'
					}`}
				>
					<AppLayout>
						<AppRoutes />
						<Snackbar
							open={open}
							autoHideDuration={2500}
							onClose={() => {
								dispatch(closeSnackbar())
							}}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Alert
								onClose={() => {
									dispatch(closeSnackbar())
								}}
								severity={severity}
								sx={{ width: '100%' }}
							>
								{message}
							</Alert>
						</Snackbar>
						<ProgressWorkModal />
						<Tidio tidioKey="pu5qwxyixmjlowy0q7wluvcriinsyy6d" />
					</AppLayout>
				</GoogleOAuthProvider>
			</ErrorBoundary>
		)
	} else {
		return <NoInternetConnection />
	}
}

export default App
