import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Typography, Box, CssBaseline } from '@mui/material'

import DashboardHeader from './Header/DashboardHeader'
import Sidebar from './Sidebar'
import DashboardRoutes from '../../routes/DashboardRoutes'

const DashboardLayout = (props) => {
	const [open, setOpen] = useState(true)

	const DrawerHeader = styled('div')(({ theme }) => {
		return {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar
		}
	})

	return (
		<Box sx={{ display: 'flex' }}>
			<DashboardHeader open={open} />
			<Sidebar open={open} setOpen={setOpen} />
			<Box component="main" sx={{ flexGrow: 1, p: 3, paddingBottom: '0' }}>
				<DrawerHeader sx={{ marginBottom: '0px' }} />
				<DashboardRoutes />
			</Box>
		</Box>
	)
}

export default DashboardLayout
