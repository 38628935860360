import { subDays } from 'date-fns'
import { addDays } from 'date-fns/esm'
import React, { PureComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer
} from 'recharts'

const ExpensesChart = ({ period, type }) => {
	let checks = useSelector((state) => state.appData.checks)
	const [data, setData] = useState([])
	const [total, setTotal] = useState(0)
	const [dateRange, setDateRange] = useState([])

	const weekDay = {
		0: 'Mon',
		1: 'Tue',
		2: 'Wed',
		3: 'Thu',
		4: 'Fri',
		5: 'Sat',
		6: 'Sun'
	}

	const monthDay = {
		0: 'Jan',
		1: 'Feb',
		2: 'Mar',
		3: 'Apr',
		4: 'May',
		5: 'Jun',
		6: 'Jul',
		7: 'Aug',
		8: 'Sep',
		9: 'Oct',
		10: 'Nov',
		11: 'Dec'
	}

	const makeWeeklyData = () => {
		let temp = []
		let total = 0
		let today = new Date()
		let lastWeekDate = subDays(today, 6)

		let i = lastWeekDate
		let x = 0

		let period = []
		period.push(JSON.parse(JSON.stringify(today)))
		period.push(JSON.parse(JSON.stringify(lastWeekDate)))
		setDateRange(period)

		do {
			let day = i.getDay()

			let filtered_check = checks.data.filter((check) => {
				return new Date(check.issuedDate).getDate() == i.getDate()
			})

			if (type === 'Total Expenses') {
				let amount = 0

				for (let check of filtered_check) {
					amount += check.amount
				}

				total += amount
				temp.push({
					name: weekDay[day],
					amount: amount.toFixed(2)
				})
			} else {
				let number = filtered_check.length
				total += number
				temp.push({
					name: weekDay[day],
					checks: number
				})
			}

			i = addDays(i, 1)
			x++
		} while (x < 7)

		setData(temp)
		setTotal(total)
	}

	const makeMonthlyData = () => {
		let temp = []
		let today = new Date()
		let period = []
		period.push(JSON.parse(JSON.stringify(today)))
		today.setMonth(today.getMonth() - 11)
		period.push(period.push(JSON.parse(JSON.stringify(today))))
		setDateRange(period)
		let x = 0
		let total = 0

		do {
			let month = today.getMonth()

			let filtered_check = checks.data.filter((check) => {
				return new Date(check.issuedDate).getMonth() == month
			})

			if (type === 'Total Expenses') {
				let amount = 0
				for (let ch of filtered_check) {
					amount += ch.amount
				}
				total += amount
				temp.push({
					name: monthDay[month],
					amount: amount.toFixed(2)
				})
			} else {
				let number = filtered_check.length
				total += number
				temp.push({
					name: monthDay[month],
					checks: number
				})
			}

			today.setMonth(today.getMonth() + 1)
			x++
		} while (x < 12)

		setData(temp)
		setTotal(total)
	}

	const makeYearlyData = () => {
		let temp = []
		let today = new Date()
		let period = []
		period.push(JSON.parse(JSON.stringify(today)))
		today.setYear(today.getYear() + 1900 - 4)
		period.push(JSON.parse(JSON.stringify(today)))
		setDateRange(period)
		let x = 0
		let total = 0

		do {
			let year = today.getYear() + 1900

			let filtered_check = checks.data.filter((check) => {
				return new Date(check.issuedDate).getYear() + 1900 == year
			})

			if (type === 'Total Expenses') {
				let amount = 0
				for (let ch of filtered_check) {
					amount += ch.amount
				}
				total += amount
				temp.push({
					name: year,
					amount: amount.toFixed(2)
				})
			} else {
				let number = filtered_check.length
				total += number
				temp.push({
					name: year,
					checks: number
				})
			}

			today.setYear(today.getYear() + 1900 + 1)
			x++
		} while (x < 5)

		setData(temp)
		setTotal(total)
	}

	useEffect(() => {
		switch (period) {
			case 'Weekly':
				makeWeeklyData()
				break
			case 'Monthly':
				makeMonthlyData()
				break
			case 'Yearly':
				makeYearlyData()
				break
		}
	}, [period, type, checks])

	const options = { year: 'numeric', month: 'short', day: 'numeric' }
	console.log(data, 'chartData')
	console.log(total, 'total')

	return (
		<>
			<div className="row m-2 mb-3">
				<h1 className="fs-4 mb-1">
					{type === 'Total Expenses' ? '$' : ''} {total.toLocaleString('en-US')}
				</h1>
				<p className="text text-secondary fs-14 m-0">
					{new Date(dateRange[1]).toLocaleDateString('en-US', options)} -{' '}
					{new Date(dateRange[0]).toLocaleDateString('en-US', options)}{' '}
				</p>
			</div>
			<ResponsiveContainer width="100%" height={280}>
				<AreaChart
					width={500}
					data={data}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0
					}}
				>
					<defs>
						<linearGradient id="coloramt" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="#EEFCDB" stopOpacity={1} />
							<stop offset="95%" stopColor="#EEFCDB" stopOpacity={0} />
						</linearGradient>
					</defs>
					<XAxis
						dataKey="name"
						style={{
							fontSize: '12px',
							fontFamily: 'Inter',
							fontWeight: '500'
						}}
					/>
					{type === 'Total Expenses' ? (
						<YAxis
							domain={[0, total]}
							allowDecimals={type === 'Total Expenses' ? true : false}
							style={{
								fontSize: '12px',
								fontFamily: 'Inter',
								fontWeight: '500'
							}}
						/>
					) : (
						<YAxis
							allowDecimals={type === 'Total Expenses' ? true : false}
							style={{
								fontSize: '12px',
								fontFamily: 'Inter',
								fontWeight: '500'
							}}
						/>
					)}

					<Tooltip />
					<Area
						type="monotone"
						dataKey={type === 'Total Expenses' ? 'amount' : 'checks'}
						fillOpacity={1}
						stroke="#82ca9d"
						fill="url(#coloramt)"
					/>
				</AreaChart>
			</ResponsiveContainer>
		</>
	)
}

export default ExpensesChart
