import { Divider, List, ListItem, ListItemText, Popover } from "@mui/material"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import FormModalMUI from "../../shared/Modals/FormModalMUI";
import { ErrorOutline } from "@mui/icons-material";
import ButtonComponent from "../../shared/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import { logOut } from "../../../redux/loginLogout";
import { emptyAppData } from "../../../redux/appData";
import { fetchGroups, fetchTags, getAddresses, getBanks, getChecks, getOrganizations, getPayees, getUserData } from "../../../utils/helper";

const CompanyLogo = () => {
    const organizationData = useSelector((state) => state.appData.organizations)

    const [selectedOrganization, setSelectedOrganization] = useState('')
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(localStorage.getItem('organization'))

    useEffect(() => {
        let result = organizationData.data.filter((data) => {
            return data?._id === selectedOrganizationId
        })

        if(result.length > 0) {
            setSelectedOrganization(result[0]);
        } else {
            setSelectedOrganization('');
        }
    }, [organizationData, selectedOrganizationId]);

    let history = useHistory()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const changeCompany = (data) => {
        localStorage.setItem('organization', data?._id)
        setSelectedOrganizationId(data?._id)
        applicationRefresh()
    }

    const applicationRefresh = () => {
        getChecks(dispatch)
        getBanks(dispatch)
        getAddresses(dispatch)
        getPayees(dispatch)
        getUserData(dispatch)
        fetchTags(dispatch)
        fetchGroups(dispatch)
    }

    return (
        <>
            {
                selectedOrganization ?
                    <div className="container" style={{ height: '36px', marginLeft: '20px' }} onClick={handleClick}>
                        <div className="row">
                            <div className="col-auto" style={{ width: '36px', height: '36px' }}>
                                <img src={selectedOrganization?.organizationLogo} alt="img-org"
                                    style={{ width: '36px', height: '36px', borderRadius: '18px', position: 'relative', left: '-12px' }} />
                            </div>
                            <div className="col-auto">
                                <p style={{ marginTop: '7px', fontSize: '14px', fontWeight: '500' }}>
                                    {selectedOrganization?.organizationName}
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    top: '10px'
                }}
            >
                <List>
                    <ListItem className="list-item-new" onClick={() => {
                        history.push('/create-organization')
                        handleClose()
                    }}
                        key="editDetails"
                    >
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }} primary={"Edit Organization Details"} />
                    </ListItem>
                    <ListItem className="list-item-new" onClick={() => {
                        history.push('/create-organization')
                        handleClose()
                    }}
                        key="createOrganization"
                    >
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }} primary={"Create New Organization"} />
                    </ListItem>
                </List>
            </Popover> */}
        </>
    )
}

export default CompanyLogo