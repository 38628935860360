import { useSelector } from 'react-redux'
import GenericTable from '../components/shared/GenericTable/GenericTable'
import PageHeader from '../components/shared/PageHeader'
import { useEffect, useState } from 'react'
import {
	useLocation,
	useHistory
} from 'react-router-dom/cjs/react-router-dom.min'
import { getSession } from '../API/StripeAPI'
import { CheckCircle, ErrorOutline } from '@mui/icons-material'
import FormModalMUI from '../components/shared/Modals/FormModalMUI'
import ButtonComponent from '../components/shared/ButtonComponent'

const AllOrders = () => {
	const location = useLocation()
	const history = useHistory()
	const [modifiedData, setModifiedData] = useState([])
	const [openPaymentModal, setOpenPaymentModal] = useState(false)
	const [paymentSucess, setPaymentSuccess] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	let checks = useSelector((state) => state.appData.checks)
	let transactions = useSelector((state) => state.appData.transactions)

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		const sessionId = queryParams?.get('session_id')
		if (sessionId) {
			showModalForPaymentStatus(sessionId)
		}
	}, [])

	const showModalForPaymentStatus = async (sessionId) => {
		let res = await getSession(sessionId)
		let status = res?.data?.payment_status
		if (status === 'paid') {
			setPaymentSuccess(true)
		}
		setOpenPaymentModal(true)
		window.history.replaceState(null, '', window.location.pathname)
	}

	useEffect(() => {
		if (transactions?.data?.length !== 0) {
			setIsLoading(true)
			makeTableData(checks?.data, transactions?.data)
		} else {
			setIsLoading(false)
			setModifiedData([])
		}
	}, [checks])

	const makeTableData = (data, transactions) => {
		const temp = []

		if (transactions && transactions?.length !== 0) {
			transactions.forEach((item, index) => {
				let metadata = item?.metadata
				let checkId = metadata?.split(' ')[1]

				if (checkId !== 'null' && checkId != null) {
					let check = data?.filter((c) => checkId === c?._id)

					if (check.length > 0) {
						let obj = {}
						obj.payeeName = check[0]?.payee?.name
						obj.checkNo = check[0]?.checkNumber
						obj.amount = '$' + parseFloat(check[0].amount)
						obj.trackingId = item?.tracking_number
						obj.status = item?.tracking_status
						obj.tackingUrl =
							item?.status === 'SUCCESS' ? (
								<a
									class="btn btn-outline-success btn-sm"
									href={item?.tracking_url_provider}
									role="button"
									target="_blank"
									rel="noreferrer"
								>
									Open tracking
								</a>
							) : (
								'Not found'
							)
						temp.push(obj)
					}
				}
			})
			setModifiedData([...temp])
			setIsLoading(false)
		} else {
			setModifiedData([])
			setIsLoading(false)
		}
	}

	const columnData = [
		{
			key: 'Check No',
			value: 'checkNo',
			colWidth: '1%',
			align: 'left'
		},
		{
			key: 'Amount',
			value: 'amount',
			colWidth: '1%',
			align: 'left'
		},
		{
			key: 'Payee Name',
			value: 'payeeName',
			colWidth: '1%',
			align: 'center'
		},
		{
			key: 'Tracking ID',
			value: 'trackingId',
			colWidth: '2%',
			align: 'center'
		},
		{
			key: 'Tracking Status',
			value: 'status',
			colWidth: '1%',
			align: 'center'
		},
		{
			key: 'Tracking URL',
			type: 'html',
			value: 'tackingUrl',
			colWidth: '1%',
			align: 'center'
		}
	]

	return (
		<>
			<div className="container">
				<PageHeader
					text="All Mails"
					info="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
				/>
				<GenericTable
					columnData={columnData}
					modifiedData={modifiedData}
					isLoading={isLoading}
					noDataProps={{
						text: 'All the mails created will be stored here. Mail your first check!',
						head: 'No mails',
						click: () => {
							history.push('/dashboard/create-check')
						},
						btnText: 'Mail Check'
					}}
				/>
			</div>

			<FormModalMUI
				open={openPaymentModal}
				onClose={() => {
					setOpenPaymentModal(false)
				}}
				maxWidth="sm"
			>
				{paymentSucess ? (
					<>
						<div class="container" style={{ width: '450px' }}>
							<div class="row">
								<div class="d-flex align-items-center justify-content-center text-success mt-3 mb-2">
									<CheckCircle sx={{ fontSize: '80px' }} />
								</div>
								<div class="col d-flex justify-content-center">
									<div class="row">
										<h3>
											<p>
												<b>Payment Successful</b>
											</p>
										</h3>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col d-flex justify-content-center">
									<div class="row text-center">
										<p>
											Your check has been successfully sent to selected
											payee(s).
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
							<ButtonComponent
								text="Close"
								type="button"
								variant="dark"
								click={() => {
									setOpenPaymentModal(false)
								}}
								extraClass="me-3"
							/>
						</div>
					</>
				) : (
					<>
						<div class="container" style={{ width: '450px' }}>
							<div class="row">
								<div class="d-flex align-items-center justify-content-center text-danger mt-3 mb-2">
									<ErrorOutline sx={{ fontSize: '80px' }} />
								</div>
								<div class="col d-flex justify-content-center">
									<div class="row">
										<h3>
											<p>
												<b>Payment Failure</b>
											</p>
										</h3>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col d-flex justify-content-center">
									<div class="row text-center">
										<p>Oops, something went wrong with the payment.</p>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
							<ButtonComponent
								text="Close"
								type="button"
								variant="danger"
								click={() => {
									setOpenPaymentModal(false)
								}}
								extraClass="me-3"
							/>
						</div>
					</>
				)}
			</FormModalMUI>
		</>
	)
}

export default AllOrders
