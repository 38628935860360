import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import FormComponents from '../../shared/forms'
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Icon,
	Switch
} from '@mui/material'
import ButtonComponent from '../../shared/ButtonComponent'
import {
	CheckCircle,
	ExpandLess,
	ExpandMore,
	RadioButtonUncheckedOutlined
} from '@mui/icons-material'
import {
	getNextAvailableCheckNumber,
	updatepreferences
} from '../../../API/PreferencesAPI'
import { updateSnackbar } from '../../../redux/snackbarState'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../utils/helper'
import FormModalMUI from '../../shared/Modals/FormModalMUI'
import AddSignature from '../forms/AddSignature'

const UserPreferences = () => {
	const userData = useSelector((state) => state.appData.userData)
	const dispatch = useDispatch()

	const [isSignatureModalOpen, setSignatureModalOpen] = useState(false)

	const [formValues, setFormvalues] = useState(null)

	let initialValues = {
		defaultCheckNumberGen: 'MANUAL',
		defaultCheckNumberLength: 4,
		defaultCheckStartNumber: '',
		wantSignature: false
	}

	useEffect(() => {
		if (userData?.isLoading === false) {
			const { data } = userData

			if ('preferences' in data) {
				const { preferences } = data

				setFormvalues({
					defaultCheckNumberGen: preferences?.defaultCheckNumberGen,
					defaultCheckNumberLength: preferences?.defaultCheckNumberLength,
					defaultCheckStartNumber: preferences?.defaultCheckStartNumber,
					wantSignature: preferences?.wantSignature
				})
			} else {
				setFormvalues({ ...initialValues })
			}
		}
	}, [userData])

	const validationSchema = Yup.object({
		defaultCheckNumberGen: Yup.string().required('Required!'),
		defaultCheckNumberLength: Yup.number()
			.required('Required!')
			.min(1)
			.max(8, 'Must be lesser or equal to 8'),
		defaultCheckStartNumber: Yup.number()
			.test(
				'len',
				'Must be between 1 and 4 digits',
				(val) => val.toString().length <= 4
			)
			.required()
	})

	const onSubmit = async (values) => {
		try {
			let res

			if (values?.defaultCheckNumberGen === 'AUTOMATIC') {
				res = await updatepreferences(values)
			} else {
				res = await updatepreferences({
					...values
				})
			}

			dispatch(
				updateSnackbar({
					open: true,
					message: 'User Preferences Updated successfully',
					severity: 'success'
				})
			)
			getUserData(dispatch)
		} catch (e) {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Unable to Update.',
					severity: 'error'
				})
			)
		}
	}

	return (
		<>
			<div className="container-fluid p-0 mt-5">
				<h4 className="fs-6 fw-semibold text-black mt-4 mb-2">Preferences</h4>
				<p className="fs-14 mb-4">Change your checkwriting preferences here</p>
			</div>
			{formValues && formValues !== null ? (
				<Formik
					initialValues={formValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{({ isValid, dirty, setFieldValue, values }) => {
						return (
							<Form>
								<div className="row">
									<div className="col-12 col-md-4">
										<h6 className="preference-head mb-2">
											Check No. generation
										</h6>
										<Field
											name="defaultCheckNumberGen"
											className="d-block w-100"
										>
											{(form) => {
												const { field } = form

												return (
													<FormControlLabel
														control={
															<Checkbox
																{...field}
																checked={
																	values.defaultCheckNumberGen === 'MANUAL'
																}
																id="MANUAL"
																onChange={(e) => {
																	setFieldValue(e.target.name, e.target.value)
																}}
																icon={<RadioButtonUncheckedOutlined />}
																checkedIcon={<CheckCircle />}
																value="MANUAL"
																sx={{
																	'&.MuiCheckbox-root': {
																		'& svg': {
																			border: '2px solid #fff',
																			borderRadius: '50%',
																			width: '20px',
																			height: '20px'
																		},
																		'&.Mui-checked': {
																			'& svg': {
																				border: '1.5px solid #5EA479'
																			},

																			color: '#5EA479'
																		}
																	}
																}}
															/>
														}
														label="Manual"
														sx={{
															display: 'flex',
															marginTop: '10px',
															marginBottom: '0',
															width: 'fit-content',
															marginLeft: '0',
															alignItems: 'center',
															height: '40px',

															'& span.MuiFormControlLabel-label': {
																fontSize: '14px'
															}
														}}
													/>
												)
											}}
										</Field>
										<Field
											name="defaultCheckNumberGen"
											className="d-block w-100"
										>
											{(form) => {
												const { field } = form

												return (
													<FormControlLabel
														control={
															<Checkbox
																{...field}
																checked={
																	values.defaultCheckNumberGen === 'AUTOMATIC'
																}
																icon={<RadioButtonUncheckedOutlined />}
																checkedIcon={<CheckCircle />}
																id="AUTOMATIC"
																onChange={(e) => {
																	setFieldValue(e.target.name, e.target.value)
																}}
																value="AUTOMATIC"
																sx={{
																	'&.MuiCheckbox-root': {
																		'& svg': {
																			border: '2px solid #fff',
																			borderRadius: '50%',
																			width: '20px',
																			height: '20px'
																		},
																		'&.Mui-checked': {
																			'& svg': {
																				border: '1.5px solid #5EA479'
																			},

																			color: '#5EA479'
																		}
																	}
																}}
															/>
														}
														label="Automatic"
														sx={{
															display: 'flex',
															marginTop: '10px',
															marginBottom: '0',
															width: 'fit-content',
															marginLeft: '0',
															alignItems: 'center',
															height: '40px',
															'& span.MuiFormControlLabel-label': {
																fontSize: '14px'
															}
														}}
													/>
												)
											}}
										</Field>
									</div>
								</div>

								<div className="row mt-4">
									<h6 className="preference-head mb-3">
										Length of check number
									</h6>
									<div className="col-12 col-md-2 col-sm-4">
										<div className="d-flex align-items-start justify-content-start">
											<FormComponents
												name="defaultCheckNumberLength"
												label=""
												control="input"
												type="number"
												disabled={true}
											/>
											<div className="d-flex align-items-center justify-content-center flex-column">
												<button
													className="bg-transparent border-0 value-cntrl-btn"
													type="button"
													onClick={(e) => {
														e.preventDefault()
														setFieldValue(
															'defaultCheckNumberLength',
															values.defaultCheckNumberLength + 1
														)
													}}
													disabled={
														values.defaultCheckNumberLength === 8 ? true : false
													}
												>
													<Icon
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '18px',
															height: '18px',
															'& svg': {
																width: 'inherit',
																height: 'inherit'
															}
														}}
													>
														<ExpandLess />
													</Icon>
												</button>
												<button
													className="bg-transparent border-0 value-cntrl-btn"
													type="button"
													onClick={(e) => {
														e.preventDefault()
														setFieldValue(
															'defaultCheckNumberLength',
															values.defaultCheckNumberLength - 1
														)
													}}
													disabled={
														values.defaultCheckNumberLength === 1 ? true : false
													}
												>
													<Icon
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															height: '18px',
															width: '18px',
															'& svg': {
																width: 'inherit',
																height: 'inherit'
															}
														}}
													>
														<ExpandMore />
													</Icon>
												</button>
											</div>
										</div>
									</div>
								</div>

								{values.defaultCheckNumberGen === 'AUTOMATIC' ? (
									<>
										<div className="row">
											<h6 className="preference-head mb-3">
												Default check start number
											</h6>
											<div className="col-12 col-md-2 col-sm-4">
												<FormComponents
													name="defaultCheckStartNumber"
													label=""
													control="input"
													type="text"
												/>
											</div>
										</div>
									</>
								) : null}

								<div className="d-flex align-items-center justify-content-between">
									<h6 className="preference-head mb-2">Your signature</h6>
									<ButtonComponent
										text={
											userData?.data?.signatureUrl !== null ||
											userData?.data?.signatureUrl !== ''
												? 'Update Signature'
												: 'Upload'
										}
										type="button"
										variant="dark"
										click={() => {
											setSignatureModalOpen(!isSignatureModalOpen)
										}}
									/>
								</div>
								<h6 className="preference-head mb-2">
									Enable signature for every check, if Uploaded
								</h6>
								<Field>
									{(form) => {
										return (
											<>
												<Switch
													checked={values?.wantSignature}
													onChange={(e) => {
														setFieldValue('wantSignature', e.target.checked)
													}}
													color="success"
													value={values?.wantSignature}
													inputProps={{ 'aria-label': 'controlled' }}
													disabled={
														userData?.data?.signatureUrl === null ||
														userData?.data?.signatureUrl === ''
													}
												></Switch>
											</>
										)
									}}
								</Field>
								<div
									className="userSignature mt-3"
									style={{
										width: '10rem',
										height: 'auto'
									}}
								>
									{userData?.data?.signatureUrl === null ||
									userData?.data?.signatureUrl === '' ? (
										<p className="fs-14 fw-semibold mb-0 text-secondary">
											No Signature Found. Please create one.
										</p>
									) : (
										<img
											src={userData?.data?.signatureUrl}
											alt="user-signature"
											style={{
												width: 'inherit',
												height: 'inherit'
											}}
										/>
									)}
								</div>

								<div className="d-flex align-items-center justify-content-end mt-3 mb-5">
									<ButtonComponent
										text="Update Preferences"
										type="submit"
										variant="dark"
										disabled={!isValid || !dirty}
									/>
								</div>
							</Form>
						)
					}}
				</Formik>
			) : null}
			<FormModalMUI
				title={
					userData?.preferences?.wantSignature
						? 'New Signature'
						: 'Create Signature'
				}
				open={isSignatureModalOpen}
				onClose={() => {
					setSignatureModalOpen(!isSignatureModalOpen)
				}}
				maxWidth="sm"
			>
				<AddSignature
					onClose={() => {
						setSignatureModalOpen(!isSignatureModalOpen)
					}}
				/>
			</FormModalMUI>
		</>
	)
}

export default UserPreferences
