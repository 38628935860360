export const bankNames = [
	'Blue foundry',
	'North East Community Bank',
	'Citizens Bank',
	'ORange Bank and Trust',
	'JPMorgan Chase',
	'Bank of America',
	'Wells Fargo',
	'Citigroup',
	'Goldman Sachs',
	'Morgan Stanley',
	'U.S. Bancorp',
	'PNC Financial Services',
	'Capital One',
	'TD Bank',
	'SunTrust',
	'BB&T',
	'Fifth Third Bank',
	'KeyBank',
	'Huntington Bancshares',
	'Ally Bank',
	'American Express Bank',
	'Charles Schwab Bank',
	'Discover Bank',
	'Navy Federal Credit Union',
	'Regions Financial Corporation',
	'BBVA USA',
	'First Horizon Bank',
	'Synovus Bank',
	'Comerica Bank',
	'M&T Bank',
	'Webster Bank',
	'Zions Bank',
	'Frost Bank',
	'Axos Bank',
	'First National Bank of Pennsylvania',
	'Fulton Bank',
	'First Citizens Bancshares',
	'Flagstar Bank',
	'Wintrust Financial Corporation',
	'TIAA Bank',
	'Trustmark National Bank',
	'Arvest Bank',
	'Old National Bank',
	'East West Bank',
	'Columbia Bank',
	'First Midwest Bank',
	'Great Western Bank',
	'Bank of Hawaii',
	'Western Alliance Bancorporation',
	'First Merchants Corporation',
	'Pacific Premier Bank',
	'Park National Bank',
	'Central Pacific Bank',
	'Home Savings Bank',
	'Union Bank',
	'First Financial Bank',
	'WesBanco Bank',
	'Glacier Bank',
	'City National Bank',
	'Mechanics Bank',
	'Cadence Bank',
	'Axion Bank',
	'Berkshire Bank',
	'Bank of Marin',
	'PlainsCapital Bank',
	'Seacoast Bank',
	'Renasant Bank',
	'Fidelity Bank',
	'First United Bank',
	'Webster First Federal Credit Union',
	'First Commonwealth Bank',
	'1st Source Bank',
	'Bank of America Private Bank',
	'South State Bank',
	'Central Pacific Financial Corp.',
	'City First Bank of D.C.',
	'Civista Bancshares',
	'Flagstar Bancorp',
	'Great Southern Bancorp',
	'Independent Bank Corp.',
	'Mercantil Bank',
	'National Cooperative Bank',
	'Provident Bancorp',
	'Southside Bancshares',
	'State Bankshares',
	'State Street Corp.',
	'Sterling Bancorp',
	'Univest Financial Corp.',
	'Valley National Bancorp',
	'WesBanco',
	'Xenith Bankshares',
	'First Capital Bancorp',
	'Simmons First National Corporation',
	'ConnectOne Bancorp',
	'Peapack-Gladstone Financial Corporation',
	'Flagship Community',
	'OceanFirst Financial Corp.',
	'Bank of Marin Bancorp',
	'BancorpSouth Bank',
	'Seacoast Banking Corporation of Florida',
	'Independent Bank Group, Inc.',
	'Sterling Bancorp, Inc.',
	'Pacific Premier Bancorp, Inc.',
	'TriState Capital Holdings, Inc.'
]
