import React, { useEffect,useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
	addbank,
	getBankDetails,
	updateBank,
	validateBankAccountNumber,
	validateBankNickName
} from '../../../API/BankAPI'

import FormComponents from '../../shared/forms'
import ButtonComponent from '../../shared/ButtonComponent'
import { updateSnackbar } from '../../../redux/snackbarState'
import { useDispatch } from 'react-redux'
import { bankNames } from '../../../utils/constants'
import { bankAccountType, getBanks } from '../../../utils/helper'
import { CircularProgress } from '@mui/material'
import { getQuickBookAccount } from '../../../API/IntegrationsAPI'
import { useSelector } from 'react-redux'

const USABank = ({
	bankData = {},
	isUpdate = false,
	onClose,
	isEdit,
	setDirty
}) => {
	const dispatch = useDispatch()
	const [isDisabled, setIsDisabled] = React.useState(false)
    
	const userData = useSelector((state) => state.appData.userData)
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)

	const [quickAccount, setQuickAccount] = useState(false)

	const [initialValues, setInitialValues] = React.useState({
		bankName: bankData?.bankName || '',
		accountName: bankData?.accountName || '',
		accountNumber: bankData?.accountNumber || '',
		bankRoutingNumber: bankData?.bankRoutingNumber || '',
		confirmAccountNumber: '',
		accountNickName: bankData?.accountNickName || '',
		accountType: bankData?.accountType
			? bankAccountType(bankData?.accountType)
			: '',
		country: 'USA'
	})

	useEffect(() => {
		fetchQuick()
	}, [userData,selectedOrganization])

	const fetchQuick = async () => {
		try {
			let body = {}
			if (selectedOrganization && selectedOrganization !== "No Org") {
				body.userId = selectedOrganization;
			} else {
				body.userId = userData.data._id; // Assuming userId is available in the scope
			}
			const res = await getQuickBookAccount(body)
			console.log(res?.data,"Data is here")
			if (res?.data) {
				setQuickAccount(res?.data.quickBooks)
			} 
		} catch (error) {
			setQuickAccount(false)
		}
	}
 
	console.log(quickAccount,"QuickBook Account")

	const validationUSASchema = Yup.object({
		bankName: Yup.string().required('Required !'),
		accountName: Yup.string().required('Required !'),
		accountNumber: Yup.string().required('Required !'),
		confirmAccountNumber: Yup.string().required('Required !'),
		accountType: Yup.string().required('Required !'),
		bankRoutingNumber: Yup.string()
			.required('Required !')
			.max(9, 'Must be exactly 4 digits'),
		accountNickName: Yup.string().required('Required !')
	})

	const initialUSAValues = {
		bankName: bankData?.bankName || '',
		accountName: bankData?.accountName || '',
		accountNumber: bankData?.accountNumber || '',
		bankRoutingNumber: bankData?.bankRoutingNumber || '',
		confirmAccountNumber: bankData?.accountNumber || '',
		accountNickName: bankData?.accountNickName || '',
		accountType: bankData?.accountType
			? bankAccountType(bankData?.accountType)
			: '',
		country: 'USA'
	}

	const onSubmit = async (values, { resetForm }) => {
		if (values.accountNumber != values.confirmAccountNumber) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Account number mismatch'
				})
			)
			return false
		}

		if (quickAccount && values.quickAccount === "") {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'QuickBook account need to select'
				})
			)
			return false
		}

		if (!isEdit) {
			try {
				const res = await validateBankAccountNumber(values.accountNumber)
			} catch (err) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'warning',
						message: 'Account number already exist.'
					})
				)
				return false
			}

			try {
				const res = await validateBankNickName(values.accountNickName)
			} catch (err) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'warning',
						message: 'Nick name already exist.'
					})
				)
				return false
			}
		}

		const body = { ...values }
		delete body.confirmAccountNumber

		try {
			let res
			res = isEdit ? await updateBank(body, bankData?._id) : await addbank(body)
			// if (isUpdate) {
			// 	res = await updateBank(data._id, body)
			// } else {

			// }
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: `${
						isUpdate ? 'Bank updated successfully.' : 'Bank added successfully.'
					}`
				})
			)
			getBanks(dispatch)
			resetForm()
			close(true)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Unable to add bank.'
				})
			)
		}
	}

	const accountTypes = [
		{
			key: 'Checking account',
			value: 'checking'
		},
		{
			key: 'Savings account',
			value: 'savings'
		},
		{
			key: 'Money market account',
			value: 'moneyMarket'
		},
		{
			key: 'Certificate of deposit (CD)',
			value: 'certificateOfDeposit'
		},
		{
			key: 'Business account',
			value: 'buisness'
		},
		{
			key: 'Joint account',
			value: 'joint'
		},
		{
			key: 'Trust account',
			value: 'trust'
		}
	]

	const bankOptions = bankNames.map((bank, index) => {
		return { key: bank, value: bank }
	})
	bankOptions.sort((a, b) => a.key.localeCompare(b.key))

	const getBank = async (routingNumber, setFieldValue) => {
		try {
			const res = await getBankDetails(routingNumber)
			const { data } = res

			setFieldValue('bankName', data[0].name)
			setIsDisabled(true)
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: `Bank found.`
				})
			)
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Invalid routing number.'
				})
			)
			setFieldValue('bankName', '')

			setIsDisabled(false)
			// setFieldValue('bankRoutingNumber', '')
		}
		// setIsDisabled(false)
	}

	const close = (forced) => {
		onClose(forced)
	}

	if (initialValues) {
		return (
			<Formik
				initialValues={initialUSAValues}
				validationSchema={validationUSASchema}
				onSubmit={onSubmit}
				id="usa-form"
				enableReinitialize={true}
			>
				{({ handleSubmit, isValid, dirty, setFieldValue, values }) => {
					console.log('form values', values)

					return (
						<Form onSubmit={handleSubmit} id="usa-form" autoComplete="false">
							{setDirty(dirty)}
							<div className="row">
								<div className="col-12 col-md-6 position-relative">
									<FormComponents
										name="bankRoutingNumber"
										label="Routing Number"
										control="input"
										type="text"
										required
										// disabled={isDisabled}
										onChange={(e) => {
											setFieldValue('bankRoutingNumber', e.target.value)

											if (
												e.target.value.length === 9 
												// && values?.bankName === ''x
											) {
												// setIsDisabled(true)
												getBank(e.target.value, setFieldValue)
											}
										}}
									/>
									{/* {isDisabled ? (
										<div className="form-field-spinner">
											<CircularProgress
												sx={{
													color: 'green',
													width: '15px !important',
													height: '15px !important'
												}}
											/>
										</div>
									) : null} */}
								</div>
								<div className="col-12 col-md-6">
									{
									// values?.bankRoutingNumber !== '' &&
									// values?.bankRoutingNumber?.length === 9 && 
									isDisabled ? (
										<FormComponents
											control="input"
											name="bankName"
											label="Select a bank*"
											disabled={isDisabled}
											required
										/>
									) : (
										<FormComponents
											name="bankName"
											label="Select a bank*"
											control="autocomplete"
											options={bankOptions}
											multiple={false}
											required
										/>
									)}
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<FormComponents
										name="accountType"
										label="Select Account type"
										control="select"
										disabled={true}
										options={accountTypes}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponents
										name="accountNumber"
										label="Account Number"
										control="input"
										type="password"
										required
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponents
										name="confirmAccountNumber"
										label="Confirm Account Number"
										control="input"
										type="text"
										required
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6">
									<FormComponents
										name="accountName"
										label="Account Name"
										control="input"
										type="text"
										required
									/>
								</div>
								<div className="col-12 col-md-6">
									<FormComponents
										name="accountNickName"
										label="Account Nick Name*"
										control="input"
										type="text"
										required
									/>
								</div>
								<div className="row">
								{quickAccount && <div className="col-12">
									<FormComponents
										name="quickAccount1"
										label="Select Account type"
										control="select"
										disabled={true}
										options={accountTypes}
										required
									/>
								</div> }
							</div>
							</div>
							<div className="d-flex flex-row align-items-center justify-content-evenly mt-1">
								<button
									type="button"
									className="common-btn bg-white light"
									onClick={() => close(false)}
								>
									Cancel
								</button>
								<ButtonComponent
									text={isEdit ? 'Save Changes' : 'Add Account'}
									type="submit"
									variant="dark"
									click={handleSubmit}
									disabled={!isValid || !dirty}
									// disabled={
									// 	(quickAccount && values.quickAccount1 === "") || 
									// 	!isValid || 
									// 	!dirty
									// }
									// disabled={
									// 	quickAccount 
									// 		? values.quickAccount1 == "" 
									// 		: !isValid || !dirty
									// }
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		)
	}
}

export default USABank
