import React from 'react'
import ReactDOM from 'react-dom/client'
import { StyledEngineProvider } from '@mui/material/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MsalProvider } from '@azure/msal-react'
import { Provider } from 'react-redux'

import store from './redux/store'
//import { msalConfig } from './utils/Providers/microsoftAuth'
import { EventType, PublicClientApplication } from '@azure/msal-browser'

//const msalInstance = new PublicClientApplication(msalConfig)

// if (
// 	!msalInstance.getActiveAccount() &&
// 	msalInstance.getAllAccounts().length > 0
// ) {
// 	// Account selection logic is app dependent. Adjust as needed for different use cases.
// 	msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0])
// }

// // Listen for sign-in event and set active account
// msalInstance.addEventCallback((event) => {
// 	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
// 		const account = event.payload.account
// 		msalInstance.setActiveAccount(account)
// 	}
// })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				{/* <MsalProvider instance={msalInstance}> */}
				<StyledEngineProvider injectFirst>
					<Provider store={store}>
						<App />
					</Provider>
				</StyledEngineProvider>
				{/* </MsalProvider> */}
			</Provider>
		</Router>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
