import React,{ useState,useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
	addbank,
	updateBank,
	validateBankAccountNumber,
	validateBankNickName
} from '../../../API/BankAPI'
import FormComponents from '../../shared/forms'
import ButtonComponent from '../../shared/ButtonComponent'
import { updateSnackbar } from '../../../redux/snackbarState'
import { useDispatch } from 'react-redux'
import { getBanks } from '../../../utils/helper'
import { useSelector } from 'react-redux'
import { getQuickBookAccount } from '../../../API/IntegrationsAPI'

const CanadaBank = ({ bankData = {}, isEdit = false, onClose, setDirty }) => {

	const dispatch = useDispatch()
	const [quickAccount, setQuickAccount] = useState(false)
	const userData = useSelector((state) => state.appData.userData)
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)

	const validationCanadaSchema = Yup.object({
		bankName: Yup.string().required('Required !'),
		accountName: Yup.string().required('Required !'),
		accountNumber: Yup.string().required('Required !'),
		confirmAccountNumber: Yup.string().required('Required !'),
		accountType: Yup.string().required('Required !'),
		bankTransitNumber: Yup.number().required('Required !'),
		financialInstituteNumber: Yup.number().required('Required !'),
		quickAccount1: Yup.string().when('quickAccount', {
			is: true,
			then: Yup.string().required('Required !')
		})
	})

	const initialCanadaValues = {
		bankTransitNumber: bankData?.bankTransitNumber || '',
		financialInstituteNumber: bankData?.financialInstituteNumber || '',
		bankName: bankData?.bankName || '',
		accountName: bankData?.accountName || '',
		accountNumber: bankData?.accountNumber || '',
		accountNickName: bankData?.accountNickName || '',
		accountType: bankData?.accountType?.toLowerCase() || '',
		country: 'Canada',
		confirmAccountNumber: ''
	}

	useEffect(() => {
		fetchQuick()
	}, [userData,selectedOrganization])

	const fetchQuick = async () => {
		try {
			let body = {}
			if (selectedOrganization && selectedOrganization !== "No Org") {
				body.userId = selectedOrganization;
			} else {
				body.userId = userData.data._id; // Assuming userId is available in the scope
			}
			const res = await getQuickBookAccount(body)
			console.log(res?.data,"Data is here")
			if (res?.data) {
				setQuickAccount(res?.data.quickBooks)
			} 
		} catch (error) {
			setQuickAccount(false)
		}
	}

	console.log(quickAccount,"QuickBook Account")


	const onSubmit = async (values, { resetForm }) => {
		if (values.accountNumber !== values.confirmAccountNumber) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Account numebr mismatch.'
				})
			)
			return false
		}

		try {
			const res = await validateBankAccountNumber(values.accountNumber)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'warning',
					message: 'Account number already exist.'
				})
			)
			return false
		}

		try {
			const res = await validateBankNickName(values.accountNickName)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'warning',
					message: 'Nick name already exist.'
				})
			)
			return false
		}

		const body = { ...values }
		delete body.confirmAccountNumber

		try {
			let res
			res = await addbank(body)
			// if (isUpdate) {
			// 	res = await updateBank(data._id, body)
			// } else {
			// 	res = await addbank(body)
			// }

			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: `${isEdit ? 'Bank updated successfully.' : 'Bank added successfully.'
						}`
				})
			)
			getBanks(dispatch)
			resetForm()
			close(true)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: `${isEdit ? 'Unable to update bank.' : 'Unable to add bank.'
						}`
				})
			)
		}
	}

	const accountTypes = [
		{
			key: 'Checking account',
			value: 'checking'
		},
		{
			key: 'Savings account',
			value: 'savings'
		},
		{
			key: 'Money market account',
			value: 'moneyMarket'
		},
		{
			key: 'Certificate of deposit (CD)',
			value: 'certificateOfDeposit'
		},
		{
			key: 'Business account',
			value: 'buisness'
		},
		{
			key: 'Joint account',
			value: 'joint'
		},
		{
			key: 'Trust account',
			value: 'trust'
		}
	]

	const close = () => {
		onClose()
	}

	return (
		<Formik
			initialValues={initialCanadaValues}
			validationSchema={validationCanadaSchema}
			onSubmit={onSubmit}
			id="canada-form"
		>
			{({ handleSubmit, resetForm, dirty }) => {
				return (
					<Form onSubmit={handleSubmit} id="canada-form">
						{setDirty(dirty)}
						<div className="row">
							<div className="col-12">
								<FormComponents
									name="bankName"
									label="Bank Name"
									control="input"
									type="text"
									required
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6">
								<FormComponents
									name="bankTransitNumber"
									label="Transit Number"
									control="input"
									type="number"
									required
								/>
							</div>
							<div className="col-12 col-md-6">
								<FormComponents
									name="financialInstituteNumber"
									label="Financial Institue Number"
									control="input"
									type="number"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6">
								<FormComponents
									name="accountNumber"
									label="Account Number"
									control="input"
									type="password"
								/>
							</div>
							<div className="col-12 col-md-6">
								<FormComponents
									name="confirmAccountNumber"
									label="Confirm Account Number"
									control="input"
									type="text"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<FormComponents
									name="accountType"
									label="Select Account type"
									control="select"
									options={accountTypes}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-md-6">
								<FormComponents
									name="accountName"
									label="Account Name"
									control="input"
									type="text"
								/>
							</div>
							<div className="col-12 col-md-6">
								<FormComponents
									name="accountNickName"
									label="Account Nick Number"
									control="input"
									type="text"
								/>
							</div>
							{quickAccount && <div className="col-12">
									<FormComponents
										name="quickAccount"
										label="Select Account type"
										control="select"
										disabled={true}
										options={accountTypes}
									/>
								</div> }
						</div>
						<div className="d-flex flex-row align-items-center justify-content-evenly mt-4">
							<button
								type="button"
								className="common-btn bg-white light"
								onClick={() => close(false)}
							>
								Cancel
							</button>
							<ButtonComponent
								text={isEdit ? 'Update Account' : 'Add Account'}
								type="submit"
								variant="dark"
								onClick={handleSubmit}
							/>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default CanadaBank
