import { Formik } from 'formik'
import office from '../assets/svg/office.svg'
import edit from '../assets/images/edit.png'
import * as Yup from 'yup'
import FormComponents from '../components/shared/forms'
import ButtonComponent from '../components/shared/ButtonComponent'
import industries from '../utils/industries.json'
import countryData from '../utils/countryStateCity.json'
import { useState } from 'react'
import { AddPhotoAlternateOutlined } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../redux/snackbarState'
import { addOrganization } from '../API/OrganizationAPI'
import { getOrganizations } from '../utils/helper'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const CreateOrganization = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [hovered, setHovered] = useState(false)

	const initialValues = {
		name: '',
		location: '',
		industry: ''
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Required'),
		location: Yup.string().required('Required!'),
		industry: Yup.string().required('Required!')
	})

	const onSubmit = async (values) => {
		try {
			if (!img) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'error',
						message: 'Logo Image is required for creating organization.'
					})
				)
			} else {
				let body = {
					organizationName: values.name,
					organizationLogo: img,
					location: values.location,
					industryType: values.industry
				}

				let res = await addOrganization(body)
				localStorage.setItem('organization', res.data._id)

				getOrganizations(dispatch)
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'success',
						message: 'Organization updated successfully.'
					})
				)
				history.push('/dashboard/main')
			}
		} catch (err) {
			console.log(err)
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Unable to add organization.'
				})
			)
		}
	}

	const [file, setFile] = useState()
	const [img, setImg] = useState('')

	const handleChange = async (e) => {
		if (e.target.files.length > 0) {
			let reader = new FileReader()

			reader.onload = function () {
				let base64String = reader.result
					.replace('data:', '')
					.replace(/^.+,/, '')

				let imageBase64Stringsep = base64String
				setImg(reader.result)
			}
			reader.readAsDataURL(e.target.files[0])
			setFile(URL.createObjectURL(e.target.files[0]))
		}
	}

	const handleHover = (hover) => {
		setHovered(hover)
	}

	return (
		<div class="container h-100 mt-5">
			<div class="row justify-content-evenly mb-5">
				<div class="col-auto">
					<h1>Tell us about your Organization</h1>
				</div>
			</div>
			<div class="row justify-content-evenly mb-5">
				<div class="col-auto image-upload">
					<label for="file-input">
						<div
							class="image-upload-label"
							style={{
								width: '300px',
								height: '300px',
								borderRadius: '150px',
								backgroundColor: '#CCCCCC',
								border: '1px solid #BBBBBB'
							}}
							onMouseEnter={() => handleHover(true)}
							onMouseLeave={() => handleHover(false)}
						>
							{hovered ? (
								<>
									<AddPhotoAlternateOutlined
										style={{
											width: '150px',
											height: '150px',
											marginLeft: '80px',
											marginTop: '50px'
										}}
									/>
									<h3
										style={{ position: 'relative', left: '80px', top: '10px' }}
									>
										{file ? 'Edit Image' : 'Add Image'}
									</h3>
								</>
							) : file ? (
								<img
									src={file}
									style={{
										width: '300px',
										height: '300px',
										borderRadius: '150px'
									}}
								/>
							) : (
								<img
									src={office}
									style={{
										width: '300px',
										height: '300px',
										borderRadius: '150px'
									}}
								/>
							)}
						</div>
					</label>
					<input type="file" id="file-input" onChange={handleChange} />
				</div>
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize={true}
			>
				{({ handleSubmit }) => {
					return (
						<div className="container" style={{ width: '600px' }}>
							<div class="row justify-content-evenly">
								<div class="col-12">
									<FormComponents
										name="name"
										type="text"
										label="Organization Name"
										control="input"
									/>
								</div>
							</div>
							<div class="row justify-content-between mb-3">
								<div class="col-6">
									<FormComponents
										name="location"
										type="text"
										label="Location"
										control="select"
										options={[
											...new Set(
												countryData?.flatMap((obj) => {
													return {
														key: obj?.name,
														value: obj?.name
													}
												})
											)
										]}
									/>
								</div>
								<div class="col-6">
									<FormComponents
										name="industry"
										type="text"
										label="Industry"
										control="select"
										options={[
											...new Set(
												industries['industries']?.flatMap((obj) => {
													return {
														key: obj?.name,
														value: obj?.value
													}
												})
											)
										]}
									/>
								</div>
							</div>
							<div class="row justify-content-center">
								<div class="col-auto">
									<ButtonComponent
										variant="dark"
										text="Create Account"
										type="submit"
										onClick={handleSubmit}
									/>
								</div>
							</div>
						</div>
					)
				}}
			</Formik>
		</div>
	)
}

export default CreateOrganization
