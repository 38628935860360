import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'

import FormHeader from '../components/shared/forms/FormHeader'
import FormComponents from '../components/shared/forms/index'
import FormButton from '../components/shared/forms/FormButton'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../redux/snackbarState'
import { FormControl, Checkbox } from '@mui/material'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { login } from '../redux/loginLogout'
import { appleSignUp, signUpGoogle } from '../API/socialAPI'

const CreateProfile = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	const [loginType, setLoginType] = useState(null)
	const [authData, setAuthData] = useState(null)
	const [formValues, setFormValues] = useState(null)
	const [initialValues, setInitialValues] = useState(null)

	useEffect(() => {
		if (location?.state) {
			setAuthData(location?.state?.authData)
			getUserData(location?.state?.userData)
			setLoginType(location?.state?.loginType)
		} else {
			history.push('/auth/sign-up')
		}
	}, [location])

	const getUserData = (data) => {
		setFormValues({
			email: data?.email || '',
			firstName: data?.firstName || '',
			middleName: data?.middleName || '',
			lastName: data?.lastName || '',
			isAggreed: false,
			phone: data?.phone || '',
			dateOfBirth: data?.dateOfBirth || new Date()
		})
		setInitialValues({
			email: data?.email || ''
		})
	}

	const validationSchema = Yup.object({
		email: Yup.string().email('Incorrect Email').required('Required!'),
		firstName: Yup.string().required('Required!'),
		lastName: Yup.string().required('Required!'),
		middleName: Yup.string(),
		phone: Yup.string(),
		dateOfBirth: Yup.date()
	})

	const onSubmit = async (values) => {
		if (initialValues?.email !== values?.email) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'User email not verified.'
				})
			)
			return false
		}

		const body = { ...values, ...authData }
		delete body.isAggreed

		if (loginType === 'google') {
			try {
				const res = await signUpGoogle(body)
				const { data } = res

				Cookies.set('refresh-token', data.refreshToken, {
					expires: 1
				})
				Cookies.set('user-status', true, {
					expires: 1
				})
				Cookies.set('access-token', data.accessToken, {
					expires: 2 / 24
				})
				dispatch(login({ token: data.accessToken }))
				dispatch(
					updateSnackbar({
						open: true,
						message: `Google login successful.`,
						severity: 'success'
					})
				)
				history.push({
					pathname: '/dashboard/main',
					state: {
						showModal: true
					}
				})
			} catch (error) {
				debugger
			}
		} else if (loginType === 'apple') {
			try {
				const res = appleSignUp(body)
				const { data } = res

				Cookies.set('refresh-token', authData.refreshToken, {
					expires: 1
				})
				Cookies.set('user-status', true, {
					expires: 1
				})
				Cookies.set('access-token', authData.accessToken, {
					expires: 2 / 24
				})
				dispatch(login({ token: authData.accessToken }))
				dispatch(
					updateSnackbar({
						open: true,
						message: `Apple login successful.`,
						severity: 'success'
					})
				)
				history.push('/dashboard/main')
				localStorage.setItem('isFirstTimeSignUp', true)
				
			} catch (error) {}
		}

		// axios
		// 	.put(`${process.env.REACT_APP_BASE_URL}/users/user`, body, {
		// 		headers: {
		// 			Authorization: authData?.accessToken
		// 		}
		// 	})
		// 	.then((res) => {
		// 		Cookies.set('refresh-token', authData.refreshToken, {
		// 			expires: 1
		// 		})
		// 		Cookies.set('user-status', true, {
		// 			expires: 1
		// 		})
		// 		Cookies.set('access-token', authData.accessToken, {
		// 			expires: 2 / 24
		// 		})
		// 		dispatch(login({ token: authData.accessToken }))
		// 		dispatch(
		// 			updateSnackbar({
		// 				open: true,
		// 				severity: 'success',
		// 				message: 'Google login successful.'
		// 			})
		// 		)
		// 		history.push('/dashboard/create-check')
		// 	})
		// 	.catch((err) => {
		// 		const { response } = err
		// 		dispatch(
		// 			updateSnackbar({
		// 				open: true,
		// 				severity: 'error',
		// 				message: `${
		// 					response?.data?.error?.userMessage || 'Something went wrong'
		// 				}`
		// 			})
		// 		)
		// 		history.push('/auth/login')
		// 	})
	}

	return (
		<div className="auth-form-div">
			<FormHeader
				head="Create Profile"
				text="Create checkwriter account with your google account details."
			/>
			{formValues ? (
				<Formik
					initialValues={formValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ handleSubmit, setFieldValue, values }) => {
						return (
							<Form>
								<FormComponents
									name="firstName"
									type="text"
									label="First Name"
									control="input"
									required
								/>
								<FormComponents
									name="middleName"
									type="text"
									label="Middle Name"
									control="input"
								/>
								<FormComponents
									name="lastName"
									type="text"
									label="Last Name"
									control="input"
									required
								/>
								<FormComponents
									name="email"
									type="email"
									label="Email"
									control="input"
									disabled
								/>

								<FormComponents
									name="phone"
									label="Phone Number"
									control="phone-input"
									type="text"
									country="us"
									onChange={(phoneNumber) =>
										setFieldValue('phone', phoneNumber)
									}
								/>
								<FormComponents
									name="dateOfBirth"
									label="Date of Birth"
									control="date"
								/>

								<div className="d-flex align-items-start justify-content-center mb-3">
									<span className="me-1 mt-0">
										<FormControl className="p-0">
											<Checkbox
												className="p-1 pt-0"
												checked={values.isAggreed}
												defaultChecked={true}
												sx={{
													'&.Mui-checked': {
														color: 'green'
													},
													'& .MuiSvgIcon-root': { fontSize: 18 }
												}}
												required
												size="small"
												name="isAggreed"
												onChange={(e) => {
													setFieldValue('isAggreed', e.target.checked)
												}}
											/>
										</FormControl>
									</span>
									<p className="redirect-txt text-left mt-0 mb-3 ">
										By clicking here, I state that I have read and understood
										the
										<Link to="/terms"> terms and conditions. </Link>
									</p>
								</div>

								<FormButton
									text="Submit"
									type="submit"
									click={handleSubmit}
									disabled={!values?.isAggreed}
								/>
							</Form>
						)
					}}
				</Formik>
			) : null}
		</div>
	)
}

export default CreateProfile

//// google code
// try {
// 	const resp = await signUpGoogle(bo)

// 	const { data } = resp

// 	history.push({
// 		pathname: '/auth/create-profile',
// 		state: {
// 			authData: {
// 				...data
// 			}
// 		}
// 	})
// } catch (err) {
// 	debugger
// }
