import { ArrowForwardIosOutlined } from '@mui/icons-material'
import GenericTable from '../shared/GenericTable/GenericTable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CheckStatusPopover from '../shared/Popovers/CheckStatusPopover'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const RecentChecks = () => {
	const history = useHistory()
	let checks = useSelector((state) => state.appData.checks)
	const [modifiedData, setModifiedData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
  console.log(modifiedData,"data")
	const columnData = [
		{
			key: 'Check No.',
			value: 'checkNumber',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Payee Name',
			value: 'payeeName',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Status',
			type: 'html',
			value: 'status',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Amount',
			value: 'amount',
			colWidth: '10%',
			align: 'center'
		}
	]

	useEffect(() => {
		if (checks.data.length !== 0) {
			setIsLoading(true)
			makeTableData(checks.data)
		} else {
			setIsLoading(checks.isLoading)
			setModifiedData([])
		}
	}, [checks])

	const makeTableData = (data) => {
		const temp = []

		if (data && data.length !== 0) {
			data.slice(0, 7).forEach((item, index) => {
				let obj = {}
				obj.payeeName = item?.payee?.name
				obj.checkNumber = item.checkNumber
				obj.status = {
					value: item.status,
					html: (
						<CheckStatusPopover
							checkStatus={item?.status}
							checkId={item?._id}
							freezeStatus={true}
						/>
					)
				}

				// obj.amount =
				// 	item?.amount && item.amount - Math.floor(item.amount) !== 0
				// 		? `$${item.amount}`
				// 		: `$${item.amount}.00`
				obj.amount =
	item?.amount !== null && item.amount - Math.floor(item.amount) !== 0
		? `$${item.amount}`
		: item?.amount !== null
		? `$${item.amount}.00`
		: '$0.00';

				obj._id = item._id
				temp.push(obj)
			})

			setModifiedData([...temp])
			setIsLoading(false)
		} else {
			setModifiedData([])
			setIsLoading(false)
		}
	}

	return (
		<div
			className="container"
			style={{
				borderRadius: '12px',
				borderStyle: 'solid',
				borderColor: '#bbbbbb',
				borderWidth: '0.5px',
				padding: '20px 20px 0'
			}}
		>
			<div className="d-flex align-items-center justify-content-between">
				<p
					className="fs-4 fw-semibold"
					style={{
						marginLeft: '10px'
					}}
				>
					Recent Checks
				</p>

				<div
					className="col-auto viewAll"
					onClick={() => {
						history.push('/dashboard/my-checks')
					}}
				>
					<p
						style={{
							fontSize: '14px',
							color: '#3F7568',
							fontWeight: '600'
						}}
					>
						View All <ArrowForwardIosOutlined fontSize="10px" />
					</p>
				</div>
			</div>
			<div className="row">
				<GenericTable
					columnData={columnData}
					modifiedData={modifiedData}
					paginationOff={true}
					isLoading={isLoading}
					height="352px"
				/>
			</div>
		</div>
	)
}

export default RecentChecks
