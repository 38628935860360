import MuiAppBar from '@mui/material/AppBar'
import {
	Divider,
	Icon,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Popover,
	Select,
	ToggleButton,
	ToggleButtonGroup,
	Toolbar,
	Tooltip
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
	AddOutlined,
	ArrowBackOutlined,
	HelpOutlineOutlined
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import ButtonComponent from '../../shared/ButtonComponent'
import AddButton from './AddButton'
import CompanyLogo from './CompanyLogo'
import ProfilePopover from './ProfilePopover'
import FormModalMUI from '../../shared/Modals/FormModalMUI'
import {
	fetchGroups,
	fetchTags,
	getAddresses,
	getBanks,
	getChecks,
	getOrganizations,
	getPayees,
	getUserData
} from '../../../utils/helper'
import Cookies from 'js-cookie'
import { updateSelectedOrganization } from '../../../redux/appData'

const DashboardHeader = ({ open }) => {
	const dispatch = useDispatch()
	let history = useHistory()
	const userData = useSelector((state) => state.appData.userData)
	const organizationData = useSelector((state) => state.appData.organizations)
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)

	const [userFirstName, setUserFirstName] = useState('')
	const [userLastName, setUserLastName] = useState('')

	useEffect(() => {
		setUserFirstName(userData?.data?.firstName)
		setUserLastName(userData?.data?.lastName)
	}, [userData])

	const drawerWidth = 240

	const [openModal, setOpenModal] = useState(false)

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	//const [selectedOrganization, setSelectedOrganization] = useState('No Org')
	const [selectedOrganizationId, setSelectedOrganizationId] =
		useState(selectedOrganization)

	// useEffect(() => {
	// 	let result = organizationData?.data?.filter((data) => {
	// 		return data?._id === selectedOrganizationId
	// 	})

	// 	if (result.length > 0) {
	// 		setSelectedOrganization(result[0])
	// 	} else {
	// 		setSelectedOrganization('')
	// 	}
	// }, [organizationData, selectedOrganizationId])

	const changeCompany = (data) => {
		Cookies.set('organization', data?._id)
		dispatch(updateSelectedOrganization(data?._id))
		setSelectedOrganizationId(data?._id)
		applicationRefresh()
	}

	const applicationRefresh = () => {
		getChecks(dispatch)
		getBanks(dispatch)
		getAddresses(dispatch)
		getPayees(dispatch)
		getUserData(dispatch)
		fetchTags(dispatch)
		fetchGroups(dispatch)
	}

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== 'open'
	})(({ theme, open }) => ({
		//zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		})
	}))

	return (
		<>
			<AppBar
				position="fixed"
				open={open}
				className="app-internal-header"
				sx={{
					background: '#fff',
					boxShadow: 'none',
					zIndex: '10',
					width: '100%'
				}}
			>
				<Toolbar
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						'&.MuiToolbar-root': {
							minHeight: 'auto'
						}
					}}
					className="px-4 pe-0"
				>
					<h3
						className="user-welcome-header mb-0"
						style={{
							color: 'rgb(61, 61, 61)',
							marginLeft: '14.2rem',
							fontSize: '18px'
						}}
					>
						Welcome {userData?.data?.firstName} {userData?.data?.lastName} !
					</h3>
					<div className="d-flex align-items-center justify-content-end flex-row">
						<div className="col" style={{ marginRight: '20px' }}>
							<AddButton />
						</div>
						<div className="col" style={{ marginRight: '10px' }}>
							<Tooltip
								title={
									<span>
										Please contact at{' '}
										<a
											href="mailto:support@synccos.com"
											target="blank"
											rel="no-referrer"
											className="fw-semibold text-white"
										>
											support@synccos.com
										</a>{' '}
										for queries and support
									</span>
								}
							>
								<HelpOutlineOutlined
									style={{ color: '#757575' }}
									sx={{ '&:hover': { cursor: 'pointer' } }}
								/>
							</Tooltip>
						</div>
						<div className="header-divider"></div>
						{/* <div className='col-auto' style={{
						paddingLeft: "20px",
						borderLeft: "1px solid #E3E5E8"
					}}>
						{
							organizationData.data.length > 0 ?
								<CompanyLogo/>
								:
								<ButtonComponent
									variant="light"
									text="Create Organization"
									onClick={() => { history.push('/create-organization') }}
									style={{
										width: "168px"
									}}
								/>
						}
					</div> */}

						{organizationData?.data?.length === 0 ? (
							<ButtonComponent
								text="Create Organization"
								variant="light"
								click={() => {
									history.push('/dashboard/create-organization')
								}}
								extraClass="black-button glassmorph-btn"
							/>
						) : (
							<Select
								value={
									Cookies.get('organization')
										? Cookies.get('organization')
										: 'No Org'
								}
								sx={{
									'&.MuiOutlinedInput-root': {
										margin: '0 15px',
										borderRadius: '6px',
										minWidth: '180px',

										'& fieldset.MuiOutlinedInput-notchedOutline': {
											borderColor: 'green'
										}
									},
									'& .MuiSelect-select': {
										padding: '7px 8px',
										fontSize: '14px',
										fontWeight: '500',
										border: '1px solid #969696',
										borderRadius: '6px'
									}
								}}
								MenuProps={{
									classes: {
										paper: 'org-select-menu'
									},
									anchorOrigin: {
										vertical: 'bottom',
										horizontal: 'center'
									},
									sx: {
										top: '13px',
										maxHeight: '200px',
										height: '200px',
										'& .MuiMenuItem-root': {
											'&.Mui-selected': {
												backgroundColor: 'rgba(0,128,0, 0.1)'
											},
											'&:hover': {
												backgroundColor: 'rgba(0,128,0, 0.1)'
											}
										}
									}
								}}
							>
								<h4
									className="fs-12 mb-0 fw-semibold"
									style={{
										padding: '7px 16px 2px'
									}}
								>
									Organizations
								</h4>
								{organizationData?.data?.length !== 0 ? (
									organizationData?.data?.map((data, index) => {
										return (
											<MenuItem
												key={index}
												value={data?._id}
												onClick={() => {
													changeCompany(data)
												}}
												sx={{
													fontSize: '14px'
												}}
											>
												{data?.organizationName}
											</MenuItem>
										)
									})
								) : (
									<MenuItem disabled>No organzations</MenuItem>
								)}
								<Divider
									sx={{
										borderColor: '#969696'
									}}
								/>
								<h4
									className="fs-12 fw-semibold"
									style={{
										padding: '2px 16px'
									}}
								>
									Personal Account
								</h4>
								<MenuItem
									value="No Org"
									onClick={() => {
										setSelectedOrganizationId('No Org')
										Cookies.remove('organization')
										dispatch(updateSelectedOrganization(null))
										applicationRefresh()
									}}
									sx={{
										fontSize: '14px'
									}}
								>
									{userData?.data?.firstName} {userData?.data?.lastName}
								</MenuItem>
								<Divider
									sx={{
										borderColor: '#969696'
									}}
								/>
								<MenuItem
									onClick={() => {
										history.push('/dashboard/manage-organizations')
									}}
									sx={{
										display: 'flex',
										alignItems: 'center',
										fontSize: '14px',
										textDecoration: 'underline'
									}}
								>
									Manage Organizations
								</MenuItem>
							</Select>
						)}
						<div className="header-divider"></div>
						{/* {organizationData.data?.length > 0 ? (
							<ToggleButtonGroup
								color="success"
								value={alignment}
								exclusive
								onChange={handleChange}
								aria-label="Mode"
							>
								<ToggleButton value="individual">Individual</ToggleButton>
								<ToggleButton value="organisation">Organization</ToggleButton>
							</ToggleButtonGroup>
						) : (
							<ButtonComponent
								variant="light"
								text="Create Organization"
								onClick={() => {
									history.push('/dashboard/business')
								}}
								style={{
									width: '168px'
								}}
							/>
						)} */}

						<CompanyLogo />

						<div
							className="col"
							style={{ marginRight: '40px', marginLeft: '20px' }}
						>
							<ProfilePopover
								userFirstName={userFirstName}
								userLastName={userLastName}
								userEmail={userData?.data?.email}
							/>
						</div>
					</div>
				</Toolbar>
			</AppBar>

			{/* <FormModalMUI open={openModal} maxWidth="sm">
				<div className="container">
					<div className="row justify-content-start">
						<div className="col-1 align-self-start">
							<IconButton
								onClick={() => {
									setAlignment('individual')
									handleCloseModal()
								}}
							>
								<ArrowBackOutlined />
							</IconButton>
						</div>
						<div className="col-11 align-self-start">
							<h4 style={{ marginTop: '6px' }}>Select Organisation</h4>
						</div>
					</div>
					<div className="row">
						<List>
							{organizationData?.data?.map((data) => {
								return (
									<ListItem
										className="list-item-new"
										onClick={() => {
											changeCompany(data)
											handleCloseModal()
										}}
										key={data?._id}
									>
										<ListItemText
											primaryTypographyProps={{ fontSize: '14px' }}
											primary={
												<div
													className="container company-logo-header"
													style={{ height: '36px' }}
												>
													<div className="row">
														<div
															className="col-auto"
															style={{ width: '36px', height: '36px' }}
														>
															<img
																src={data?.organizationLogo}
																alt="img-org"
																style={{
																	width: '36px',
																	height: '36px',
																	borderRadius: '18px',
																	position: 'relative',
																	left: '-12px'
																}}
															/>
														</div>
														<div className="col-auto">
															<p
																style={{
																	marginTop: '7px',
																	fontSize: '14px',
																	fontWeight: '500'
																}}
															>
																{data?.organizationName}
															</p>
														</div>
													</div>
												</div>
											}
										/>
									</ListItem>
								)
							})}
						</List>
					</div>
				</div>
			</FormModalMUI> */}
		</>
	)
}

export default DashboardHeader
