import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormHeader from '../components/shared/forms/FormHeader'
import FormComponents from '../components/shared/forms/index'
import FormButton from '../components/shared/forms/FormButton'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../redux/snackbarState'

const NewPassword = () => {
	const history = useHistory()

	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!location.search) {
			history.push('/auth/login')
		}
	}, [])

	const searchParams = new URLSearchParams(location?.search)
	const token = searchParams?.get('token') ? searchParams.get('token') : ''

	const validationSchema = Yup.object({
		password: Yup.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			),
		confirmPassword: Yup.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			)
	})

	const initialValues = {
		confirmPassword: '',
		password: ''
	}

	const onSubmit = async (values) => {
		if (values.password !== values.confirmPassword) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Password mismatch.'
				})
			)
			return false
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/auth/reset-password`,
				{
					method: 'POST', // or 'PUT'
					headers: {
						'Content-Type': 'application/json',
						Authorization: `${token}`
					},
					body: JSON.stringify(values)
				}
			)

			const result = await response.json()

			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: 'Password updated successfully.'
				})
			)

			history.push('/auth/login')
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Unable to update password.'
				})
			)
		}
	}

	return (
		<div className="auth-form-div">
			<div className="pt-5">
				<FormHeader
					head="Set new password"
					text="Must be atleast 8 characters"
				/>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<FormComponents
							name="password"
							label="New Password"
							control="input"
							type="password"
						/>
						<FormComponents
							name="confirmPassword"
							label="Confirm Password"
							control="input"
							type="password"
						/>
						<FormButton
							text="Reset Password"
							type="submit"
							click={handleSubmit}
						/>
					</Form>
				)}
			</Formik>

			<p className="redirect-txt text-center mt-5">
				Back to <Link to="/auth/login">Log In</Link>
			</p>
		</div>
	)
}

export default NewPassword
