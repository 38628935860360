import React, { useEffect, useState } from 'react'

import ButtonComponent from '../components/shared/ButtonComponent.jsx'
import { Add } from '@mui/icons-material'
import CompanyForm from '../components/views/CompanyForm.jsx'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

const Buisness = () => {
	const location = useLocation()

	const [isUpdate, setIsUpdate] = useState(false)
	const [orgData, setOrgData] = useState(null)

	console.log('location', location)

	useEffect(() => {
		if (location?.state?.orgData) {
			setIsUpdate(true)
			setOrgData({ ...location?.state?.orgData })
		}
	}, [location])

	const deleteItem = (id) => {
		let temp = [...orgData]
		temp = temp.filter((data) => {
			return data?.oid !== id
		})
		setOrgData(temp)
	}

	return (
		<div className="container">
			<div className="d-flex align-items-center justify-content-between">
				<h2
					className="fw-semibold fs-4 m-0"
					style={{
						color: '#12221fbf'
					}}
				>
					{isUpdate ? 'Update Organization' : 'Create Organization'}
				</h2>
			</div>

			<CompanyForm data={orgData} isUpdate={isUpdate} deleteItem={deleteItem} />
		</div>
	)
}

export default Buisness
