import React,{useState} from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton';
import {
	TextField,
	InputLabel,
	OutlinedInput,
	Box,
	Typography,Button
} from '@mui/material'
import FormHeader from '../components/shared/forms/FormHeader'
import FormComponents from '../components/shared/forms/index'
import FormButton from '../components/shared/forms/FormButton'
import SocialLogin from '../components/shared/SocialLogin'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../redux/snackbarState'
import { FormControl, Checkbox } from '@mui/material'
import { sendOTP } from '../API/UserAPI'

const SignUp = () => {
	const dispatch = useDispatch()
	const history = useHistory()


	const [showPassword, setShowPassword] = useState(false); // State for password visibility
	const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility

	const validationSchema = Yup.object({
		email: Yup.string().email('Incorrect Email').required('Required!'),
		password: Yup.string()
			.required('Required!')
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			),
		confirmPassword: Yup.string()
			.required('Required')
			.oneOf([Yup.ref('password'), null], 'Passwords does not matched	') // Check if confirmPassword matches password
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			),
		//phoneNumber: Yup.string().required('Phone number is required'),
		firstName: Yup.string().required('Required!'),
		lastName: Yup.string().required('Required!')
		// middleName: Yup.string()
	})

	const initialValues = {
		email: '',
		password: '',
		confirmPassword: '',
		firstName: '',
		middleName: '',
		lastName: '',
		isAggreed: false
	}

	const onSubmit = async (values) => {
		// console.log(values)

		try {
			const response = await sendOTP({
				email: values.email.toLowerCase()
			})
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: 'An email verification link sent. Please check your email.'
				})
			)
			history.push({
				pathname: '/auth/verify-otp',
				state: {
					values: { ...values }
				}
			})
		} catch (error) {
			const { response } = error
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: `${
						response?.data?.error?.userMessage || 'Something went wrong'
					}`
				})
			)
		}
	}

	const styles = {
		label: {
			color: '#6C757D',
			marginBottom: '6px',
			marginLeft: '20px',
			fontSize: '14px'
		},
		inputField: {
			'& .MuiOutlinedInput-root': {
				height: '39px',
				'& fieldset': {
					borderRadius: '10px',
					borderColor: '#CED4DA'
				},
				'&:hover fieldset': {
					borderColor: '#A9A9A9'
				},
				'&.Mui-focused fieldset': {
					borderColor: '#7C4DFF',
					boxShadow: '0 0 0 2px rgba(124, 77, 255, 0.3)'
				}
			},
			'& .MuiInputBase-input': {
				color: '#495057',
				padding: '14px 12px'
			}
		},
		checkboxStyles: {
			'&.Mui-checked': {
				color: '#7C4DFF' // Customize the color to match the design
			},
			'& .MuiSvgIcon-root path': {
				// stroke: '#000000', // Set the color of the checkmark to black when checked
				strokeWidth: 1 // Set the thickness of the checkmark line
			},
			'& .MuiSvgIcon-root': {
				fontSize: 30,
				marginTop: '4px' // Customize the size of the checkbox
			},
			color: '#CED4DA' // Default border color when unchecked
		},
		errorMessage: {
			color: 'red',
			marginLeft: '20px',
			fontSize: '12px'
		},
		checkbox: {
			'&.Mui-checked': {
				color: 'green'
			},
			'& .MuiSvgIcon-root': {
				fontSize: 18
			}
		},
		buttons:{
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '16px 36px',
			gap: '12px',
			width: '100%',
			height: '48px',
			background: 'linear-gradient(90deg, #693BAA 4%, #B04EC3 100%)',
			borderRadius: '10px !important',
			flex: 'none',
			order: 7,
			flexGrow: 0,
			color: 'white', // Optional: Set text color
			'&:disabled': {
				background: '#CED4DA', // Optional: Change background when disabled
			}
		}
	}

	return (
		<div className="auth-form-div">
			<div
				className="form-head mb-5"
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					fontWeight: 'initial'
				}}
			>
				<h2
					className="fs-1 mb-1 text-center form-header"
					style={{ fontWeight: 'initial' }}
				>
					Sign Up
				</h2>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
						handleSubmit,
						handleChange,
						setFieldValue,
						values,
						errors,
						touched
					}) => (
					<Form onSubmit={handleSubmit}>
						<Typography variant="body1" sx={styles.label}>
							First Name <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="firstName"
							type="text"
							fullWidth
							value={values.firstName}
							onChange={handleChange}
							error={touched.firstName && Boolean(errors.firstName)}
							sx={styles.inputField}
						/>

						<ErrorMessage name="firstName">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>

						<Typography
							variant="body1"
							sx={styles.label}
							style={{ marginTop: '15px' }}
						>
							Last Name <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="lastName"
							type="text"
							fullWidth
							value={values.lastName}
							onChange={handleChange}
							error={touched.lastName && Boolean(errors.lastName)}
							sx={styles.inputField}
						/>

						<ErrorMessage name="lastName">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>
						<Typography
							variant="body1"
							sx={styles.label}
							style={{ marginTop: '15px' }}
						>
							Email <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="email"
							type="text"
							fullWidth
							value={values.email}
							onChange={handleChange}
							// onChange={(e) => {
							// 	const lowercaseValue = e.target.value.toLowerCase();
							// 	handleChange({
							// 		target: {
							// 			name: 'email',
							// 			value: lowercaseValue,
							// 		},
							// 	});
							// }}
							error={touched.email && Boolean(errors.email)}
							sx={styles.inputField}
						/>

						<ErrorMessage name="email">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>

						<Typography
							variant="body1"
							sx={styles.label}
							style={{ marginTop: '15px' }}
						>
							Password <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="password"
							type={showPassword ? 'text' : 'password'} // Toggle type based on state
							fullWidth
							value={values.password}
							onChange={handleChange}
							error={touched.password && Boolean(errors.password)}
							sx={styles.inputField}
							InputProps={{
								endAdornment: (
									<IconButton onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? <VisibilityOff /> :  <VisibilityIcon />} {/* Change icon based on state */}
									</IconButton>
								)
							}}
						/>

						<ErrorMessage name="password">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>

						{/* Confirm Password Field */}
						<Typography
							variant="body1"
							sx={styles.label}
							style={{ marginTop: '15px' }}
						>
							Confirm Password <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="confirmPassword"
							type={showConfirmPassword ? 'text' : 'password'} // Toggle type based on state
							fullWidth
							value={values.confirmPassword}
							onChange={handleChange}
							error={touched.confirmPassword && Boolean(errors.confirmPassword)}
							sx={styles.inputField}
							InputProps={{
								endAdornment: (
									<IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
										{showConfirmPassword ? <VisibilityOff /> :  <VisibilityIcon />} {/* Change icon based on state */}
									</IconButton>
								)
							}}
						/>

						<ErrorMessage name="confirmPassword">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>
						<div className="d-flex align-items-start  mb-3 mt-3">
							{/* Checkbox */}
							<FormControl className="p-0">
								<Checkbox
									className="p-1 pt-0"
									checked={values.isAggreed}
									sx={styles.checkboxStyles}
									required
									size="medium"
									name="isAggreed"
									onChange={(e) => {
										setFieldValue('isAggreed', e.target.checked)
									}}
								/>
							</FormControl>

							{/* Text with Link */}
							<Typography
								variant="body2"
								sx={{
									color: '#6C757D', // Text color
									marginLeft: '10px', // Space between checkbox and text
									fontSize: '15px', // Font size
									lineHeight: '24px' // Line height to vertically center align text
								}}
							>
								By clicking here, I state that I have read and understood the
								<Link
									to="/terms"
									style={{
										color: '#007BFF',
										textDecoration: 'none',
										marginLeft: '4px'
									}}
								>
									Terms & Conditions.
								</Link>
							</Typography>
						</div>

						{/* Submit Button */}
						{/* <FormButton
							text="Submit"
							type="submit"
							click={handleSubmit}
							disabled={!values?.isAggreed}
						/> */}
						<Button
							className='border-0 fs-6 fw-semibold text-capitalize'
							type="submit"
							onClick={handleSubmit}
							disabled={!values?.isAggreed}
							sx={styles.buttons}
						>
							Submit
						</Button>
					</Form>
				)}
			</Formik>

			<div className="divider my-5 d-flex align-items-center justify-content-center">
				{/* <span className="line"></span> */}
				<span className="txt" style={{fontSize:"14px"}} >Or Sign up with</span>
				{/* <span className="line"></span> */}
			</div>

			<SocialLogin />
			<p className="redirect-txt text-center mt-4 fs-6">
				Already have an account ? <Link to="/auth/login">Login</Link>
			</p>
		</div>
	)
}

export default SignUp
