import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import Cookies from 'js-cookie'
import { verifyOTP } from '../API/UserAPI'

import FormHeader from '../components/shared/forms/FormHeader'
import FormButton from '../components/shared/forms/FormButton'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signUpAPI } from '../API/AuthAPI'
import { updateSnackbar } from '../redux/snackbarState'
import {
	fetchGroups,
	fetchTags,
	getAddresses,
	getBanks,
	getChecks,
	getPayees,
	getUserData
} from '../utils/helper'
import {Button} from '@mui/material'

const OTPVerify = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [otp, setOtp] = useState('')
	const location = useLocation()
	const [formValues, setformValues] = useState(null)

	useEffect(() => {
		if (location?.state?.values) {
			setformValues({ ...location?.state?.values })
		} else {
			history.push('/auth/sign-up')
		}
	}, [location])

	const handleSubmit = async (event) => {
		event.preventDefault()

		if (otp.length !== 6) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'warning',
					message: 'OTP must be 6 digits.'
				})
			)
			return false
		}

		try {
			const res = await verifyOTP({
				email: formValues?.email.toLowerCase(),
				otp: otp
			})
			userSignUP(formValues) 
		} catch (error) {
			const { response } = error
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: `${
						response?.data?.error?.userMessage || 'Something went wrong.'
					}`
				})
			)
		}
	}

	const userSignUP = async (values) => {
		if (values.password !== values.confirmPassword) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'warning',
					message: 'Password Mismatch.'
				})
			)
			return false
		}

		const body = { ...values }
		body.email = body.email.toLowerCase();
		delete body.confirmPassword

		try {
			const response = await signUpAPI({ ...values, email: values.email.toLowerCase()})

			const { data } = response
			Cookies.set('access-token', data.accessToken, {
				expires: 1 / 24
			})
			Cookies.set('refresh-token', data.refreshToken, {
				expires: 12 / 24
			})
			// localStorage.setItem('user', JSON.stringify(data))
			Cookies.set('user-status', true, {
				expires: 12 / 24
			})
			Cookies.set('subscription', data.subscriptionTaken, {
				expires: 12 / 24
			})
			if (data.subscriptionTaken == true) {
				// if (values.isRememberd) {
				// 	Cookies.set('user-memory', values.isRememberd, {
				// 		expires: 2 / 24
				// 	})
				// } else {
				// 	window.sessionStorage.setItem('user-memory', true)
				// }
				console.log("Suhbscription True")


				dispatch(
					updateSnackbar({
						open: true,
						severity: 'success',
						message: 'Signup Successful.'
					})
				)
				history.push({
					pathname: '/dashboard/main',
					state: {
						showModal: true
					}
				})
			} else {
				history.push('/subscription') // Redirect to the subscription route
			}

			getChecks(dispatch)
			getBanks(dispatch)
			getAddresses(dispatch)
			getPayees(dispatch)
			getUserData(dispatch)
			fetchTags(dispatch)
			fetchGroups(dispatch)

			// history.push({
			// 	pathname: '/dashboard/main',
			// 	state: {
			// 		showModal: true
			// 	}
			// })
		} catch (err) {
			const { response } = err
			const { data } = response
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: data?.error?.userMessage || 'Unknown Error.'
				})
			)
		}
	}


	const styles = {
		label: {
			color: '#6C757D',
			marginBottom: '6px',
			marginLeft: '20px',
			fontSize: '14px'
		},
		inputField: {
			'& .MuiOutlinedInput-root': {
				height: '39px',
				'& fieldset': {
					borderRadius: '10px',
					borderColor: '#CED4DA'
				},
				'&:hover fieldset': {
					borderColor: '#A9A9A9'
				},
				'&.Mui-focused fieldset': {
					borderColor: '#7C4DFF',
					boxShadow: '0 0 0 2px rgba(124, 77, 255, 0.3)'
				}
			},
			'& .MuiInputBase-input': {
				color: '#495057',
				padding: '14px 12px'
			}
		},
		checkboxStyles: {
			'&.Mui-checked': {
				color: '#7C4DFF' // Customize the color to match the design
			},
			'& .MuiSvgIcon-root path': {
				// stroke: '#000000', // Set the color of the checkmark to black when checked
				strokeWidth: 1 // Set the thickness of the checkmark line
			},
			'& .MuiSvgIcon-root': {
				fontSize: 35,
				marginTop: '4px' // Customize the size of the checkbox
			},
			color: '#CED4DA' // Default border color when unchecked
		},
		errorMessage: {
			color: 'red',
			marginLeft: '20px',
			fontSize: '12px'
		},
		checkbox: {
			'&.Mui-checked': {
				color: 'green'
			},
			'& .MuiSvgIcon-root': {
				fontSize: 18
			}
		},
		buttons:{
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '16px 36px',
			gap: '12px',
			width: '100%',
			height: '48px',
			background: 'linear-gradient(90deg, #693BAA 4%, #B04EC3 100%)',
			borderRadius: '10px !important',
			flex: 'none',
			order: 7,
			flexGrow: 0,
			color: 'white', // Optional: Set text color
			'&:disabled': {
				background: '#CED4DA', // Optional: Change background when disabled
			}
		}
	}
	return (
		<div className="auth-form-div">
			<div className="pt-5">
				{/* <FormHeader
					head=" Verify Your Email "
					text={`We sent a verification code to ${formValues?.email}`}
				/> */}
				<div
					className="form-head mb-5"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						fontWeight: 'initial'
					}}
				>
					<h2
						className="fs-1 mb-1 text-center form-header"
						style={{ fontWeight: 'initial' }}
					>
						Verify Your Email
					</h2>
				</div>
			</div>

			<OtpInput
				value={otp}
				onChange={setOtp}
				numInputs={6}
				className="d-flex align-items-center justify-content-between mb-5"
				renderInput={(props) => (
					<input
						{...props}
						className="otpInput fs-3 text-black mb-5"
						type="number"
					/>
				)}
				containerStyle={{
					justifyContent: 'space-between'
				}}
			/>
			{/* <FormButton
				text="Submit"
				type="submit"
				click={handleSubmit}
				disabled={otp.length !== 6 ? true : false}
			/> */}
			<Button
				className='border-0 fs-6 fw-semibold text-capitalize mt-4'
				type="submit"
				onClick={handleSubmit}
				sx={styles.buttons}
				disabled={otp.length !== 6 ? true : false}
			>
				Submit
			</Button>

			<p className="redirect-txt text-center mt-5 fs-6">
				Back To <Link to="/auth/sign-up">SignUp</Link>
			</p>
		</div>
	)
}

export default OTPVerify
