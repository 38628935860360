import React from 'react'
import ButtonComponent from '../components/shared/ButtonComponent'
import { stripeLogo } from '../assets/svg/IntegrationsSvg'
import { subscriptionAPI } from '../API/UserAPI'
import { updateSnackbar } from '../redux/snackbarState'
import Cookies from 'js-cookie'


const Subscription = () => {
const accessToken = Cookies.get('access-token');
    
    const getCheckout = async (data1,dispatch) => {
		// console.log(data1)
		try {
			const res = await subscriptionAPI(data1,accessToken)
			const { data } = res
			if(data){
				window.open(`${data.stripeCheckoutUrl}`, '_blank');
			}
		} catch (err) {
			console.log('err', err)
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Error while Checkout.',
					severity: 'error'
				})
			)
		}
	}

	return (
		<div className="d-flex align-items-center justify-content-center"  style={{height: "100vh"}}>
			<div className="integration-grid1 d-flex justify-content-center">
				<div className="row m-0 p-0 mt-5 integration-div1 col-4">
					<div className="col-12">
						<div
							className=" position-relative d-flex align-items-center justify-content-center flex-column"
							sx={{
								'&.MuiButtonBase-root': {
									'&:hover': {
										backgroundColor: 'rgba(0, 128, 0, 0.04)'
									},
									color: '#12221f'
								}
							}}
						>
							<h3 className="logo">Subscription</h3>
							<h3 className="logo">You are not subscribed</h3>
							<div className="d-flex justify-space-even mt-3"  style={{width: "100%"}}>
                                <ButtonComponent
										text={'Start Trial'}
										variant="btn btn-success btn-ex-lg"
										onClick={() => { getCheckout(true) }}
									/>
									<ButtonComponent
										text={'Subscribe'}
										variant="dark btn-ex-lg"
										onClick={() => { getCheckout(false) }}
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* )} */}
		</div>
	)
}

export default Subscription
