import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import PageHeader from '../components/shared/PageHeader'
import BasicDetails from '../components/views/MyProfile/BasicDetails'
import UpdatePassword from '../components/views/MyProfile/UpdatePassword'
import { updateUser } from '../API/UserAPI'
import { updateSnackbar } from '../redux/snackbarState'
import { getUserData } from '../utils/helper'
import Address from '../components/views/MyProfile/Address'
import FormModalMUI from '../components/shared/Modals/FormModalMUI'
import Button from '@mui/material/Button'
import UserPreferences from '../components/views/MyProfile/UserPreferences'

const MyProfile = () => {
	// const [isModalOpen, setModalOpen] = useState(false);

	// const openModal = () => {
	// 	setModalOpen(true)
	// }

	// const closeModal = () => {
	// 	setModalOpen(false)
	// }

	const userData = useSelector((state) => state.appData.userData)
	const dispatch = useDispatch()

	const [initialValues, setInitialValues] = useState(null)

	const validationSchema = Yup.object({
		email: Yup.string().email('Incorrect Email'),
		firstName: Yup.string(),
		lastName: Yup.string(),
		middleName: Yup.string(),
		phone: Yup.string(),
		dateOfBirth: Yup.date()
	})

	const onSubmit = async (values) => {
		try {
			const res = await updateUser({ ...values, email: values.email.toLowerCase()})

			dispatch(
				updateSnackbar({
					open: true,
					message: 'User Data Updated successfully',
					severity: 'success'
				})
			)

			getUserData(dispatch)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Unable to Update.',
					severity: 'error'
				})
			)
		}
	}

	useEffect(() => {
		if (userData && Object.keys(userData).length !== 0) {
			setInitialValues({
				email: userData?.data?.email || '',
				firstName: userData?.data?.firstName || '',
				middleName: userData?.data?.middleName || '',
				lastName: userData?.data?.lastName || '',
				dateOfBirth: userData?.data?.dateOfBirth || new Date(),
				phone: userData?.data?.phone || ''
			})
		}
	}, [userData])

	return (
		<div className="container">
			<PageHeader
				text="My Profile"
				info="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
			/>
			{initialValues ? (
				<>
					<BasicDetails
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
				/>
					<Address />
					<UpdatePassword />
					<UserPreferences />

					{/* <Button variant="outlined" onClick={openModal}>
						Open dialog
					</Button>
					{
						isModalOpen && <FormModalMUI onClose={closeModal} open={isModalOpen}/>
					} */}
				</>
			) : null}
		</div>
	)
}

export default MyProfile
