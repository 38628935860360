///Packages components
import React, { useEffect, useState, useRef } from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import * as Yup from 'yup'
import { useReactToPrint } from 'react-to-print'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router'
import { Alert } from '@mui/material'
import { Button } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'

////Icons Used

///Custom components used
import FormComponents from '../components/shared/forms'
import PageHeader from '../components/shared/PageHeader'
import ButtonComponent from '../components/shared/ButtonComponent'

////Redux functions used
import { updateSnackbar } from '../redux/snackbarState'

///Apis used
import {
	addCheck,
	getCheckNumber,
	updateCheck,
	validateCheck,
	bulkaddCheck
} from '../API/ChecksAPI'
import Printcheck from '../components/views/Printcheck'
import { getChecks, printStyles } from '../utils/helper'

import FormModalMUI from '../components/shared/Modals/FormModalMUI'
import AddTag from '../components/views/forms/AddTag'

import AddBank from '../components/views/forms/AddBank'
import AddPayee from '../components/views/forms/AddPayee'
import NestedSelect from '../components/shared/forms/NestedSelect'
import CreateCheckSkeleton from '../components/shared/Skeletons/CreateCheckSkeleton'
import AddAddress from '../components/views/forms/AddAddress'
import AddSignature from '../components/views/forms/AddSignature'

import team from '../assets/images/team.jpeg'

const CreateCheck = () => {
	const banks = useSelector((state) => state.appData.banks)
	const payees = useSelector((state) => state.appData.payees)
	const addresses = useSelector((state) => state.appData.addresses)
	const allGroups = useSelector((state) => state.appData.groups)
	const userData = useSelector((state) => state.appData.userData)
	const organizationData = useSelector((state) => state.appData.organizations)
	const [organizationData1, setOrgizationData] = useState([])
	const [org, setOrg] = useState(false)
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)
	// console.log(addresses, 'addresses is here Data')

	useEffect(() => {
		let result = organizationData.data.filter((data) => {
			return data?._id === selectedOrganization
		})
		console.log(result, 'Result Is HERE')
		if (result?.addressLine1 == '') {
			console.log('1')
			setOrg(selectedOrganization == 'No Org' ? false : true)
		} else if (addresses?.data.length == 0) {
			console.log('2')
			setOrg(
				selectedOrganization !== 'No Org'
					? result[0]?.addressLine1 == ''
						? true
						: false
					: true
			)
		} else if (addresses?.data.length !== 0) {
			console.log('3')
			setOrg(
				selectedOrganization !== 'No Org'
					? result[0]?.addressLine1 == ''
						? true
						: false
					: false
			)
		} else if (organizationData1 !== undefined) {
			setOrg(true)
		} else {
			setOrg(false)
		}

		if (result.length > 0) {
			setOrgizationData(result[0])
		} else {
			// console.log('');
			setOrgizationData()
		}
	}, [organizationData, selectedOrganization])

	console.log(org, 'org is here')

	const printRef = useRef(null)
	const location = useLocation()
	const history = useHistory()

	const [printCheck, setPrintCheck] = useState(false)
	const [formInitialValues, setFormInitialValues] = useState(null)
	const [formInitialValues1, setFormInitialValues1] = useState([
		{
			invoiceId: '',
			quantity: '',
			memo: '',
			checkNumber: '',
			amount: '',
			bankId: '',
			payeeId: '',
			tags: [],
			status: 'DRAFT',
			isSignatureSelected: userData?.data?.preferences?.wantSignature || false,
			issuedDate: new Date()
		}
	])
	const [printContent, setPrintContent] = useState([])
	const [isUpdate, setIsUpdate] = useState(location?.state?.isUpdate || false)
	const [selectedTag, setSelectedTag] = useState([])
	const [isTagModalOpen, setTagModalOpen] = useState(false)
	const [isAddBankModalOpen, setAddBankModalOpen] = useState(false)
	const [isAddPayeeModalOpen, setAddPayeeModalOpen] = useState(false)
	const [checkId, setCheckId] = useState('')
	const [submitted, setSubmitted] = useState(false)
	const [isValidCheckNumber, setValidCheckNumber] = useState(false)
	const [checkNumberLength, setCheckNumberLength] = useState(4)
	const [touched, setTouched] = useState(false)
	const [checkNumber, setCheckNumber] = useState('')

	const [isSignatureModalOpen, setSignatureModalOpen] = useState(false)

	const [isAddressModalOpen, setAddressModalOpen] = useState(false)
	const [forms, setForms] = useState([
		{
			bankId: '',
			payeeId: '',
			amount: '',
			checkNumber: '',
			issuedDate: new Date(),
			invoiceId: '',
			memo: '',
			tags: [],
			status: '',
			isSignatureSelected: false
		}
	])

	const addForm = () => {
		// Duplicate the last form's values as a new form
		const newForm = { ...forms[forms.length - 1] }
		newForm.bankId = ''
		newForm.checkNumber = ''
		console.log(forms, 'Forms')
		setForms([...forms, newForm])
	}
	const removeForm = (index) => {
		setForms(forms.filter((_, idx) => idx !== index))
	}

	const openAddressModal = () => {
		setAddressModalOpen(true)
	}

	const getFormDataArray = () => {
		const formDataArray = forms.map((form, index) => ({
			bankId: form.bankId[index],
			payeeId: form.payeeId,
			amount: form.amount[index],
			checkNumber: form.checkNumber,
			issuedDate: form.issuedDate,
			invoiceId: form.invoiceId,
			memo: form.memo,
			tags: form.tags,
			status: form.status,
			isSignatureSelected: form.isSignatureSelected
		}))

		return formDataArray
	}

	const formDataArray = getFormDataArray()

	const [showPreview, setShowPreview] = useState(false)

	const dispatch = useDispatch()

	const bankOptions = banks?.data.map((bank, index) => {
		return { key: bank.bankName, value: bank._id }
	})

	const payeeOptions = payees?.data?.map((payee, index) => {
		return { key: payee.name, value: payee.id }
	})  

	console.log(payeeOptions,"Payee Option Is Here")

	const validationSchema = Yup.object().shape({
		bankId: Yup.string().required('Required'),
		payeeId: Yup.string().required('Required!'),
		// amount: Yup.number().required('Required!'),
		issuedDate: Yup.date().required('Required!'),
		checkNumber: Yup.string()
			.matches(/^[0-9]*$/, 'Please enter only numbers')
			// .min(4, 'Minimum 4 digits')
			.max(checkNumberLength, `Max ${checkNumberLength} allowed`)
			.required('Required!'),
		invoiceId: Yup.string(),
		memo: Yup.string().max('30', 'Max 30 characters.'),
		tags: Yup.array(),
		isSignatureSelected: Yup.boolean()
	})

	// const singleFormInitialValues = {
	// 	checkNumber: '',
	// 	amount: '',
	// 	issuedDate: new Date(),
	// 	payeeId: '',
	// 	bankId: '',
	// 	memo: '',
	// 	invoiceId: '',
	// 	tags: [],
	// 	isSignatureSelected: false,
	// 	status: "DRAFT"
	//   };

	const handleSubmit = (values) => {
		console.log('Submitted Values:', values)
	}

	const initialValues = {
		checkNumber: '',
		amount: '',
		issuedDate: new Date(),
		payeeId: '',
		bankId: '',
		memo: '',
		invoiceId: '',
		tags: [],
		isSignatureSelected: userData?.data?.preferences?.wantSignature || false,
		status: 'DRAFT'
	}

	const [isFirstTimeSignUp, setIsFirstTimeSignUp] = useState(false)

	useEffect(() => {
		let firstTime = localStorage.getItem('isFirstTimeSignUp') === 'true'
		setIsFirstTimeSignUp(firstTime)
		// console.log(org,"Updated ORG IS HERE")
	}, [])

	useEffect(() => {
		setPrintContent({ ...location?.state?.printContent?.rowData })
		if (location?.state?.printCheck) {
			setPrintCheck(true)
		}
		if (location?.state?.isUpdate) {
			setIsUpdate(true)
		}
	}, [location, isUpdate])

	console.log(printContent, 'printContent')
	useEffect(() => {
		if (banks && banks.data) {
			console.log(banks, 'Bank')
			console.log(banks.data, 'BankData')

			if (isUpdate) {
				const checkData = {
					...location?.state?.printContent
				}
				console.log('checkData', checkData)
				const { data } = userData
				const { preferences } = data
				// debugger

				setCheckId(checkData?._id)
				setCheckNumber(checkData?.checkNumber)
				setFormInitialValues({
					checkNumber: checkData?.checkNumber,
					amount: checkData.amount || '',
					issuedDate: checkData?.issuedDate
						? new Date(checkData.issuedDate)
						: new Date(),
					payeeId: checkData?.payee?._id || '',
					bankId: checkData?.bankDetails?._id || '',
					memo: checkData?.memo || '',
					invoiceId: checkData?.invoiceId || '',
					tags: [...checkData?.tags],
					isSignatureSelected: checkData?.isSignatureSelected,
					status: checkData?.status || ''
				})

				// setFormInitialValues1({
				// 	checkNumber: checkData?.checkNumber,
				// 	amount: 1000 || '',
				// 	issuedDate: checkData?.issuedDate
				// 		? new Date(checkData.issuedDate)
				// 		: new Date(),
				// 	payeeId: checkData?.payee?._id || '',
				// 	bankId: checkData?.bankDetails?._id || '',
				// 	memo: checkData?.memo || '',
				// 	invoiceId: checkData?.invoiceId || '',
				// 	tags: [...checkData?.tags],
				// 	isSignatureSelected: checkData?.isSignatureSelected,
				// 	status: checkData?.status || ''
				// })
//    console.log(,"checkData is here")
				setFormInitialValues1([
					{
						checkNumber: checkData?.checkNumber,
						amount: checkData.amount || 0,
						issuedDate: checkData?.issuedDate
							? new Date(checkData.issuedDate)
							: new Date(),
						payeeId: checkData.payee._id || '',
						bankId: checkData?.bankDetails?._id || '',
						memo: checkData?.memo || '',
						invoiceId: checkData?.invoiceId || '',
						tags: [...checkData?.tags],
						isSignatureSelected: checkData?.isSignatureSelected,
						status: checkData?.status || ''
					}
				])

				// setForms(
				// 	forms.map((form) => ({
				// 		checkNumber: generatePaddedNumberString(
				// 			// preferences?.defaultCheckNumberLength,
				// 			5,
				// 			checkData?.checkNumber
				// 		),
				// 		amount: 100 || '',
				// 		issuedDate: checkData?.issuedDate
				// 			? new Date(checkData.issuedDate)
				// 			: new Date(),
				// 		payeeId: checkData?.payee?._id || '',
				// 		bankId: checkData?.bankDetails?._id || '',
				// 		memo: checkData?.memo || '',
				// 		invoiceId: checkData?.invoiceId || '',
				// 		tags: [...checkData?.tags],
				// 		isSignatureSelected: checkData?.isSignatureSelected,
				// 		status: checkData?.status || ''
				// 	}))
				// )

				setSelectedTag([...checkData?.tags])
			}
		}
	}, [isUpdate, banks])

	useEffect(() => {
		if (userData?.isLoading === false) {
			if (!location?.state?.isUpdate) {
				if (
					userData?.data &&
					userData?.data?.preferences?.defaultCheckNumberGen === 'AUTOMATIC'
				) {
					getAutoGeneratedCheckNumber()
				} else {
					const { data } = userData
					const { preferences } = data

					setFormInitialValues({
						...initialValues
					})
					setValidCheckNumber(true)
					setCheckNumberLength(preferences?.defaultCheckNumberLength)
				}
			} else {
				const { data } = userData
				const { preferences } = data
				setValidCheckNumber(true)
				setCheckNumberLength(preferences?.defaultCheckNumberLength)
			}
		}
	}, [userData])

	const generatePaddedNumberString = (n, number) => {
		const numberString = number.toString()
		const paddedNumberString = numberString.padStart(n, '0')

		return paddedNumberString
	}

	const handleBankChange = (event, value) => {
		console.log(value, 'hello ')
	}

	const getAutoGeneratedCheckNumber = async (value) => {
		try {
			const res = await getCheckNumber(value)
			const { data } = res
			// console.log(res,"ResponseCheck")
			const { nextAvailableCheckNumber } = data
			// console.log(nextAvailableCheckNumber)
			let checkString = nextAvailableCheckNumber.toString()

			// const preferences = userData?.data.preferences
			const preferences = 5
			checkString = checkString.padStart(
				// preferences.defaultCheckNumberLength,
				preferences,
				'0'
			)

			setFormInitialValues({
				...initialValues,
				checkNumber: checkString,
				bankId: value
			})
			setForms(
				forms.map((form) => ({
					...form,
					checkNumber: checkString,
					bankId: value
				}))
			)
			setValidCheckNumber(true)
			// setCheckNumberLength(preferences?.defaultCheckNumberLength)
			setCheckNumberLength(preferences)
		} catch (error) {
			console.log(error)
		}
	}

	const getAutoGeneratedCheckNumber1 = async (value) => {
		try {
			const res = await getCheckNumber(value)
			const { data } = res
			const { nextAvailableCheckNumber } = data
			let checkString = nextAvailableCheckNumber.toString()

			const preferences = 5
			checkString = checkString.padStart(preferences, '0')

			return checkString // Return the generated check number value
		} catch (error) {
			console.log(error)
			return null
		}
	}
	const [nestedListOptions, setNestedListOptions] = useState(null)
	const [showWarning, setWarning] = useState(false)

	const [isDirty, setIsDirty] = useState(false)

	const setDirty = (dirty) => {
		setIsDirty(dirty)
	}

	const closeAddressModal = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setAddressModalOpen(false)
			setWarning(false)
		}
	}

	useEffect(() => {
		makeNestedListOptions(allGroups.data)
	}, [allGroups])

	const makeNestedListOptions = async (groups) => {
		let options = groups
			.filter(
				(group) => group.hasOwnProperty('name') && group?.tags?.length > 0
			)
			.map((group) => {
				if (group?.tags?.length > 0) {
					let categoryList = group?.tags?.map((item) => {
						return {
							key: item?.name,
							value: item?._id,
							color: item?.color
						}
					})

					return {
						categoryHead: group.name,
						categoryHeadColor: group.color,
						categoryList: categoryList
					}
				}
			})

		let independent_tags = groups
			.filter((group) => !group.hasOwnProperty('name'))
			.map((group) => {
				let categoryList = group?.tags?.map((item) => {
					return {
						key: item?.name,
						value: item?._id,
						color: item?.color
					}
				})

				return {
					categoryHead: 'Other tags',
					categoryHeadColor: 'grey',
					categoryList: categoryList
				}
			})

		options.push(independent_tags[0])
		setNestedListOptions(options)
	}
	const [updatedTagsArray, setUpdatedTagsArray] = useState([])

	const handleChange = (id, formIndex) => {
		setTouched(true)

		// setSelectedTag((prevTags) => {
		// 	const updatedTags = [...prevTags]
		// 	const formTags = Array.isArray(updatedTags[formIndex])
		// 		? [...updatedTags[formIndex]]
		// 		: []

		// 	if (formTags.includes(id)) {
		// 		const index = formTags.indexOf(id)
		// 		if (index > -1) {
		// 			formTags.splice(index, 1)
		// 		}
		// 	} else {
		// 		formTags.push(id)
		// 	}

		// 	updatedTags[formIndex] = formTags
		// 	const newArray = [...updatedTags]
		// 	console.log(newArray)

		// 	return newArray
		// })
		setSelectedTag((prevTags) => {
			const updatedTags = [...prevTags]
			const formTags = Array.isArray(updatedTags[formIndex])
				? [...updatedTags[formIndex]]
				: []

			if (formTags.includes(id)) {
				const index = formTags.indexOf(id)
				if (index > -1) {
					formTags.splice(index, 1)
				}
			} else {
				formTags.push(id)
			}

			updatedTags[formIndex] = formTags

			const newArray = [...updatedTags]
			console.log(newArray) // Log the new array of objects
			setUpdatedTagsArray(newArray)
			return newArray
		})
	}

	const openTagEditor = () => {
		setTagModalOpen(true)
	}

	const closeTagEditor = (new_tag) => {
		setTagModalOpen(false)
	}

	const openAddBank = () => {
		setAddBankModalOpen(true)
	}

	const closeAddBank = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setAddBankModalOpen(false)
			setWarning(false)
		}
	}

	const openAddPayee = () => {
		setAddPayeeModalOpen(true)
	}

	const closeAddPayee = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setAddPayeeModalOpen(false)
			setWarning(false)
		}
	}

	const [allBodies, setAllBodies] = useState([])
	const showPreviewCheck = (values) => {
		// console.log(values,"Values")
		setAllBodies([])
		values.forms.forEach((value) => {
			let payee = payees.data.find((payee) => payee.id === value.payeeId)
			let bankDetails = banks.data.find((bank) => bank._id === value.bankId)

			let payeeCopy = { ...payee }
			let bankDetailsCopy = { ...bankDetails }

			delete payeeCopy.id
			delete bankDetailsCopy._id

			let body = {
				...value,
				bankDetails: {
					...bankDetailsCopy
				},
				payee: {
					...payeeCopy
				},
				// address: {
				// 	...addresses.data[0]
				// },
				address: organizationData1
					? {
							companyName: organizationData1.organizationName,
							addressLine1: organizationData1.addressLine1,
							addressLine2: organizationData1.addressLine2,
							city: organizationData1.city,
							state: organizationData1.state,
							zipCode: organizationData1.zip,
							country: organizationData1.country,
							name1: organizationData1.organizationName
					  }
					: {
							...addresses.data[0]
					  },
				tags: [...selectedTag],
				isSignatureSelected: value.isSignatureSelected || false
			}
			console.log(organizationData1, 'OrganizationData')
			delete body.payeeId
			delete body.bankId
			// console.log({ ...body })
			setPrintContent({ ...body })
			console.log({ ...body })
			setAllBodies((prevBodies) => [...prevBodies, body])
			console.log(allBodies, 'AllBodies')

			// setPrintContent((prevContent) => [...prevContent, body]);
			// setPrintContent((prevContent) => {
			// 	if (prevContent === null || prevContent === undefined) {
			// 		return [body];
			// 	} else {
			// 		return [...prevContent, body];
			// 	}
			// });
		})

		setShowPreview(true)
	}
	const onSubmit = async (values, operation, resetForm) => {
		console.log(updatedTagsArray)
		console.log(values)

		const updatedValues = values.forms.map((value, index) => {
			return {
				...value,
				tags: updatedTagsArray[index]
			}
		})

		console.log(updatedValues, 'Updated')
		// values = updatedValues[0]

		// debugger
		if (!submitted) {
			setSubmitted(true)
			if (addresses.data.length === 0) {
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Add Your Address first.',
						severity: 'error'
					})
				)
				setSubmitted(false)
				return false
			}

			// if (!isUpdate) {
			// 	try {
			// 		const resp = await validateCheck(values.checkNumber, values?.bankId)
			// 	} catch (error) {
			// 		dispatch(
			// 			updateSnackbar({
			// 				open: true,
			// 				message: 'Check Number already exist.',
			// 				severity: 'error'
			// 			})
			// 		)
			// 		setSubmitted(false)
			// 		return false
			// 	}
			// }

			let payee = payees.data.find((payee) => payee.id === values.payeeId)
			let bankDetails = banks.data.find((bank) => bank._id === values.bankId)

			let payeeCopy = { ...payee }
			let bankDetailsCopy = { ...bankDetails }

			delete payeeCopy.id
			delete bankDetailsCopy._id

			let body = null

			if (!isUpdate) {
				console.log(values)
				// body = {
				// 	...values,
				// 	bankDetails: {
				// 		bankId: values?.bankId
				// 	},
				// 	payee: {
				// 		payeeId: values?.payeeId
				// 	},
				// 	address: {
				// 		addressId: addresses.data[0]._id
				// 	},
				// 	// tags: [...selectedTag],
				// 	isBlankCheck: false,
				// 	isSignatureSelected: values?.isSignatureSelected || false
				// }
				const dataArray = updatedValues.map((item) => {
					const { bankId, payeeId, ...rest } = item
					return {
						...rest,
						bankDetails: {
							bankId: item.bankId // Dynamic bankId value
						},
						address: {
							addressId: addresses.data[0]._id // Dynamic address value
						},
						payee: {
							payeeId: item.payeeId // Dynamic payeeId value
						}
					}
				})
				body = dataArray
			} else {
				const dataArray = updatedValues.map((item) => {
					return {
						...item,
						bankDetails: {
							bankId: item.bankId // Dynamic bankId value
						},
						address: {
							addressId: addresses.data[0]._id // Dynamic address value
						},
						payee: {
							payeeId: item.payeeId // Dynamic payeeId value
						}
					}
				})
				console.log(dataArray, 'IsUpdate')

				// body = {
				// 	...values,
				// 	bankDetails: {
				// 		_id: values?.bankId
				// 	},
				// 	address: {
				// 		_id: addresses.data[0]._id
				// 	},
				// 	payee: {
				// 		_id: values?.payeeId
				// 	},
				// 	tags: [...selectedTag]
				// }
				// debugger
			}

			if (body && body.payeeId) {
				delete body.payeeId
			}
			if (body && body.bankId) {
				delete body.bankId
			}

			setCheckNumber(values.checkNumber)

			try {
				if (isUpdate) {
					const dataArray = updatedValues.map((item) => {
						const { bankId, payeeId, ...rest } = item
						return {
							...rest,
							bankDetails: {
								bankId: item.bankId // Dynamic bankId value
							},
							// address: {
							// 	addressId: addresses.data[0]._id // Dynamic address value
							// },
							address: organizationData1
								? {
										companyName: organizationData1.organizationName,
										addressLine1: organizationData1.addressLine1,
										addressLine2: organizationData1.addressLine2,
										city: organizationData1.city,
										state: organizationData1.state,
										zipCode: organizationData1.zip,
										country: organizationData1.country,
										name1: organizationData1.organizationName
								  }
								: {
										...addresses.data[0]
								  },
							payee: {
								payeeId: item.payeeId // Dynamic payeeId value
							}
						}
					})
					body = dataArray
					const res = await updateCheck(body[0], checkId)

					if (operation === 'save&Print') {
						setPrintContent({ ...body })
						handlePrint()
					}
				} else {
					// console.log(dataArray)
					console.log(body)
					const res = await bulkaddCheck(body)
					const { data } = res

					setCheckId(data?._id)
					setPrintContent({ ...data })
					if (operation === 'save&Print') {
						handlePrint()
					} else {
						resetForm()
						getAutoGeneratedCheckNumber()
						setShowPreview(false)
						setSelectedTag([])
					}
				}

				dispatch(
					updateSnackbar({
						open: true,

						message: `${
							!isUpdate
								? 'Check added successfully.'
								: 'Check updated successfully.'
						}`,
						severity: 'success'
					})
				)
				history.push('/dashboard/my-checks')

				getChecks(dispatch)
			} catch (err) {
				const { response } = err
				const { data } = response
				const { error } = data
				dispatch(
					updateSnackbar({
						open: true,
						message: `${
							error?.userMessage || !isUpdate
								? 'Unable to add check.'
								: 'Unable to update check.'
						}`,
						severity: 'error'
					})
				)
			}
			setSubmitted(false)
		}
	}

	const savePrintBlankCheck = async (values) => {
		if (!submitted) {
			setSubmitted(true)
			if (addresses.data.length === 0) {
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Add Your Address first.',
						severity: 'error'
					})
				)
				setSubmitted(false)

				return false
			}

			if (values?.bankId === '') {
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Select Bank first.',
						severity: 'info'
					})
				)
				setSubmitted(false)
				return false
			}

			if (!isUpdate) {
				try {
					const resp = await validateCheck(values.checkNumber)
				} catch (error) {
					dispatch(
						updateSnackbar({
							open: true,
							message: 'Check Number already exist.',
							severity: 'error'
						})
					)
					setSubmitted(false)
					return false
				}
			}

			let bankDetails = banks.data.find((bank) => bank._id === values.bankId)

			let bankDetailsCopy = { ...bankDetails }

			delete bankDetailsCopy._id

			let body = {
				amount: 0,
				invoiceId: '',
				bankDetails: {
					...bankDetailsCopy
				},
				payee: {},
				address: {
					...addresses.data[0]
				},
				tags: [],
				isBlankCheck: true,
				isSignatureSelected: values?.isSignatureSelected,
				issuedDate: values?.issuedDate,
				memo: values?.memo,
				checkNumber: values?.checkNumber,
				status: 'BLANK'
			}

			setPrintContent({ ...body })
			setCheckNumber(body?.checkNumber)

			try {
				const res = await addCheck(body)
				const { data } = res
				setCheckId(data?.id)

				handlePrint()
				getAutoGeneratedCheckNumber()
				setShowPreview(false)
				setSelectedTag([])

				dispatch(
					updateSnackbar({
						open: true,

						message: `${
							!isUpdate
								? 'Check added successfully.'
								: 'Check updated successfully.'
						}`,
						severity: 'success'
					})
				)
				history.push('/dashboard/my-checks')
				getChecks(dispatch)
			} catch (err) {
				const { response } = err
				const { data } = response
				const { error } = data
				dispatch(
					updateSnackbar({
						open: true,
						message: `${
							error?.userMessage || !isUpdate
								? 'Unable to add check.'
								: 'Unable to update check.'
						}`,
						severity: 'error'
					})
				)
			}
			setSubmitted(false)
		}
	}

	const updateCheckStatus = (id) => {
		try {
			const res = updateCheck({ status: 'UNCLEARED' }, id)
		} catch (error) {
			console.log(error)
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
		fonts: [
			{
				family: 'Inter',
				source:
					'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'
			}
		],
		pageStyle: printStyles,
		documentTitle: `Check Number - ${checkNumber}.pdf`
	})

	const checkStatusOptions = [
		{ key: 'Draft', value: 'DRAFT' },
		{ key: 'Void', value: 'VOID' }
	]

	const signatureOptions = [
		{ key: 'Yes', value: true },
		{ key: 'No', value: false }
	]

	const [blankStatus, setBlankstatus] = useState(false)

	const [blankFormIndexes, setBlankFormIndexes] = useState([])
	console.log(blankFormIndexes, 'BLANK FORM INDEX')
	const initialFormValues1 = [
		{
			invoiceId: '',
			quantity: '',
			memo: '',
			checkNumber: '',
			amount: '',
			bankId: '',
			payeeId: '',
			tags: [],
			status: 'DRAFT',
			isSignatureSelected: userData?.data?.preferences?.wantSignature || false,
			issuedDate: new Date()
		}
	]
	return (
		<>
			<div className="container">
				<PageHeader
					text={isUpdate ? 'Edit Check' : 'Create Check'}
					info="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
				/>

				{/* {addresses?.data.length} */}
				{/* {console.log(organizationData1,"Data Is here")} */}
				{/* {console.log(org,"Organization is here show checks or not ")} */}

				{console.log(org, 'ORg is here updated ')}
				{org && (
					<Alert
						severity="info"
						className="mb-4 d-flex align-items-center w-100"
						sx={{
							'.MuiAlert-message': {
								width: '100%'
							}
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<p className="m-0 fs-6" style={{ color: '#161617;' }}>
								You need to add an address to create checks.
							</p>
							<button
								className="fw-semibold m-0 fs-6 border-0 bg-transparent p-0"
								onClick={openAddressModal}
								style={{
									color: '#0439EA'
								}}
							>
								Add Address
							</button>
						</div>
					</Alert>
				)}
				{/* {!org ? (
					 addresses?.data.length == 0 ? (
						// organizationData1 !== null && organizationData1?.addressLine1 == "" ?
						<Alert
							severity="info"
							className="mb-4 d-flex align-items-center w-100"
							sx={{
								'.MuiAlert-message': {
									width: '100%'
								}
							}}
						>
							<div className="d-flex align-items-center justify-content-between">
								<p className="m-0 fs-6" style={{ color: '#161617;' }}>
									You need to add an address to create checks.
								</p>
								<button
									className="fw-semibold m-0 fs-6 border-0 bg-transparent p-0"
									onClick={openAddressModal}
									style={{
										color: '#0439EA'
									}}
								>
									Add Address
								</button>
							</div>
						</Alert>
					) : // : null
					 org ? (
						<Alert
							severity="info"
							className="mb-4 d-flex align-items-center w-100"
							sx={{
								'.MuiAlert-message': {
									width: '100%'
								}
							}}
						>
							<div className="d-flex align-items-center justify-content-between">
								<p className="m-0 fs-6" style={{ color: '#161617;' }}>
									You need to add an address to create checks.
								</p>
								<button
									className="fw-semibold m-0 fs-6 border-0 bg-transparent p-0"
									onClick={openAddressModal}
									style={{
										color: '#0439EA'
									}}
								>
									Add Address
								</button>
							</div>
						</Alert>
					) : null
				) : null} */}

				{/* {console.log(organizationData1,"Data 1 Is Here")} */}
				{/* {organizationData1?.isLoading ? null : organizationData1?.addressLine1 == null ? (
<Alert
	severity="info"
	className="mb-4 d-flex align-items-center w-100"
	sx={{
		'.MuiAlert-message': {
			width: '100%'
		}
	}}
>
	<div className="d-flex align-items-center justify-content-between">
		<p className="m-0 fs-6" style={{ color: '#161617;' }}>
			You need to add an address to create checks.
		</p>
		<button
			className="fw-semibold m-0 fs-6 border-0 bg-transparent p-0"
			onClick={openAddressModal}
			style={{
				color: '#0439EA'
			}}
		>
			Add Address
		</button>
	</div>
</Alert> 
// : null
) : null} */}

				{userData.isLoading ? null : userData?.data?.signatureUrl === '' ||
				  userData?.data?.signatureUrl === null ? (
					<Alert
						severity="info"
						className="mb-4 d-flex align-items-center w-100"
						sx={{
							'.MuiAlert-message': {
								width: '100%'
							}
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<p className="m-0 fs-6" style={{ color: '#161617;' }}>
								You need to add signture to sign checks. You can also update in
								Profile Settings.
							</p>
							<button
								className="fw-semibold m-0 fs-6 border-0 bg-transparent p-0"
								onClick={() => {
									setSignatureModalOpen(!isSignatureModalOpen)
								}}
								style={{
									color: '#0439EA'
								}}
							>
								Add Signature
							</button>
						</div>
					</Alert>
				) : null}
				{formInitialValues ? (
					printCheck ? (
						<>
							{allBodies.map((body, index) => (
								<Printcheck key={index} printContent={body} />
							))}
						</>
					) : (
						// <Formik
						// 	initialValues={formInitialValues}
						// 	validationSchema={validationSchema}
						// 	// onSubmit={onSubmit}
						// 	onSubmit={async (values) => {
						// 		await new Promise((r) => setTimeout(r, 500));
						// 		alert(JSON.stringify(values, null, 2));
						// 	  }}
						// 	enableReinitialize={true}
						// >
						// 	{({ handleSubmit, values, resetForm, isValid, dirty }) => {
						// 		return (
						// 			<>
						// 				<div className="row mb-3 px-2 m-0">

						// 					{forms.length === 0 ? (
						// 						<ButtonComponent
						// 							text="Add Form"
						// 							variant="light"
						// 							type="button"
						// 							click={addForm}
						// 						/>
						// 					) : (
						// 						forms.map((initialValues, index) => (
						// 							<Formik
						// 								key={index}
						// 								initialValues={initialValues}
						// 								onSubmit={(values) => handleSubmit(values, index)}
						// 								enableReinitialize={true}
						// 							>
						// 								{({ handleSubmit, values, resetForm, isValid, dirty }) => (
						// 									<Form onSubmit={handleSubmit}>
						// 										{/* <FieldArray name="friends"></FieldArray> */}
						// 										<div className="row mb-3 px-2 m-0">
						// 											<div className="col-12 col-md-6">
						// 												<FormComponents
						// 													options={signatureOptions}
						// 													control="radio"
						// 													name="isSignatureSelected"
						// 													label="Want to sign with signature?"
						// 												/>
						// 											</div>
						// 											<div className="col-12 col-md-6 d-flex align-items-start justify-content-end">

						// 											</div>
						// 										</div>
						// 										<Form onSubmit={handleSubmit}>
						// 											<div className="row p-0 mx-0 my-2">
						// 												<div className="col-12 col-md-4 px-3">
						// 													<div className="d-flex align-items-start justify-content-center w-100">
						// 														<FormComponents
						// 															name="bankId"
						// 															key={submitted}
						// 															label="Select a Bank Account*"
						// 															control="autocomplete"
						// 															options={bankOptions}
						// 															multiple={false}
						// 															btnText="Add Bank Account"
						// 															isAddBtn={true}
						// 															onChange={(value) => getAutoGeneratedCheckNumber(value.value)}
						// 														/>
						// 														<button
						// 															className="add-btn icon-type-btn"
						// 															onClick={openAddBank}
						// 															type="button"
						// 														>
						// 															<AddOutlined
						// 																sx={{
						// 																	color: 'rgba(0,0,0,0.87)',
						// 																	width: '18px',
						// 																	height: '18px'
						// 																}}
						// 															/>
						// 														</button>
						// 													</div>
						// 												</div>
						// 												<div className="col-12 col-md-4 px-3">
						// 													<div className="d-flex align-items-start justify-content-center w-100">
						// 														<FormComponents
						// 															name="payeeId"
						// 															label="Select Payee*"
						// 															control="autocomplete"
						// 															key={submitted}
						// 															options={payeeOptions}
						// 															multiple={false}
						// 															btnText="Add Payee"
						// 															isAddBtn={true}
						// 														/>
						// 														<button
						// 															className="add-btn icon-type-btn"
						// 															onClick={openAddPayee}
						// 															type="button"
						// 														>
						// 															<AddOutlined
						// 																sx={{
						// 																	color: 'rgba(0,0,0,0.87)',
						// 																	width: '18px',
						// 																	height: '18px'
						// 																}}
						// 															/>
						// 														</button>
						// 													</div>
						// 												</div>
						// 												<div className="col-12 col-md-4 px-3">
						// 													<div className="d-flex align-items-start">
						// 														<div className="field-prefix">$</div>
						// 														<FormComponents
						// 															name="amount"
						// 															type="number"
						// 															label="Amount"
						// 															control="input"
						// 															prefix={true}
						// 														/>
						// 													</div>
						// 												</div>
						// 											</div>
						// 											<div className="row p-0 mx-0 my-2">
						// 												<div className="col-12 col-md-4 px-3">
						// 													<FormComponents
						// 														name="checkNumber"
						// 														type="text"
						// 														label="Check Number*"
						// 														control="input"
						// 														disabled

						// 													/>
						// 													{console.log(values.checkNumber)}
						// 												</div>
						// 												<div className="col-12 col-md-4 px-3">
						// 													<FormComponents
						// 														name="issuedDate"
						// 														label="Issued Date"
						// 														control="date"
						// 													/>
						// 												</div>
						// 												<div className="col-12 col-md-4 px-3">
						// 													<FormComponents
						// 														name="invoiceId"
						// 														type="text"
						// 														label="Invoice ID"
						// 														control="input"
						// 													/>
						// 												</div>
						// 											</div>
						// 											<div className="row p-0 mx-0 my-2">
						// 												<div className="col-12 col-md-4 px-3">
						// 													<FormComponents
						// 														name="memo"
						// 														type="text"
						// 														label="Memo"
						// 														control="input"
						// 													/>
						// 												</div>
						// 												<div className="col-12 col-md-4 px-3">
						// 													<div className="d-flex align-items-start justify-content-center w-100">
						// 														<NestedSelect
						// 															label="Select Tags"
						// 															name="tags"
						// 															id="tags"
						// 															selected={Array.isArray(selectedTag[index]) ? selectedTag[index] : [selectedTag[index]]}
						// 															handleChange={(id) => handleChange(id, index)}
						// 															options={nestedListOptions}
						// 															multiple={true}
						// 															color={true}
						// 															isAddBtn={true}
						// 														></NestedSelect>
						// 														<button
						// 															className="add-btn icon-type-btn"
						// 															onClick={openTagEditor}
						// 															type="button"
						// 														>
						// 															<AddOutlined
						// 																sx={{
						// 																	color: 'rgba(0,0,0,0.87)',
						// 																	width: '18px',
						// 																	height: '18px'
						// 																}}
						// 															/>
						// 														</button>
						// 													</div>
						// 												</div>
						// 												<div className="col-12 col-md-4 px-3">
						// 													<div className="d-flex align-items-start justify-content-center w-100">
						// 														<FormComponents
						// 															name="status"
						// 															key={submitted}
						// 															label="Status*"
						// 															control="autocomplete"
						// 															options={[
						// 																{ key: "Draft", value: "DRAFT" },
						// 																{ key: "Void", value: "VOID" },
						// 																{ key: "Blank", value: "BLANK" }
						// 															]}
						// 															multiple={false}
						// 															btnText="Status"
						// 															isAddBtn={true}
						// 															value={values.status}
						// 														/>
						// 													</div>
						// 												</div>
						// 											</div>
						// 											<div className="row p-0 mx-0 my-0">
						// 												<div className="col-12">

						// 													<div className={`d-flex ${index === forms.length - 1 ? "justify-content-between" : "justify-content-end"}`}>
						// 														{index === forms.length - 1 && (
						// 															<div className="d-flex align-items-center">
						// 																<ButtonComponent
						// 																	text={'Save & Print'}
						// 																	variant="light"
						// 																	type="button"
						// 																	disabled={!isUpdate && !isValid || !dirty || submitted}
						// 																	click={() => {
						// 																		onSubmit(values, 'save&Print', resetForm)
						// 																	}}
						// 																	extraClass="me-3"
						// 																//icon={<PrintOutlined />}
						// 																/>
						// 																<ButtonComponent
						// 																	text={submitted ? 'Saving...' : 'Save'}
						// 																	variant="dark"
						// 																	type="submit"
						// 																	click={() => {
						// 																		onSubmit(values, 'save', resetForm)
						// 																	}}
						// 																	disabled={!isUpdate && !isValid || !dirty || submitted}
						// 																/>
						// 															</div>
						// 														)}
						// 														<div>
						// 															{showPreview &&
						// 																Object.keys(printContent).length !== 0 ? (
						// 																<div className="d-flex align-items-right">
						// 																	<ButtonComponent
						// 																		text={'Reload Preview'}
						// 																		variant="light"
						// 																		type="button"
						// 																		//disabled={!isValid || !dirty}
						// 																		click={() => {
						// 																			showPreviewCheck(values)
						// 																		}}
						// 																		extraClass="me-3"
						// 																	/>
						// 																	<ButtonComponent
						// 																		text={'Hide Preview'}
						// 																		variant="light"
						// 																		type="button"
						// 																		disabled={!isUpdate && !isValid || !dirty}
						// 																		click={() => {
						// 																			setShowPreview(false)
						// 																		}}
						// 																		extraClass="me-3"
						// 																	/>

						// 																</div>
						// 															) : (
						// 																<div className='d-flex justify-content-between align-items-center'>
						// 																	<ButtonComponent
						// 																		text={'Preview'}
						// 																		variant="light"
						// 																		type="button"
						// 																		disabled={submitted}
						// 																		// !isValid ||
						// 																		click={() => {
						// 																			showPreviewCheck(values)
						// 																		}}
						// 																		extraClass="me-3"
						// 																	/>
						// 																	{index > 0 && (
						// 																		<ButtonComponent
						// 																			text={'Remove Form'}
						// 																			variant="light"
						// 																			type="button"
						// 																			onClick={() => removeForm(index)}
						// 																		/>
						// 																	)}
						// 																	{index === forms.length - 1 && (

						// 																		<ButtonComponent
						// 																			text={'Add Another Form'}
						// 																			variant="light"
						// 																			type="button"
						// 																			onClick={addForm}
						// 																		/>

						// 																	)}

						// 																</div>
						// 															)}
						// 														</div>
						// 													</div>
						// 												</div>
						// 											</div>
						// 										</Form>
						// 										{/* Similar to your existing form fields */}

						// 									</Form>
						// 								)}
						// 							</Formik>
						// 						))
						// 					)}
						// 					 <ButtonComponent
						// 						text={'Preview'}
						// 						variant="light"
						// 						type="button"
						// 						disabled={submitted}
						// 						// !isValid ||
						// 						click={() => {
						// 							showPreviewCheck(values)
						// 						}}
						// 						extraClass="me-3"
						// 					/>
						// 				</div>

						// 			</>
						// 		)
						// 	}}
						// </Formik>
						<Formik
							initialValues={{ forms: formInitialValues1 }}
							onSubmit={(values) => {
								console.log(values)
							}}
						>
							{({
								values,
								handleSubmit,
								setFieldValue,
								resetForm,
								isValid,
								dirty
							}) => (
								<form onSubmit={handleSubmit}>
									<FieldArray name="forms">
										{({ push, remove }) => (
											<>
												<div>
													{values?.forms?.map((form, index) => (
														<>
															<div key={index}>
																<div className="row mb-3 px-2 m-0">
																	<div className="col-12 col-md-6">
																		<FormComponents
																			options={signatureOptions}
																			control="radio"
																			name={`forms.${index}.isSignatureSelected`}
																			label="Want to sign with signature?"
																		/>
																	</div>
																	<div className="col-12 col-md-6 d-flex align-items-start justify-content-end"></div>
																</div>
																<div className="row p-0 mx-0 my-2">
																	<div className="col-12 col-md-4 px-3">
																		<div className="d-flex align-items-start justify-content-center w-100">
																			<FormComponents
																				name={`forms.${index}.bankId`}
																				key={submitted}
																				label="Select a Bank Account*"
																				control="autocomplete"
																				options={bankOptions}
																				multiple={false}
																				btnText="Add Bank Account"
																				isAddBtn={true}
																				onChange={(value) =>
																					getAutoGeneratedCheckNumber1(
																						value.value
																					)
																						.then((response) => {
																							setFieldValue(
																								`forms.${index}.checkNumber`,
																								response
																							)
																							// const bankId = value.value;
																							// const checkNumbersByBankId = {};
																							// const existingCheckNumber = checkNumbersByBankId[bankId];
																							// if (existingCheckNumber) {
																							// 	const nextCheckNumber = parseInt('00024');
																							// 	setFieldValue(`forms.${index}.checkNumber`, nextCheckNumber.toString());
																							// 	checkNumbersByBankId[bankId] = nextCheckNumber;
																							// } else {
																							// 	setFieldValue(`forms.${index}.checkNumber`, response);
																							// 	checkNumbersByBankId[bankId] = parseInt(response, 10);
																							// }
																							// console.log(checkNumbersByBankId)}
																						})
																						.catch((error) => {
																							console.log(error)
																						})
																				}
																			/>
																			<button
																				className="add-btn icon-type-btn"
																				onClick={openAddBank}
																				type="button"
																			>
																				<AddOutlined
																					sx={{
																						color: 'rgba(0,0,0,0.87)',
																						width: '18px',
																						height: '18px'
																					}}
																				/>
																			</button>
																		</div>
																	</div>
																	<div className="col-12 col-md-4 px-3">
																		<div className="d-flex align-items-start justify-content-center w-100">
																			<FormComponents
																				name={`forms.${index}.payeeId`}
																				label="Select Payee*"
																				control="autocomplete"
																				key={submitted}
																				options={payeeOptions}
																				multiple={false}
																				btnText="Add Payee"
																				isAddBtn={true}
																			/>
																			<button
																				className="add-btn icon-type-btn"
																				onClick={openAddPayee}
																				type="button"
																			>
																				<AddOutlined
																					sx={{
																						color: 'rgba(0,0,0,0.87)',
																						width: '18px',
																						height: '18px'
																					}}
																				/>
																			</button>
																		</div>
																	</div>
																	<div className="col-12 col-md-4 px-3">
																		<div className="d-flex align-items-start">
																			<div className="field-prefix">$</div>
																			<FormComponents
																				name={`forms.${index}.amount`}
																				type="number"
																				label="Amount"
																				control="input"
																				prefix={true}
																			/>
																		</div>
																	</div>
																</div>
																<div className="row p-0 mx-0 my-2">
																	<div className="col-12 col-md-4 px-3">
																		<FormComponents
																			name={`forms.${index}.checkNumber`}
																			type="text"
																			label="Check Number*"
																			control="input"
																			disabled
																		/>
																		{/* {console.log(values.checkNumber)} */}
																	</div>
																	<div className="col-12 col-md-4 px-3">
																		<FormComponents
																			name={`forms.${index}.issuedDate`}
																			label="Issued Date"
																			control="date"
																		/>
																	</div>
																	<div className="col-12 col-md-4 px-3">
																		<FormComponents
																			name={`forms.${index}.invoiceId`}
																			type="text"
																			label="Invoice ID"
																			control="input"
																		/>
																	</div>
																</div>
																<div className="row p-0 mx-0 my-2">
																	<div className="col-12 col-md-4 px-3">
																		<FormComponents
																			name={`forms.${index}.memo`}
																			type="text"
																			label="Memo"
																			control="input"
																		/>
																	</div>
																	<div className="col-12 col-md-4 px-3">
																		<div className="d-flex align-items-start justify-content-center w-100">
																			<NestedSelect
																				label="Select Tags"
																				name={`forms.${index}.tags`}
																				id="tags"
																				selected={
																					Array.isArray(selectedTag[index])
																						? selectedTag[index]
																						: [selectedTag[index]]
																				}
																				handleChange={(id) =>
																					handleChange(id, index)
																				}
																				options={nestedListOptions}
																				multiple={true}
																				color={true}
																				isAddBtn={true}
																			></NestedSelect>
																			<button
																				className="add-btn icon-type-btn"
																				onClick={openTagEditor}
																				type="button"
																			>
																				<AddOutlined
																					sx={{
																						color: 'rgba(0,0,0,0.87)',
																						width: '18px',
																						height: '18px'
																					}}
																				/>
																			</button>
																		</div>
																	</div>
																	<div className="col-12 col-md-4 px-3">
																		<div className="d-flex align-items-start justify-content-center w-100">
																			<FormComponents
																				name={`forms.${index}.status`}
																				key={submitted}
																				label="Status*"
																				control="autocomplete"
																				options={[
																					{ key: 'Draft', value: 'DRAFT' },
																					{ key: 'Void', value: 'VOID' },
																					...(values.status === 'DRAFT'
																						? []
																						: [
																								{ key: 'Blank', value: 'BLANK' }
																						  ])
																				]}
																				multiple={false}
																				btnText="Status"
																				isAddBtn={true}
																				value={values.status}
																				onChange={(selectedValue) => {
																					if (selectedValue.key === 'Blank') {
																						setBlankstatus(true)
																						if (
																							blankFormIndexes.includes(index)
																						) {
																							setBlankFormIndexes(
																								blankFormIndexes.filter(
																									(idx) => idx !== index
																								)
																							)
																						} else {
																							setBlankFormIndexes([
																								...blankFormIndexes,
																								index
																							])
																						}
																					} else {
																						setBlankstatus(false)

																						// If status is not "Blank", hide the quantity field
																						setBlankFormIndexes(
																							blankFormIndexes.filter(
																								(idx) => idx !== index
																							)
																						)
																					}
																				}}
																			/>
																		</div>
																	</div>
																</div>

																{blankFormIndexes.includes(index) && (
																	<div className="row p-0 mx-0 my-2">
																		<div className="col-12 col-md-4 px-3">
																			<FormComponents
																				name={`forms.${index}.quantity`}
																				type="text"
																				label="Quantity"
																				control="input"
																			/>
																		</div>
																	</div>
																)}
																<div className="row p-0 mx-0 my-0">
																	<div className="col-12">
																		<div
																			className={`d-flex ${
																				index === forms.length - 1
																					? 'justify-content-between'
																					: 'justify-content-end'
																			}`}
																		>
																			{index === forms.length - 1 && (
																				<div className="d-flex align-items-center">
																					{/* <ButtonComponent
																				text={'Save & Print'}
																				variant="light"
																				type="button"
																				disabled={
																					(!isUpdate && !isValid) ||
																					!dirty ||
																					submitted
																				}
																				click={() => {
																					onSubmit(
																						values,
																						'save&Print',
																						resetForm
																					)
																				}}
																				extraClass="me-3"
																			/>
																			<ButtonComponent
																				text={submitted ? 'Saving...' : 'Save'}
																				variant="dark"
																				type="submit"
																				click={() => {
																					onSubmit(values, 'save', resetForm)
																				}}
																				// <button type="submit">Submit</button>
																				disabled={
																					(!isUpdate && !isValid) ||
																					!dirty ||
																					submitted
																				}
																			/> */}
																				</div>
																			)}

																			<div>
																				{showPreview &&
																				Object.keys(printContent).length !==
																					0 ? (
																					<div className="d-flex align-items-right">
																						{/* <ButtonComponent
																					text={'Reload Preview'}
																					variant="light"
																					type="button"
																					//disabled={!isValid || !dirty}
																					click={() => {
																						showPreviewCheck(values)
																					}}
																					extraClass="me-3"
																				/>
																				<ButtonComponent
																					text={'Hide Preview'}
																					variant="light"
																					type="button"
																					disabled={
																						(!isUpdate && !isValid) || !dirty
																					}
																					click={() => {
																						setShowPreview(false)
																					}}
																					extraClass="me-3"
																				/> */}
																					</div>
																				) : (
																					<div className="d-flex justify-content-between align-items-center">
																						{/* <ButtonComponent
																					text={'Preview'}
																					variant="light"
																					type="button"
																					disabled={submitted}
																					// !isValid ||
																					click={() => {
																						showPreviewCheck(values)
																					}}
																					extraClass="me-3"
																				/> */}
																						{index > 0 && (
																							<ButtonComponent
																								style={{ marginBottom: '7px' }}
																								text={'Remove Form'}
																								variant="light"
																								type="button"
																								// onClick={() => removeForm(index)}
																								onClick={() => remove(index)}
																							/>
																						)}

																						{/* <ButtonComponent
																						text={'Remove Form'}
																						variant="light"
																						type="button"
																						// onClick={() => removeForm(index)}
																						onClick={() => remove(index)}
																					/> */}

																						{/* {index == forms.length - 1 && (
																					<ButtonComponent
																						text={'Add Another Form'}
																						variant="light"
																						type="button"
																						// onClick={addForm}
																						onClick={() =>
																							push({
																								invoiceId: '',
																								memo: '',
																							    checkNumber: '',
																							    amount: '',
																							    bankId: '',
																							    payeeId: '',
																							    tags: [],
																								status: 'DRAFT',
																								isSignatureSelected: userData?.data?.preferences?.wantSignature || false,
																								issuedDate: new Date()	
																							})

																							
																						}
																					/>
																				)} */}
																					</div>
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</>
													))}
													<div className="row p-0 mx-0 my-0 mb-4">
														<div className="col-12">
															<div
																className={`d-flex ${
																	// index === forms.length - 1
																	// ?
																	'justify-content-between'
																	// :
																	// 'justify-content-end'
																}`}
															>
																{/* {index === forms.length - 1 && ( */}
																<div className="d-flex align-items-center">
																	<ButtonComponent
																		text={'Save & Print'}
																		variant="light"
																		type="button"
																		disabled={
																			(!isUpdate && !isValid) ||
																			!dirty ||
																			submitted
																		}
																		click={() => {
																			onSubmit(values, 'save&Print', resetForm)
																		}}
																		extraClass="me-3"
																	/>
																	<ButtonComponent
																		text={submitted ? 'Saving...' : 'Save'}
																		variant="dark"
																		type="submit"
																		click={() => {
																			onSubmit(values, 'save', resetForm)
																			// console.log(values)
																		}}
																		// <button type="submit">Submit</button>
																		disabled={
																			(!isUpdate && !isValid) ||
																			!dirty ||
																			submitted
																		}
																	/>
																</div>
																{/* )} */}

																<div>
																	{showPreview &&
																	Object.keys(printContent).length !== 0 ? (
																		<div className="d-flex align-items-right">
																			<ButtonComponent
																				text={'Reload Preview'}
																				variant="light"
																				type="button"
																				//disabled={!isValid || !dirty}
																				click={() => {
																					showPreviewCheck(values)
																				}}
																				extraClass="me-3"
																			/>
																			<ButtonComponent
																				text={'Hide Preview'}
																				variant="light"
																				type="button"
																				// disabled={
																				// 	(!isUpdate && !isValid) || !dirty
																				// }
																				click={() => {
																					setShowPreview(false)
																				}}
																				extraClass="me-3"
																			/>
																		</div>
																	) : (
																		<div className="d-flex justify-content-between align-items-center">
																			<ButtonComponent
																				text={'Preview'}
																				variant="light"
																				type="button"
																				disabled={submitted}
																				// !isValid ||
																				click={() => {
																					showPreviewCheck(values)
																				}}
																				extraClass="me-3"
																			/>
																			{/* {index > 0 && ( */}
																			{/* <ButtonComponent
																						text={'Remove Form'}
																						variant="light"
																						type="button"
																						// onClick={() => removeForm(index)}
																						// onClick={() => remove(index)}
																					/> */}
																			{/* )} */}
																			{/* {index == forms.length - 1 && ( */}

																			{blankFormIndexes.includes() && (
																				<div className="row p-0 mx-0 my-2">
																					<div className="col-12 col-md-4 px-3">
																						<FormComponents
																							name={`forms.quantity`}
																							type="text"
																							label="quantity"
																							control="input"
																						/>
																					</div>
																				</div>
																			)}
																			<ButtonComponent
																				text={'Add Check'}
																				variant="light"
																				type="button"
																				// onClick={addForm}
																				disabled={blankStatus}
																				onClick={() =>
																					push({
																						invoiceId: '',
																						memo: '',
																						checkNumber: '',
																						amount: '',
																						bankId: '',
																						payeeId: '',
																						tags: [],
																						status: 'DRAFT',
																						isSignatureSelected:
																							userData?.data?.preferences
																								?.wantSignature || false,
																						issuedDate: new Date()
																					})
																				}
																			/>
																			{/* )} */}
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										)}
									</FieldArray>
								</form>
							)}
						</Formik>
					)
				) : (
					<CreateCheckSkeleton />
				)}
			</div>
			{printContent && Object.keys(printContent).length !== 0
				? allBodies.map((body, index) => (
						// <Printcheck key={index} printContent={body} />
						<Printcheck
							printContent={body}
							showPreview={showPreview}
							printRef={printRef}
							handlePrint={handlePrint}
						/>
				  ))
				: // <Printcheck
				  // 	printContent={printContent}
				  // 	showPreview={showPreview}
				  // 	printRef={printRef}
				  // 	handlePrint={handlePrint}
				  // />
				  null}

			{/* {console.log(isFirstTimeSignUp)} */}

			<FormModalMUI
				maxWidth="md"
				open={isFirstTimeSignUp}
				onClose={() => {
					localStorage.setItem('isFirstTimeSignUp', false)
					setIsFirstTimeSignUp(false)
				}}
			>
				<div className="container">
					<div className="row">
						<div
							className="col-4"
							style={{ paddingTop: '50px', paddingLeft: '20px' }}
						>
							<img className="team" src={team} alt="team" />
						</div>
						<div className="col-8" style={{ padding: '50px' }}>
							<div className="mb-4">
								<h1>
									<b>Welcome to Synccos</b>
								</h1>
							</div>
							<div style={{ marginBottom: '40px' }}>
								<p>
									Thank you for being a part of our amazing new venture. We
									apologize for any performance issues as we build and cater to
									our early adopters' needs. Your feedback is crucial as we
									shape the future. Please sign in or sign up to join the
									discussion on how we can best suit your needs. Let's create
									something extraordinary together! #ItsYourSynccos
								</p>
							</div>

							<ButtonComponent
								text="Close"
								variant="dark"
								onClick={() => {
									localStorage.setItem('isFirstTimeSignUp', false)
									setIsFirstTimeSignUp(false)
								}}
							/>
						</div>
					</div>
				</div>
			</FormModalMUI>

			<FormModalMUI
				title="Create Tag"
				open={isTagModalOpen}
				maxWidth="sm"
				onClose={closeTagEditor}
			>
				<AddTag onClose={closeTagEditor} title="Create Tag" edit={false} />
			</FormModalMUI>
			<FormModalMUI
				title="Add new bank account"
				open={isAddBankModalOpen}
				maxWidth="md"
				onClose={() => closeAddBank(false)}
			>
				<AddBank
					onClose={closeAddBank}
					setDirty={setDirty}
					warning={showWarning}
					setWarning={setWarning}
				/>
			</FormModalMUI>
			<FormModalMUI
				title="Add new payee"
				open={isAddPayeeModalOpen}
				maxWidth="md"
				onClose={() => closeAddPayee(false)}
			>
				<AddPayee
					onClose={closeAddPayee}
					isEdit={false}
					setDirty={setDirty}
					warning={showWarning}
					setWarning={setWarning}
				/>
			</FormModalMUI>

			<FormModalMUI
				title="Create Signature"
				open={isSignatureModalOpen}
				onClose={() => {
					setSignatureModalOpen(!isSignatureModalOpen)
				}}
				maxWidth="sm"
			>
				<AddSignature
					onClose={() => {
						setSignatureModalOpen(!isSignatureModalOpen)
					}}
				/>
			</FormModalMUI>

			<AddAddress
				isModalOpen={isAddressModalOpen}
				setWarning={setWarning}
				closeModal={closeAddressModal}
				setDirty={setDirty}
				showWarning={showWarning}
			/>
		</>
	)
}

export default CreateCheck
