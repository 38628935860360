import React from 'react'
import noDataImg from '../../assets/images/no-data-img.png'
import ButtonComponent from '../shared/ButtonComponent'
import { AddOutlined } from '@mui/icons-material'

const NoDataFound = ({ head, text, click, needBtn, btnText }) => {
	return (
		<div className="no-data-container">
			<div className="no-data-inner-container">
				<div className="no-data-img">
					<img src={noDataImg} alt="" />
				</div>
				<h2
					className="no-data-head fs-4 fw-semibold"
					style={{ color: '#212121' }}
				>
					{head}
				</h2>
				<p className="no-data-txt fs-14 mt-1 mb-4" style={{ color: '#757575' }}>
					{text}
				</p>
				{needBtn ? (
					<ButtonComponent
						text={btnText}
						variant="dark"
						type="button"
						click={click}
						icon={<AddOutlined />}
					/>
				) : null}
			</div>
		</div>
	)
}

export default NoDataFound
