import {getRequest, postRequest, putRequest} from '.';

// List all transactions
const listAllTransactions = async (bankAccountId) => {
    return getRequest('/checkregister_transactions?bankAccountId=' + bankAccountId);
};

// Deposit
const addDeposit = async (body) => {
    return postRequest('/checkregister_transactions/', body);
};

// Update Transaction status
const updateTransactionStatus = async (id, body) => {
    return putRequest(`/checkregister_transactions/${id}/`, body);
};

export {
    listAllTransactions,
    addDeposit,
    updateTransactionStatus
};
