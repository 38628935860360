import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import GenericTable from '../components/shared/GenericTable/GenericTable'
import PageHeader from '../components/shared/PageHeader'
import AddPayee from '../components/views/forms/AddPayee'
import {
	AddOutlined,
	DeleteOutlineOutlined,
	EditOutlined,
	MoreVert
} from '@mui/icons-material'
import ButtonComponent from '../components/shared/ButtonComponent'
import { Menu, MenuItem, IconButton } from '@mui/material'
import { deletepayee } from '../API/PayeeAPI'

import { useDispatch } from 'react-redux'
import { getPayees } from '../utils/helper'
import { updateSnackbar } from '../redux/snackbarState'
import FormModalMUI from '../components/shared/Modals/FormModalMUI'

const ContextMenu = ({ rowData }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [isEditPayeeModalOpen, setEditPayeeModalOpen] = useState(false)
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)
	const [isDirty, setIsDirty] = useState(false)
	const [showWarning, setWarning] = useState(false)

	const setDirty = (dirty) => {
		setIsDirty(dirty)
	}

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const openEditPayee = () => {
		setEditPayeeModalOpen(true)
		handleClose()
	}

	const closeEditPayee = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setEditPayeeModalOpen(false)
			setWarning(false)
		}
	}

	const openDeleteModal = () => {
		setDeleteModalOpen(true)
	}

	const closeDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const dispatch = useDispatch()

	const deleteUserPayee = async () => {
		if (!isSubmitting) {
			setSubmitting(true)
			try {
				const res = await deletepayee(rowData.id)
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Record Deleted.',
						severity: 'success'
					})
				)
				getPayees(dispatch)
			} catch (error) {
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Unable to delete.',
						severity: 'error'
					})
				)
			}
			setSubmitting(false)
			closeDeleteModal()
		}
	}

	return (
		<>
			<IconButton
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				sx={{
					display: 'flex',
					justifyContent: 'start',
					alignItems: 'center'
				}}
			>
				<MenuItem onClick={openEditPayee}>
					<EditOutlined
						sx={{
							marginRight: '20px'
						}}
					/>
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose()
						openDeleteModal()
					}}
				>
					<DeleteOutlineOutlined
						sx={{
							marginRight: '20px'
						}}
					/>
					Delete
				</MenuItem>
			</Menu>

			<FormModalMUI
				title="Edit payee"
				open={isEditPayeeModalOpen}
				maxWidth="md"
				onClose={() => closeEditPayee(false)}
			>
				<AddPayee
					onClose={closeEditPayee}
					isEdit={true}
					payeeData={rowData}
					setDirty={setDirty}
					warning={showWarning}
					setWarning={setWarning}
				/>
			</FormModalMUI>

			{isDeleteModalOpen && (
				<FormModalMUI
					onClose={closeDeleteModal}
					open={isDeleteModalOpen}
					maxWidth="sm"
				>
					<div className="container">
						<div className="row">
							<div className="row txt-danger pt-3">
								<DeleteOutlineOutlined sx={{ fontSize: '80px' }} />
							</div>
							<div className="col d-flex justify-content-center">
								<div className="row">
									<h3>
										<p>
											<b>Confirm Deletion?</b>
										</p>
									</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col d-flex justify-content-center">
								<div className="row text-center">
									<p>
										Are you sure you want to delete payee with name:{' '}
										<b>{rowData.name}</b> ?
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex align-items-center justify-content-center mt-3 mb-5">
						<ButtonComponent
							text="Cancel"
							type="button"
							variant="light"
							click={closeDeleteModal}
							extraClass="me-3"
						/>
						<ButtonComponent
							text={isSubmitting ? 'Deleting...' : 'Delete'}
							disabled={isSubmitting}
							type="submit"
							variant="danger"
							click={deleteUserPayee}
						/>
					</div>
				</FormModalMUI>
			)}
		</>
	)
}

const Payee = () => {
	let payees = useSelector((state) => state.appData.payees)
	const [isLoading, setIsLoading] = useState(true)
	const [isAddPayeeModalOpen, setAddPayeeModalOpen] = useState(false)

	const [modifiedData, setModifiedData] = useState([])
	const [isDirty, setIsDirty] = useState(false)
	const [showWarning, setWarning] = useState(false)

	const setDirty = (dirty) => {
		setIsDirty(dirty)
	}

	const columnData = [
		{
			key: 'Name',
			value: 'name',
			colWidth: '10%'
		},
		{
			key: 'Address',
			value: 'payeeAddress',
			colWidth: '15%',
			align: 'center'
		},
		{
			key: 'Date',
			value: 'createdDate',
			colWidth: '10%'
		},
		{ key: 'Actions', value: 'contextMenu', colWidth: '9%' }
	]

	useEffect(() => {
		if (payees.data.length !== 0) {
			makeTableData(payees.data)
		} else {
			setIsLoading(payees.isLoading)
			setModifiedData([])
		}
	}, [payees])

	const makeTableData = (data) => {
		const temp = []
		if (data && data.length !== 0) {
			data.forEach((item, index) => {
				let obj = {}

				obj = { ...item }

				obj.payeeAddress = `${item?.address?.addressLine1 || ''}, ${
					item?.address?.addressLine2 || ''
				}`

				let timestamp = item.id.toString().substring(0, 8)
				let date = new Date(parseInt(timestamp, 16) * 1000)

				obj.createdDate = `${date.getDate()}/${
					date.getMonth() + 1
				}/${date.getFullYear()}`

				obj.contextMenu = (
					<>
						<ContextMenu
							rowData={item}
							setDirty={setDirty}
							showWarning={showWarning}
							setWarning={setWarning}
							isDirty={isDirty}
						/>
					</>
				)
				temp.push(obj)
			})

			setModifiedData([...temp])
			setIsLoading(false)
		} else {
			setModifiedData([])
			setIsLoading(false)
		}
	}

	const openAddPayee = () => {
		setAddPayeeModalOpen(true)
	}

	const closeAddPayee = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setAddPayeeModalOpen(false)
			setWarning(false)
		}
	}

	return (
		<>
			<div className="container">
				<PageHeader
					text="Payee List"
					info="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
				/>
				<div className="generic-table-container">
					<div className="d-flex align-items-center justify-content-end">
						<ButtonComponent
							text="New"
							variant="dark"
							icon={<AddOutlined />}
							type="button"
							onClick={openAddPayee}
							extraClass="ms-3"
						/>
					</div>
					<GenericTable
						columnData={columnData}
						modifiedData={modifiedData}
						count={payees?.data.length || 0}
						isLoading={isLoading}
						noDataProps={{
							text: 'All the payees created will be stored here. Create your first payee!',
							head: 'No payees',
							click: () => {
								openAddPayee()
							},
							btnText: 'New Payee'
						}}
					/>
				</div>
			</div>

			<FormModalMUI
				title="Add new payee"
				open={isAddPayeeModalOpen}
				maxWidth="md"
				onClose={() => closeAddPayee(false)}
			>
				<AddPayee
					onClose={closeAddPayee}
					setDirty={setDirty}
					warning={showWarning}
					setWarning={setWarning}
				/>
			</FormModalMUI>
		</>
	)
}

export default Payee
