import React, { useState } from 'react'

import {
	InsertDriveFileOutlined,
	DoneRounded,
	ScheduleOutlined,
	BlockOutlined,
	AlternateEmailOutlined,
	PrintOutlined
} from '@mui/icons-material'
import { Button, MenuItem, Menu } from '@mui/material'
import FormModalMUI from '../Modals/FormModalMUI'

const CheckStatusPopover = ({
	checkStatus,
	checkId,
	func,
	openChangeConfirmModal,
	freezeStatus
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
		event.stopPropagation()
	}
	const handleClose = (event) => {
		setAnchorEl(null)
		event.stopPropagation()
	}

	switch (checkStatus) {
		case 'DRAFT':
			return (
				<>
					<Button
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						className={` ${!freezeStatus ?  'dropdown-toggle' : ''} check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						style={{
							backgroundColor: '#F1F4FF',
							color: '#1255D8',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE'
						}}
					>
						<span className="status-icon">
							<InsertDriveFileOutlined
								sx={{
									color: '#1255D8',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Draft
					</Button>
					{!freezeStatus &&
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
							PaperProps={{
								style: {
									minWidth: '121px',
									transition: 'all 0.3s linear',
									marginTop: '2px',
									borderRadius: '4px',
									border: '1px solid #EEE',
									boxShadow: 'none'
								}
							}}
						>
							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('CLEARED', checkId)
									// func(e, 'CLEARED', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Cleared
							</MenuItem>

							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('VOID', checkId)
									// func(e, 'VOID', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Void
							</MenuItem>
						</Menu>
					}
				</>
			)

		case 'CLEARED':
			return (
				<>
					<Button
						className={`check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						style={{
							backgroundColor: '#F3FFF1',
							color: '#257E49',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE'
						}}
						disabled={true}
					>
						<span className="status-icon">
							<DoneRounded
								sx={{
									color: '#257E49',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Cleared
					</Button>
				</>
			)

		case 'VOID':
			return (
				<>
					<div
						className={` check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						style={{
							backgroundColor: '#FFF1F1',
							color: '#DE0202',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE',
							lineHeight: '1.75'
						}}
						disabled={true}
					>
						<span className="status-icon">
							<BlockOutlined
								sx={{
									color: '#DE0202',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Void
					</div>
				</>
			)

		case 'EMAILED':
			return (
				<>
					<Button
						className={`${!freezeStatus ?  'dropdown-toggle' : ''} check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						style={{
							backgroundColor: '#F3FFF1',
							color: '#257E49',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE'
						}}
					>
						<span className="status-icon">
							<AlternateEmailOutlined
								sx={{
									color: '#257E49',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Emailed
					</Button>
					{!freezeStatus &&
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
							PaperProps={{
								style: {
									minWidth: '121px',
									transition: 'all 0.3s linear',
									marginTop: '2px',
									borderRadius: '4px',
									border: '1px solid #EEE',
									boxShadow: 'none'
								}
							}}
						>
							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('CLEARED', checkId)
									// func(e, 'CLEARED', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Cleared
							</MenuItem>
						</Menu>
					}
				</>
			)

		case 'PRINTED':
			return (
				<>
					<Button
						className={`${!freezeStatus ?  'dropdown-toggle' : ''} check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						style={{
							backgroundColor: '#F3FFF1',
							color: '#257E49',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE'
						}}
					>
						<span className="status-icon">
							<PrintOutlined
								sx={{
									color: '#257E49',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Printed
					</Button>
					{!freezeStatus &&
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
							PaperProps={{
								style: {
									minWidth: '121px',
									transition: 'all 0.3s linear',
									marginTop: '2px',
									borderRadius: '4px',
									border: '1px solid #EEE',
									boxShadow: 'none'
								}
							}}
						>
							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('CLEARED', checkId)
									// func(e, 'CLEARED', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Cleared
							</MenuItem>
						</Menu>
					}
				</>
			)

		case 'BLANK':
			return (
				<>
					<Button
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						className={`${!freezeStatus ?  'dropdown-toggle' : ''} check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						style={{
							backgroundColor: '#F1F4FF',
							color: '#1255D8',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE'
						}}
					>
						<span className="status-icon">
							<InsertDriveFileOutlined
								sx={{
									color: '#1255D8',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Blank
					</Button>
					{!freezeStatus &&
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
							PaperProps={{
								style: {
									minWidth: '121px',
									transition: 'all 0.3s linear',
									marginTop: '2px',
									borderRadius: '4px',
									border: '1px solid #EEE',
									boxShadow: 'none'
								}
							}}
						>
							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('VOID', checkId)
									// func(e, 'VOID', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Void
							</MenuItem>
						</Menu>
					}
				</>
			)

		default:
			return (
				<>
					<Button
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
						className={`${!freezeStatus ?  'dropdown-toggle' : ''} check-status-div ${checkStatus?.toLowerCase()} d-flex flex-row align-items-center py-2 px-3 rounded justify-content-center text-capitalize mx-auto`}
						style={{
							backgroundColor: '#F1F4FF',
							color: '#1255D8',
							fontSize: '10px',
							fontWeight: '500',
							borderRadius: '6px',
							border: '1px solid #EEE'
						}}
					>
						<span className="status-icon">
							<InsertDriveFileOutlined
								sx={{
									color: '#1255D8',
									marginRight: '5px',
									width: '0.75rem',
									height: '0.75rem'
								}}
							/>{' '}
						</span>
						Draft
					</Button>
					{!freezeStatus &&
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
							PaperProps={{
								style: {
									minWidth: '121px',
									transition: 'all 0.3s linear',
									marginTop: '2px',
									borderRadius: '4px',
									border: '1px solid #EEE',
									boxShadow: 'none'
								}
							}}
						>
							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('CLEARED', checkId)
									// func(e, 'CLEARED', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Cleared
							</MenuItem>

							<MenuItem
								className="status-choices justify-content-center fs-12"
								sx={{
									padding: ' 5px 10px',
									color: '#3f3f3f'
								}}
								onClick={(e) => {
									openChangeConfirmModal('VOID', checkId)
									// func(e, 'VOID', checkId)
									handleClose(e)
									e.stopPropagation()
								}}
							>
								Void
							</MenuItem>
						</Menu>
					}
				</>
			)
	}
}

export default CheckStatusPopover
