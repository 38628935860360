import React,{useEffect,useState} from 'react'
import { Formik, Form,ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FormControlLabel, Checkbox } from '@mui/material'
import Cookies from 'js-cookie'
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import FormHeader from '../components/shared/forms/FormHeader'
import FormComponents from '../components/shared/forms/index'
import FormButton from '../components/shared/forms/FormButton'
import SocialLogin from '../components/shared/SocialLogin'
import { Link, useHistory } from 'react-router-dom'
import { loginAPI } from '../API/AuthAPI'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../redux/snackbarState'
import { login, setIsRemember } from '../redux/loginLogout'
import {
	TextField,
	Typography,Button,FormControl
} from '@mui/material'

const Login = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [showPassword, setShowPassword] = useState(false); // State for password visibility

	const validationSchema = Yup.object({
		email: Yup.string().email('Incorrect Email').required('Required'),
		password: Yup.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			)
	})

	const initialValues = {
		email: '',
		password: '',
		isRememberd: false
	}

// Login Functionality button click function
	const onSubmit = async (values) => {
		let body = { ...values }
		body.email = body.email.toLowerCase();
		delete body.isRememberd
		const domain = window.location.hostname === 'localhost' ? 'localhost' : '.synccos.com';
		try {
			const res = await loginAPI(body)
			const { data } = res
			Cookies.set('access-token', data.accessToken, {
				expires: 1 / 24
			})
			// Cookies.set('direct-token', data.accessToken, {
			// 	expires: 1 / 24,
			// 	domain: '.synccos.com',
			// })

			// Cookies.set('direct-token-ref', "checkwriter", {
			// 	expires: 1 / 24,
			// 	domain: '.synccos.com',
			// })

			Cookies.set('direct-token', data.accessToken, {
				expires: 1 / 24,
				domain: domain,
			})

			Cookies.set('direct-token-ref', "checkwriter", {
				expires: 1 / 24,
				domain: domain,
			})

			Cookies.set('refresh-token', data.refreshToken, {
				expires: 12 / 24
			})
			Cookies.set('subscription', data.subscriptionTaken, {
				expires: 12 / 24
			})
			localStorage.setItem('user', JSON.stringify(data));
			Cookies.set('user-status', true, {
				expires: 12 / 24
			})
			if (data.subscriptionTaken) {
				if (values.isRememberd) {
					Cookies.set('user-memory', values.isRememberd, {
						expires: 2 / 24
					})
					dispatch(setIsRemember())
				} else {
					window.sessionStorage.setItem('user-memory', true)
				}
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Login Sucessful.',
						severity: 'success'
					})
				)
				history.push({
					pathname: '/dashboard/main',
					state: {
						showModal: true
					}
				});
				// history.go(0);
				window.location.reload()
			} else {
				history.push('/subscription');
			}
		} catch (err) {
			const { response } = err
			const { data } = response
			dispatch(
				updateSnackbar({
					open: true,
					message: data?.error?.userMessage || 'Unknown Error.',
					severity: 'error'
				})
			)
		}
	}




	const styles = {
		label: {
			color: '#6C757D',
			marginBottom: '6px',
			marginLeft: '20px',
			fontSize: '14px'
		},
		inputField: {
			'& .MuiOutlinedInput-root': {
				height: '39px',
				'& fieldset': {
					borderRadius: '10px',
					borderColor: '#CED4DA'
				},
				'&:hover fieldset': {
					borderColor: '#A9A9A9'
				},
				'&.Mui-focused fieldset': {
					borderColor: '#7C4DFF',
					boxShadow: '0 0 0 2px rgba(124, 77, 255, 0.3)'
				}
			},
			'& .MuiInputBase-input': {
				color: '#495057',
				padding: '14px 12px'
			}
		},
		checkboxStyles: {
			'&.Mui-checked': {
				color: '#7C4DFF' // Customize the color to match the design
			},
			'& .MuiSvgIcon-root path': {
				// stroke: '#000000', // Set the color of the checkmark to black when checked
				strokeWidth: 1 // Set the thickness of the checkmark line
			},
			'& .MuiSvgIcon-root': {
				fontSize: 30,
				marginTop: '4px' // Customize the size of the checkbox
			},
			color: '#CED4DA' // Default border color when unchecked
		},
		errorMessage: {
			color: 'red',
			marginLeft: '20px',
			fontSize: '12px'
		},
		checkbox: {
			'&.Mui-checked': {
				color: 'green'
			},
			'& .MuiSvgIcon-root': {
				fontSize: 18
			}
		},
		buttons:{
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '16px 36px',
			gap: '12px',
			width: '100%',
			height: '48px',
			background: 'linear-gradient(90deg, #693BAA 4%, #B04EC3 100%)',
			borderRadius: '10px !important',
			flex: 'none',
			order: 7,
			flexGrow: 0,
			color: 'white', // Optional: Set text color
			'&:disabled': {
				background: '#CED4DA', // Optional: Change background when disabled
			}
		}
	}

	return (
		<div className="auth-form-div">
			{/* <FormHeader
				head="Welcome Back!"
				text="Write checks with confidence, wherever you are."
			/> */}
			<div
				className="form-head mb-5"
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					fontWeight: 'initial'
				}}
			>
				<h2
					className="fs-1 mb-1 text-center form-header"
					style={{ fontWeight: 'initial' }}
				>
					Login
				</h2>
			</div>
			{/* <Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<FormComponents
							name="email"
							type="email"
							label="Email Address"
							control="input"
							placeholder="sample@company.com"
							required
						/>
						<FormComponents
							name="password"
							label="Password"
							control="input"
							type="password"
							required
						/>
						<FormButton text="Submit" type="submit" click={handleSubmit} />
					</Form>
				)}
			</Formik> */}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
						handleSubmit,
						handleChange,
						setFieldValue,
						values,
						errors,
						touched
					}) => (
					<Form onSubmit={handleSubmit}>



						<Typography
							variant="body1"
							sx={styles.label}
							style={{ marginTop: '15px' }}
						>
							Email <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="email"
							type="text"
							fullWidth
							value={values.email}
							onChange={handleChange}
							error={touched.email && Boolean(errors.email)}
							sx={styles.inputField}
						/>

						<ErrorMessage name="email">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>

						<Typography
							variant="body1"
							sx={styles.label}
							style={{ marginTop: '15px' }}
						>
							Password <span style={{ color: '#7C4DFF' }}>*</span>
						</Typography>
						<TextField
							variant="outlined"
							name="password"
							type={showPassword ? 'text' : 'password'} // Toggle type based on state
							fullWidth
							value={values.password}
							onChange={handleChange}
							error={touched.password && Boolean(errors.password)}
							sx={styles.inputField}
							InputProps={{
								endAdornment: (
									<IconButton onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? <VisibilityOff /> :  <VisibilityIcon />} {/* Change icon based on state */}
									</IconButton>
								)
							}}
						/>

						<ErrorMessage name="password">
							{(msg) => <Typography sx={styles.errorMessage}>{msg}</Typography>}
						</ErrorMessage>


						{/* Submit Button */}
						{/* <FormButton
							text="Submit"
							type="submit"
							click={handleSubmit}
							disabled={!values?.isAggreed}
						/> */}
						<Button
							className='border-0 fs-6 fw-semibold text-capitalize mt-4'
							type="submit"
							onClick={handleSubmit}
							// disabled={!values?.isAggreed}
							sx={styles.buttons}
						>
							Submit
						</Button>
					</Form>
				)}
			</Formik>

			<div className="d-flex align-items-center justify-content-between fs-6">
				<FormControlLabel
					control={
						<Checkbox
							className=" ms-2"
							sx={styles.checkboxStyles}
						/>
					}
					label="Remember Me"
					className="my-;abel"
					sx={{
						'& .MuiFormControlLabel-label': { fontSize: '15px' }
					}}
				/>
				<Link
					to="/auth/forget-password"
					className="text-black text-decoration-none"
					style={{
						fontSize: '15px'
					}}
				>
					Forget Password?
				</Link>
			</div>

			<div className="divider my-4 d-flex align-items-center justify-content-center">
				{/* <span className="line"></span> */}
				<span className="txt" style={{fontSize:"14px"}}>Or Login with</span>
				{/* <span className="line"></span> */}
			</div>
			<SocialLogin />

			<p className="redirect-txt text-center mt-3 fs-6">
				Don’t have an account ? <Link to="/auth/sign-up">Sign Up</Link>
			</p>
		</div>
	)
}

export default Login
