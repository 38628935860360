import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import appLogo from '../../../assets/images/app-logo.png';
import appLogoDark from '../../../assets/images/app-logo-dark.png';

const Header = () => {
	const history = useHistory();
	const isLoggedIn = useSelector((state) => state.loginLogout.isLoggedIn);
	const location = useLocation();

	const options = [
		{ title: 'Overview', path: '/' },
		{ title: 'Features', path: '/' },
		{ title: 'Integrations', path: '/' },
		{ title: 'Pricing', path: '/' },
	];

	useEffect(() => {
		// Use a timeout to ensure that the state is settled before redirecting
		const redirectTimeout = setTimeout(() => {
			if (isLoggedIn) {
				history.push('/dashboard/main');
			} else if (location.pathname === '/') {
				window.location.href = 'https://synccos.com/check-writer/';
			}
		}, 100);

		return () => clearTimeout(redirectTimeout); // Cleanup on unmount
	}, [isLoggedIn, history, location.pathname]);

	return (
		<div className="app-header bg-transparent" style={{ display: 'none' }}>
			<div className="app-header-inner d-flex flex-row justify-content-between align-items-center bg-transparent">
				<div className="app-logo">
					<Link to="/">
						<img
							src={location.pathname === '/' ? appLogo : appLogoDark}
							alt="App Logo"
						/>
					</Link>
				</div>
				{isLoggedIn ? (
					<button
						onClick={() => {
							history.push('/dashboard/main');
							localStorage.setItem('isFirstTimeSignUp', false);
						}}
						className={`common-btn fs-14 ${
							location.pathname === '/' ? 'border-0 ' : 'light'
						}`}
					>
						Dashboard
					</button>
				) : (
					<div className="d-flex align-items-center justify-content-center">
						<button
							onClick={() => {
								history.push('/auth/login');
							}}
							className="common-btn fs-14 border-0 me-5 bg-transparent p-0"
							style={
								location.pathname === '/'
									? { color: '#e7feab', minWidth: 'auto' }
									: { color: '#000', minWidth: 'auto' }
							}
						>
							Sign in
						</button>
						<button
							onClick={() => {
								history.push('/auth/sign-up');
							}}
							className={`common-btn fs-14 ${
								location.pathname === '/' ? 'border-0 ' : 'light'
							}`}
						>
							Get started
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
