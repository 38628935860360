import React from 'react'
import numberToWords from 'number-to-words'
import { checkLockIcon } from '../../assets/svg'

import '../../styles/Print.css'
import { getChecks } from '../../utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { updateSnackbar } from '../../redux/snackbarState'
import { updateCheck } from '../../API/ChecksAPI'
import { columnGroupsStateInitializer } from '@mui/x-data-grid/internals'

const Printcheck = ({ printContent, showPreview, printRef }) => {
	const dispatch = useDispatch()
	const userData = useSelector((state) => state.appData.userData)
    // console.log(printContent,"PrintingCheck")
	const {
		address = '',
		payee = '',
		bankDetails = '',
		amount = 0,
		memo = '',
		issuedDate = new Date(),
		checkNumber = '',
		isSignatureSelected,
		status
	} = printContent

	const date = new Date(issuedDate)

	// const updateCheckStatus = async (status) => {
	// 	try {
	// 		const resp = await updateCheck({ status }, printContent._id)

	// 		dispatch(
	// 			updateSnackbar({
	// 				open: true,
	// 				message: `Check saved as ${status}.`,
	// 				severity: 'success'
	// 			})
	// 		)

	// 		getChecks(dispatch)
	// 	} catch (error) {
	// 		dispatch(
	// 			updateSnackbar({
	// 				open: true,
	// 				message: 'Something went wrong.',
	// 				severity: 'error'
	// 			})
	// 		)
	// 	}
	// }

	const wholeNumber = Math.floor(amount)
	const decimalPart = Math.round((amount - wholeNumber) * 100)
	const words = numberToWords.toWords(wholeNumber)
	const decimalWords = numberToWords.toWords(decimalPart)
	const amountInWords = `${words} and ${decimalWords} cent*****`

	return (
		<>
			<div
				className="print-container"
				style={showPreview ? { display: 'block' } : { display: 'none' }}
			>
				<div
					className="print-body d-flex align-items-between flex-column"
					ref={printRef}
				>
					<div
						className="check-body position-relative"
						style={{ fontFamily: 'Inter' }}
					>
						<div className="d-flex justify-content-between align-items-start first-section">
							<div className="user-info">
								<p
									className="fw-semibold user-name"
									// style={{
									// 	color: checkColor
									// }}
								>

									{/* {address?.name1 ? address?.name1  : userData?.data?.firstName  || userData?.data?.lastName  }
									{address?.name1 ? address?.name1 : userData?.data?.firstName ||  userData?.data?.lastName  } */}
									{address?.name1 ? address?.name1  : userData?.data?.firstName || ''}{' '}
									{address?.name1 ? null: userData?.data?.lastName || ''}
								</p>
								<p
									className="fw-normal address-info"
									// style={{
									// 	color: checkColor
									// }}
								>
									{address?.addressLine1}
									{address?.addressLine2 && address?.addressLine2 !== ''
										? `, ${address?.addressLine2}`
										: ''}
								</p>
								<p
									className="fw-normal address-info"
									// style={{
									// 	color: checkColor
									// }}
								>
									{address?.city || ''}, {address?.state || ''} ,
									{address?.zipCode || ''}
								</p>
							</div>
							<div className="bank-logo">
								<h1
									className="bank-name-header"
									// style={{
									// 	color: checkColor
									// }}
								>
									{bankDetails?.bankName}
								</h1>
							</div>
							<div
								className="background-div"
								// style={{
								// 	backgroundColor: checkColor
								// }}
							>
								<div className="background">
									No. <span className="fs-6 fw-bold">{checkNumber}</span>
								</div>
							</div>
						</div>
						<div
							className="d-flex align-items-center justify-content-end check-date"
							// style={{
							// 	color: checkColor
							// }}
						>
							Date:{' '}
							<span
								className=""
								style={
									{
										//color: checkColor,
										//borderColor: checkColor
									}
								}
							>
								{date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
							</span>
						</div>
						<div className="d-flex align-items-center justify-content-between payee-amount-div mt-4">
							<p
								className="text-uppercase"
								// style={{
								// 	color: checkColor
								// }}
							>
								Pay to the order of:
							</p>
							<div className="pay-person w-100">
								<div className="payee-name d-flex align-items-center">
									<p
										className="payee w-100"
										// style={{
										// 	borderColor: checkColor,
										// 	color: checkColor
										// }}
									>
										{payee?.name}
									</p>
									<span
										className="dollar-sign"
									>
										$
									</span>
								</div>
							</div>
							<div
								className="diagnol-box"
								// style={{
								// 	color: checkColor
								// }}
								style={
									status === 'BLANK'
										? { padding: '18px 70px' }
										: { padding: '6px 35px' }
								}
							>
								{status === 'BLANK'
									? ''
									: `**${
											amount && amount !== ''
												? amount.toLocaleString('en-US', {
														minimumFractionDigits: 2
												  })
												: '0'
									  }`}
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-between text-amount-div">
							<p
								className="text-amount text-capitalize"
								// style={{
								// 	borderColor: checkColor,
								// 	color: checkColor
								// }}
							>
								{status === 'BLANK' ? '' : amountInWords.replaceAll('-', ' ')}
							</p>
							<p
								className="dollar-txt"
								// style={{
								// 	color: checkColor
								// }}
							>
								Dollors
							</p>
							<div className="d-flex align-items-center justify-content-center security-txt-div">
								<div className="lock-icon">{checkLockIcon}</div>
								<p
									className="text-uppercase security-txt"
									// style={{
									// 	color: checkColor
									// }}
								>
									security feature included
								</p>
							</div>
						</div>
						<div
							className="d-flex align-items-center justify-content-start memo-div"
							// style={{
							// 	color: checkColor
							// }}
						>
							Memo : {memo}
						</div>
						<div className="d-flex align-items-center justify-content-end">
							<div className="signature-div">
								<div
									className="signature-box"
									// style={{
									// 	borderColor: checkColor
									// }}
								>
									{isSignatureSelected ? (
										<img
											src={userData?.data?.signatureUrl}
											alt="signature"
											style={{
												width: '75%',
												height: 'inherit'
											}}
										/>
									) : null}
								</div>
								<p
									className="text-uppercase"
									// style={{
									// 	color: checkColor
									// }}
								>
									Authorized Signature
								</p>
							</div>
						</div>
						<div
							className="check-number text-center"
							// style={{
							// 	color: checkColor
							// }}
						>
							C0000{checkNumber}C A{bankDetails?.bankRoutingNumber}A C
							{bankDetails?.accountNumber}C
						</div>
					</div>
					<div className="middle-section-check container mx-5 my-5 ps-5">
						<div className="user-address ms-5 mb-4 pt-5">
							<div className="user-info mb-5">
								<p
									className="fw-normal user-name ext-black m-0 fs-14"
									// style={{
									// 	color: checkColor
									// }}
								>
									{userData?.data?.firstName || ''}{' '}
									{userData?.data?.lastName || ''}
								</p>
								<p
									className="fw-normal address-info ext-black m-0 fs-14"
									// style={{
									// 	color: checkColor
									// }}
								>
									{address?.addressLine1}
									{address?.addressLine2 && address?.addressLine2 !== ''
										? `, ${address?.addressLine2}`
										: ''}
								</p>
								<p
									className="fw-normal address-info ext-black m-0 fs-14"
									// style={{
									// 	color: checkColor
									// }}
								>
									{address?.city || ''}, {address?.state || ''} ,
									{address?.zipCode || ''}
								</p>
							</div>
						</div>
						<div className="mb-5 ms-5 mt-5 pb-4">
							<p className="text-black m-0 fs-14">{payee?.name}</p>
						</div>
					</div>
					<div className="lower-section-check mt-3">
						<div className="row mt-5">
							<div className="col-3">
								<p className="fs-14 m-0 fw-bold">
									{date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
								</p>
							</div>
							<div className="col-6">
								<p className="fs-14 m-0 fw-bold">{payee?.name || ''}</p>
							</div>
							<div className="col-3">
								<p className="fs-14 m-0 fw-bold">{checkNumber}</p>
							</div>
						</div>

						<div className="row">
							<div className="col-3"></div>
							<div className="col-5">
								<div className="row mt-4">
									<div className="col-5">
										<p className="fs-14">Pay To</p>
									</div>
									<div className="col-7">
										<p className="fs-14">{payee?.name}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-5">
										<p className="fs-14">Pay From:</p>
									</div>
									<div className="col-7">
										<p className="fs-14 m-0">
											{userData?.data?.firstName || ''}{' '}
											{userData?.data?.lastName || ''}
										</p>
										<p className="fs-14 m-0">
											{address?.addressLine1 || ''} ,{' '}
											{address?.addressLine2 || ''}
										</p>
										<p className="fs-14 m-0">
											{address?.city}, {address?.state}, {address?.zipCode}
										</p>
									</div>
								</div>
							</div>
							<div className="col-3"></div>
						</div>
						<div className="d-flex align-items-start justify-content-between mt-5 pt-3">
							<div>
								<p className="m-0 fs-14">Memo: {memo}</p>
								<p className="fw-bold m-0 fs-14">
									{userData?.data?.firstName || ''}{' '}
									{userData?.data?.lastName || ''}
								</p>
							</div>
							<div>
								<p className="fw-bold m-0 fs-14">
									{' '}
									{status === 'BLANK' ? '' : `**${amount}`}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Printcheck
