import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormComponents from '../../shared/forms'
import FormButton from '../../shared/forms/FormButton'
import FormModal from '../../shared/Modals/FormModal'
import ButtonComponent from '../../shared/ButtonComponent'
import { addpayee, updatepayee } from '../../../API/PayeeAPI'
import { updateSnackbar } from '../../../redux/snackbarState'
import { useDispatch, useSelector } from 'react-redux'
import { getPayees } from '../../../utils/helper'
import countryData from '../../../utils/countryStateCity.json'
import { ErrorOutlineOutlined } from '@mui/icons-material'
import { AddOutlined } from '@mui/icons-material'
import { addDeposit } from '../../../API/CheckRegisterAPI'

const AddDeposit = ({
	onClose,
	isEdit,
	payeeData
	// setWarning,
	// setDirty,
	// warning
}) => {
	const dispatch = useDispatch()

	const validationSchema = Yup.object({
		bankId: Yup.string().required('Required!'),
		amount: Yup.string().required('Required!'),
		date: Yup.string().required('Required!')

	})

	const banks = useSelector((state) => state.appData.banks)

	const onSubmit = async (values, { resetForm }) => {
		let body = {}

		body.bankAccountId = values.bankId
		body.amount = values.amount
		body.issue_date = values.date

   
		console.log(body,"body")

		try {
			const res = await addDeposit(body)
			const { data } = res
			console.log(data,"Data is here")
			close()
			dispatch(
			updateSnackbar({
				open: true,
				message: 'Deposit Success.',
				severity: 'success'
			})
			)
		}catch(err){
			const { response } = err
			const { data } = response
			dispatch(
				updateSnackbar({
					open: true,
					message:  'Unknown Error.',
					severity: 'error'
				})
			)
		}


	}

	const close = (forced) => {
		onClose(forced)
	}

	const bankOptions = banks?.data.map((bank, index) => {
		return { key: bank.bankName, value: bank._id }
	})


	return (
		<Formik
			initialValues={{
				bankId: '',
				amount:  '',
				date: new Date(),
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ handleSubmit, setFieldValue, values, dirty }) => (
				<Form onSubmit={handleSubmit} className="px-3 pt-3 pb-3">
					<div className="row">
						<div className="col-12 col-md-12">
							<div className="d-flex align-items-start justify-content-center w-100">
								<FormComponents
									name="bankId"
									// key={bankOptions.value}
									label="Select a Bank Account Number*"
									control="autocomplete"
									options={bankOptions}
									multiple={false}
									btnText="Add Bank Account"
									isAddBtn={true}
								/>
								<button
									className="add-btn icon-type-btn"
									// onClick={openAddBank}
									type="button"
								>
									<AddOutlined
										sx={{
											color: 'rgba(0,0,0,0.87)',
											width: '18px',
											height: '18px'
										}}
									/>
								</button>
							</div>
						</div>
						<div className="col-12 col-md-12">
							<div className="d-flex align-items-start">
								<div className="field-prefix">$</div>
								<FormComponents
									name="amount"
									type="number"
									label="Amount"
									control="input"
									prefix={true}
								/>
							</div>
						</div>
						<div className="col-12 col-md-12">
							<div className="d-flex align-items-start">
								{/* <div className="field-prefix">$</div> */}
								<FormComponents
									name="date"
									label="Deposit Date"
									control="date"
								/>
							</div>
						</div>
					</div>
					<div className="d-flex flex-row align-items-center justify-content-evenly">
						<button
							type="button"
							className="common-btn bg-white light"
							onClick={() => {
								close(false)
							}}
						>
							Cancel
						</button>
						<ButtonComponent
							text={isEdit ? 'Save' : 'Add Deposit'}
							type="submit"
							variant="dark"
							click={handleSubmit}
						/>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default AddDeposit
