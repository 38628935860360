import { useSelector } from "react-redux"
import StatsIndicator from "./StatsIndicator"
import { useState } from 'react'
import { abbreviateNumber } from "../../utils/helper"

const CheckStats = ({type}) => {
    const checks = useSelector((state) => state.appData.checks)
    let totalCheckAmount = 0
    let totalClearedCheckAmount = 0
    let totalUnclearedCheckAmount = 0
    let totalDraftCheckAmount = 0
    const totalCheckCount = checks.data.length;
    const clearedCheckCount = checks.data.filter(data => data.status === "CLEARED").length;
    const unclearedCheckCount = checks.data.filter(data => data.status === "UNCLEARED").length;
    const draftCheckCount = checks.data.filter(data => data.status === "DRAFT").length;
    for (let check of checks.data) {
        totalCheckAmount += check.amount
    }
    for (let check of checks.data.filter(data => data.status === "CLEARED")) {
        totalClearedCheckAmount += check.amount
    }
    for (let check of checks.data.filter(data => data.status === "UNCLEARED")) {
        totalUnclearedCheckAmount += check.amount
    }
    for (let check of checks.data.filter(data => data.status === "DRAFT")) {
        totalDraftCheckAmount += check.amount
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-4 d-flex justify-content-center">
                    {type === 'Total Checks' && (<StatsIndicator color="#3D3D3D" textColor="white" title="Total checks" data={totalCheckCount}/>)}
                    {type === 'Total Expenses' && (<StatsIndicator color="#3D3D3D" textColor="white" title="Total Amount" data={abbreviateNumber(totalCheckAmount)}/>)}
                </div>
                <div className="col-4 d-flex justify-content-center">
                {type === 'Total Checks' && (<StatsIndicator color="#EAFBE7" textColor="black" title="Cleared" data={clearedCheckCount}/>)}
                {type === 'Total Expenses' && (<StatsIndicator color="#EAFBE7" textColor="black" title="Cleared" data={abbreviateNumber(totalClearedCheckAmount)}/>)}
                </div>
                {/* <div className="col-3 d-flex justify-content-center">
                {type === 'Total Checks' && (<StatsIndicator color="#FFF8DE" textColor="black" title="Uncleared" data={unclearedCheckCount}/>)}
                {type === 'Total Expenses' && (<StatsIndicator color="#FFF8DE" textColor="black" title="Uncleared" data={abbreviateNumber(totalUnclearedCheckAmount)}/>)}
                </div> */}
                <div className="col-4 d-flex justify-content-center">
                {type === 'Total Checks' && (<StatsIndicator color="#F1F4FF" textColor="black" title="Drafts" data={draftCheckCount}/>)}
                {type === 'Total Expenses' && (<StatsIndicator color="#F1F4FF" textColor="black" title="Drafts" data={abbreviateNumber(totalDraftCheckAmount)}/>)}
                </div>
            </div>
        </div>
    )
}

export default CheckStats