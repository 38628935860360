import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import CreateCheck from '../pages/CreateCheck'
import Banks from '../pages/Banks'
import Payee from '../pages/Payee'
import MyChecks from '../pages/MyChecks'
import MyProfile from '../pages/MyProfile'
import MyTags from '../pages/MyTags'
import CreatePaymentLinks from '../pages/CreatePaymentLinks'
import PrivateRoute from './PrivateRoutes'
import Dashboard from '../pages/Dashboard'
import Buisness from '../pages/Buisness'
import ManageOrganization from '../pages/ManageOrganization'
import AllOrders from '../pages/AllOrder'
import PaymentLinks from '../pages/PaymentLinks'
import Integrations from '../pages/Integrations'
import CheckRegister from '../pages/CheckRegister'
import InvoiceChecks from '../pages/invoiceChecks'
// import CreateTransactions from '../pages/CreateTransactions'

const DashboardRoutes = () => {
	return (
		<Switch>
			<PrivateRoute
				exact
				path={['/dashboard', '/dashboard/main']}
				component={Dashboard}
			/>
			<PrivateRoute
				exact
				path="/dashboard/create-check"
				component={CreateCheck}
			/>
			<PrivateRoute
				exact
				path="/dashboard/my-checks/:status?"
				component={MyChecks}
			/>
			<PrivateRoute
				exact
				// path="/dashboard/my-checks/:status?"
				path="/dashboard/check-register"

				component={CheckRegister}
			/>
			{/* <PrivateRoute
				exact
				// path="/dashboard/my-checks/:status?"
				path="/dashboard/check-register"

				component={CheckRegister}
			/> */}
			{/* <PrivateRoute
				exact
				path="/dashboard/create-transactions"
				component={CreateTransactions}
			/> */}
			
			<PrivateRoute
				exact
				path="/dashboard/payment-links/:status?"
				component={PaymentLinks}
			/>
			<PrivateRoute exact path="/dashboard/bank-accounts" component={Banks} />
			<PrivateRoute exact path="/dashboard/payees" component={Payee} />
			<PrivateRoute exact path="/dashboard/profile" component={MyProfile} />
			<PrivateRoute exact path="/dashboard/my-tags" component={MyTags} />
			<PrivateRoute
				exact
				path={[
					'/dashboard/create-organization',
					'/dashboard/update-organization'
				]}
				component={Buisness}
			/>
			<PrivateRoute
				exact
				path="/dashboard/manage-organizations"
				component={ManageOrganization}
			/>
		
			<PrivateRoute
				exact
				path="/dashboard/create-payment-links"
				component={CreatePaymentLinks}
			/>
			<PrivateRoute
				exact
				path="/dashboard/Invoicechecks"
				component={InvoiceChecks}
			/>
			<PrivateRoute exact path="/dashboard/all-orders" component={AllOrders} />
			<PrivateRoute path="/dashboard/integrations" component={Integrations} />
			<Redirect to="/not-found" />
		</Switch>
	)
}

export default DashboardRoutes
