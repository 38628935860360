import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const addbank = async (body) => {
	const res = await postRequest('/users/user/bank-details', body)
	return res
}

const updateBank = async (body, id) => {
	const res = await putRequest(`/users/user/bank-details/${id}`, body)
	return res
}

const getAllBanks = async () => {
	const res = await getRequest('/users/user/bank-details')
	return res
}

const deleteBank = async (id) => {
	const res = await deleteRequest(`users/user/bank-details/${id}`)

	return res
}

const validateBankAccountNumber = async (accNumber) => {
	const res = await getRequest(
		`/users/user/bank-details/validate-bank-account-number/${accNumber}`
	)
	return res
}

const validateBankNickName = async (nickName) => {
	const res = await getRequest(
		`/users/user/bank-details/validate-bank-account-nickname/${nickName}`
	)
	return res
}

const getBankDetails = async (routingNumber) => {
	const res = getRequest(`/autofill/getBankAddress/${routingNumber}`)
	return res
}


export {
	addbank,
	updateBank,
	getAllBanks,
	deleteBank,
	validateBankAccountNumber,
	validateBankNickName,
	getBankDetails
}
