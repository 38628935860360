import { configureStore } from '@reduxjs/toolkit'
import loginLogoutReducer from './loginLogout'
import snackbarStateReducer from './snackbarState'
import appDataReducer from './appData'
import updateDataReducer from './updateData'

const rootReducer = {
	loginLogout: loginLogoutReducer,
	snackbarState: snackbarStateReducer,
	appData: appDataReducer,
	updateData: updateDataReducer
}

export default configureStore({
	reducer: rootReducer,
	devTools: true
})
