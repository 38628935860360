import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../../../redux/snackbarState'
import { Formik, Field, ErrorMessage } from 'formik'
import FormComponents from '../../shared/forms'
import { ChromePicker, TwitterPicker } from 'react-color'
import ButtonComponent from '../../shared/ButtonComponent'
import { addTag } from '../../../API/TagsAPI'
import { fetchGroups, fetchTags } from '../../../utils/helper'
import { Divider, Icon, IconButton } from '@mui/material'
import {
	AddCircleOutlined,
	AddOutlined,
	CloseOutlined,
	Done
} from '@mui/icons-material'
import colorSwatch from '../../../assets/images/color-swatch.png'
import TagNameColor from '../../shared/forms/TagNameColor'
import { addGroup } from '../../../API/GroupAPI'

const AddGroup = ({ onClose, groups }) => {
	const [submitted, setSubmitted] = useState(false)
	const [tagList, setTagList] = useState([])
	const dispatch = useDispatch()

	const colors = [
		'#FFD480',
		'#FFB873',
		'#FF8C64',
		'#FF5733',
		'#C7F2A7',
		'#008000',
		'#41E2BA',
		'#80C1FF',
		'#6699FF',
		'#5489FF',
		'#9370DB',
		'#BA55D3',
		'#FF69B4'
	]

	const [groupInfo, setGroupInfo] = useState([{
		name: '',
		color: colors[Math.floor(Math.random() * colors.length)]
	}])

	const addTagToList = () => {
		let color = colors[Math.floor(Math.random() * colors.length)]
		setTagList([...tagList, { name: '', color: color }])
	}

	useEffect(() => {
		addTagToList()
	}, [])

	const removeTagFromList = (index) => {
		const updatedList = [...tagList]
		updatedList.pop()
		setTagList(updatedList)
	}

	const changeTagColor = (color, index) => {
		const updatedList = [...tagList]
		for (let i = 0; i < updatedList.length; i++) {
			if (i == index) {
				updatedList[i]['color'] = color
			}
		}
		setTagList(updatedList)
	}

	const changeTagName = (name, index) => {
		const updatedList = [...tagList]
		for (let i = 0; i < updatedList.length; i++) {
			if (i == index) {
				updatedList[i]['name'] = name
			}
		}
		setTagList(updatedList)
	}

	const changeGroupName = (name) => {
		let info = [...groupInfo]
		info[0]['name'] = name
		setGroupInfo(info)
	}

	const changeGroupColor = (color) => {
		let info = [...groupInfo]
		info[0]['color'] = color
		setGroupInfo(info)
	}

	const [disabled, setDisabled] = useState(true)
	const [valid, setValid] = useState(false)

	useEffect(() => {
		// console.log(groupInfo[0]?.name === '',  tagList?.length === 0, tagList[tagList.length - 1]?.name === '', !valid)
		if(groupInfo[0]?.name === '' ||  tagList?.length === 0 || tagList[tagList.length - 1]?.name === '' || !valid) {
			setDisabled(true)
		} else {
			setDisabled(false)
		}
	}, [groupInfo, tagList])

	const onSave = async () => {
		if (!submitted) {
			setSubmitted(true)

			let group_name = groupInfo[0]['name']
			let group_color = groupInfo[0]['color']

			if(group_name === ''){
				setSubmitted(false)
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'error',
						message: 'Cannot add group without name.'
					})
				)
				onClose()
				return;
			}

			let body = {
				name: group_name,
				color: group_color
			}

			try {
				let res = await addGroup(body)
				let group_id = res?.data?._id

				if (res.status == 200) {
					tagList.forEach(async (tag) => {
						if (tag?.name !== '') {
							let body = {
								name: tag?.name,
								color: tag?.color,
								group: group_id
							}
							try {
								let res = await addTag(body)
							} catch (err) {
								dispatch(
									updateSnackbar({
										open: true,
										severity: 'error',
										message: err.response.data.error.userMessage
											? err.response.data.error.userMessage
											: 'Unable to add tag.'
									})
								)
							}
							if (res.status == 200) {
								fetchGroups(dispatch)
							}
						}
					})

					dispatch(
						updateSnackbar({
							open: true,
							severity: 'success',
							message: `${'Group added successfully.'}`
						})
					)
				} else
					onClose()
			} catch (err) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'error',
						message: err.response.data.error.userMessage
							? err.response.data.error.userMessage
							: 'Unable to add group.'
					})
				)
			}

			setSubmitted(false)
		}
	}

	return (
		<div className="container p-3">
			<TagNameColor
				tagName={groupInfo[0]['name']}
				tagColor={groupInfo[0]['color']}
				label="Group Name"
				hasDelete={false}
				changeColor={(color) => {
					changeGroupColor(color)
				}}
				changeName={(name) => {
					changeGroupName(name)
				}}
				setValid={setValid}
			/>
			<Divider component="div" />
			<div className="row justify-content-between mt-3 mb-3">
				<div className="col-auto">
					<p className="fw-bold text-secondary mt-2">Tags</p>
				</div>
				<div className="col-auto">
					<IconButton
						disabled={tagList[tagList.length - 1]?.name === '' || !valid ? true : false}
						color="success"
					>
						<AddCircleOutlined onClick={addTagToList} />
					</IconButton>
				</div>
			</div>

			<ul style={{ padding: 0 }}>
				{tagList.map((tag, index) => (
					<li key={index} style={{ listStyle: 'none' }}>
						<TagNameColor
							tagName={tag['name']}
							tagColor={tag['color']}
							label="Tag Name"
							hasDelete={index == tagList.length - 1}
							removeTag={() => {
								removeTagFromList(index)
							}}
							changeColor={(color) => {
								changeTagColor(color, index)
							}}
							changeName={(name) => {
								changeTagName(name, index)
							}}
							setValid={setValid}
						/>
					</li>
				))}
			</ul>

			<div className="row">
				<div className="d-flex align-items-center justify-content-center mt-3 mb-0">
					<ButtonComponent
						text="Cancel"
						type="button"
						variant="light"
						extraClass="me-5"
						onClick={onClose}
					/>
					<ButtonComponent
						text="Save Group"
						type="submit"
						variant="dark"
						onClick={onSave}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	)
}

export default AddGroup
