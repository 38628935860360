import axios from 'axios'
import { postRequest } from '.'

const getGoogleInfo = async (token) => {
	axios
		.get(
			`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json'
				}
			}
		)
		.then((res) => {
			return res
		})
		.catch((err) => {
			return err
		})
}

const signUpGoogle = async (body) => {
	const res = await postRequest('/auth/google/signup', body)
	return res
}

const signInGoogle = async (body) => {
	const res = await postRequest('/auth/google/login', body)
	return res
}

const appleSignUp = async (body) => {
	const res = await postRequest('auth/apple/signup', body)
	return res
}

const appleSignIn = async (body) => {
	const res = await postRequest('/auth/apple/login', body)
	return res
}

export { getGoogleInfo, signUpGoogle, appleSignUp, appleSignIn, signInGoogle }
