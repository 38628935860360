import { postRequest } from '.'

const getEmailLinks = async (body) => {
	const res = await postRequest('/checks/check/linkGenUpload', body)
	return res
}

const sendEmail = async (body) => {
	const res = await postRequest('/checks/check/sendEmailWithCheck', body)
	return res
}

export { getEmailLinks, sendEmail }
