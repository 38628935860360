import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const addpayee = async (body) => {
	const res = await postRequest('/users/user/payees/payee', body)
	return res
}

const updatepayee = async (body, id) => {
	const res = await putRequest(`/users/user/payees/payee/${id}`, body)
	return res
}

const getAllpayees = async () => {
	const res = await getRequest('/users/user/payees')
	return res
}

const getPayeesById = async (id) => {
	const res = await getRequest(`/users/user/payees/payee/${id}`)
	return res
}

const deletepayee = async (id) => {
	const res = await deleteRequest(`users/user/payees/payee/${id}`)

	return res
}

export { addpayee, updatepayee, getAllpayees, deletepayee, getPayeesById }
