import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Popover
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Cookies from 'js-cookie'
import { logOut } from '../../../redux/loginLogout'
import { emptyAppData } from '../../../redux/appData'
import FormModalMUI from '../../shared/Modals/FormModalMUI'
import { ErrorOutline, Logout, Settings,Person } from '@mui/icons-material'
import ButtonComponent from '../../shared/ButtonComponent'

const ProfilePopover = ({ userFirstName, userLastName, userEmail }) => {
	let history = useHistory()
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const [logoutModal, setLogoutModal] = useState(false)

	const logout = () => {
		Cookies.remove('organization')
		dispatch(logOut())
		dispatch(emptyAppData())
		Cookies.remove('refresh-token')
		Cookies.remove('direct-token')
		Cookies.remove('direct-token-ref')
		Cookies.remove('access-token')
		Cookies.remove('user-status')
		Cookies.remove('user-status')
		window.sessionStorage.removeItem('user-memory')
		// history.push('/')
		window.location.href = 'https://synccos.com/check-writer/'
	}

	return (
		<>
			<IconButton
				sx={{
					width: '36px',
					height: '36px',
					borderRadius: '4px',
					border: '1px solid #204464',
					backgroundColor: '#204464',
					color: '#fff',
					fontSize: '16px',
					'&:hover': { color: '#fff', backgroundColor: '#204464' }
				}}
				onClick={handleClick}
			>
				{userFirstName && userLastName
					? (userFirstName.charAt(0) + userLastName.charAt(0)).toUpperCase()
					: ''}
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				sx={{
					top: '15px',
					left: '-25px'
				}}
			>
				<List style={{ minWidth: '150px' }}>
					<ListItem
						sx={{
							fontSize: '12px',
							fontWeight: '500',
							justifyContent: 'center',
							padding: '15px 15px'
						}}
					>
						<div className="d-flex align-items-center justify-content-center">
							<div className="profile-initials">
								{userFirstName && userLastName
									? (
											userFirstName.charAt(0) + userLastName.charAt(0)
									  ).toUpperCase()
									: 'NA'}
							</div>
							<div>
								<p className="mb-0 fs-6 fw-semibold">
									{userFirstName || ''} {userLastName || ''}
								</p>
								<p
									className="mb-0 fs-12"
									style={{
										color: '#969696'
									}}
								>
									{userEmail || ''}
								</p>
							</div>
						</div>
					</ListItem>
					<Divider
						sx={{
							borderColor: '#969696'
						}}
					/>
					<ListItem
						className="list-item-new"
						onClick={() => {
							history.push('/dashboard/profile')
							handleClose()
						}}
						key="goToProfile"
						sx={{
							marginTop: '10px',
							padding: '6px 15px',
							justifyContent: 'start',
							alignItems: 'center',
							display: 'flex',
							fontSize: '14px'
						}}
					>
						<Settings
							sx={{
								fontSize: '14px',
								marginRight: '5px'
							}}
						/>
						Profile Settings
					</ListItem>
					<ListItem
						className="list-item-new"
						onClick={() => {
							setLogoutModal(true)
							handleClose()
						}}
						key="logout"
						sx={{
							padding: '16px 15px',
							justifyContent: 'start',
							alignItems: 'center',
							display: 'flex',
							fontSize: '14px'
						}}
					>
						<Logout
							sx={{
								fontSize: '14px',
								marginRight: '5px',
								transform: 'rotate(180deg)'
							}}
						/>
						Logout
					</ListItem>
					
				</List>
			</Popover>

			<FormModalMUI
				open={logoutModal}
				onClose={() => {
					setLogoutModal(false)
				}}
				maxWidth="sm"
			>
				<div class="container" style={{ width: '450px' }}>
					<div class="row">
						<div class="d-flex align-items-center justify-content-center txt-danger mt-3 mb-2">
							<ErrorOutline sx={{ fontSize: '80px' }} />
						</div>
						<div class="col d-flex justify-content-center">
							<div class="row">
								<h3>
									<p>
										<b>Logout</b>
									</p>
								</h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col d-flex justify-content-center">
							<div class="row text-center">
								<p>Are you sure you want to logout?</p>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
					<ButtonComponent
						text="Cancel"
						type="button"
						variant="light"
						click={() => {
							setLogoutModal(false)
						}}
						extraClass="me-3"
					/>
					<ButtonComponent
						text={'Logout'}
						type="submit"
						variant="danger"
						click={logout}
					/>
				</div>
			</FormModalMUI>
		</>
	)
}

export default ProfilePopover
