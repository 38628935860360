import { getRequest, putRequest } from '.'

const updatepreferences = async (body, id) => {
	const res = await putRequest(`/users/user/preferences`, body)
	return res
}

const getNextAvailableCheckNumber = async () => {
	const res = await getRequest(`/users/user/next-available-check-number`)
	return res
}

export { updatepreferences, getNextAvailableCheckNumber }
