import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormComponents from '../../shared/forms'
import FormButton from '../../shared/forms/FormButton'
import FormModal from '../../shared/Modals/FormModal'
import ButtonComponent from '../../shared/ButtonComponent'
import { addpayee, updatepayee } from '../../../API/PayeeAPI'
import { updateSnackbar } from '../../../redux/snackbarState'
import { useDispatch } from 'react-redux'
import { getPayees } from '../../../utils/helper'
import countryData from '../../../utils/countryStateCity.json'
import { ErrorOutlineOutlined } from '@mui/icons-material'

const AddPayee = ({
	onClose,
	isEdit,
	payeeData,
	setWarning,
	setDirty,
	warning
}) => {
	const dispatch = useDispatch()

	const validationSchema = Yup.object({
		name: Yup.string().required('Required!'),
		email: Yup.string().required('Required!'),
		phone: Yup.string().required('Required!'),
		addressLine1: Yup.string().required('Required!'),
		country: Yup.string().required('Required!'),
		city: Yup.string().required('Required!'),
		state: Yup.string().required('Required!'),
		zipCode: Yup.number().required('Required!')
	})

	const onSubmit = async (values, { resetForm }) => {
		let body = {}

		body.name = values.name
		body.address = {
			name: values.name,
			addressLine1: values.addressLine1,
			addressLine2: values.addressLine2,
			country: values.country,
			city: values.city,
			zipCode: values.zipCode,
			state: values.state
		}
		body.phone = values.phone
		body.email = values.email
		body.companyName = values.companyName

		try {
			const res = isEdit
				? await updatepayee(body, payeeData.id)
				: await addpayee(body)

			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: 'Payee added successfully.'
				})
			)
			getPayees(dispatch)
			resetForm()
			onClose(true)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: err.response.data.error.userMessage
						? err.response.data.error.userMessage
						: 'Unable to add payee.'
				})
			)
		}
	}

	const close = (forced) => {
		onClose(forced)
	}

	return (
		<Formik
			initialValues={{
				name: isEdit ? payeeData.name : '',
				email: isEdit ? payeeData.email : '',
				phone: isEdit ? payeeData.phone : '',
				addressLine1: isEdit ? payeeData.address.addressLine1 : '',
				addressLine2: isEdit ? payeeData.address.addressLine2 : '',
				city: isEdit ? payeeData.address.city : '',
				country: isEdit ? payeeData.address.country : '',
				zipCode: isEdit ? payeeData.address.zipCode : '',
				companyName: isEdit ? payeeData.address.companyName : '',
				state: isEdit ? payeeData.address.state : ''
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ handleSubmit, setFieldValue, values, dirty }) => (
				<Form onSubmit={handleSubmit} className="px-3 pt-3 pb-3">
					{setDirty(dirty)}
					{warning && (
						<div class="alert alert-danger" role="alert">
							<div className="row row justify-content-between">
								<div className="col-auto p-2">
									<ErrorOutlineOutlined className="me-2" />
									Are you sure you want to close the modal? Your details wont be
									saved.
								</div>
								<div className="col-auto">
									<div className="row justify-content-end">
										<div className="col-auto">
											<button
												type="button"
												class="btn btn-light"
												onClick={() => setWarning(false)}
											>
												No
											</button>
										</div>
										<div className="col-auto">
											<button
												type="button"
												class="btn btn-danger"
												onClick={() => onClose(true)}
											>
												Yes
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="row">

						<div className="col-12 col-md-12">
							<FormComponents
								name="name"
								label="Payee Full Name"
								control="input"
								type="text"
								required
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<FormComponents
								name="email"
								label="Email Address"
								control="input"
								type="email"
								required
							/>
						</div>
						<div className="col-12 col-md-6">
							<FormComponents
								name="phone"
								specialLabel="Phone Number *"
								control="phone-input"
								type="text"
								country="us"
								required
								onChange={(phoneNumber) => setFieldValue('phone', phoneNumber)}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-12">
							<FormComponents
								name="companyName"
								label="Company Name"
								control="input"
								type="text"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<FormComponents
								name="addressLine1"
								label="Address Line 1"
								control="input"
								type="text"
								required
							/>
						</div>
						<div className="col-12 col-md-6">
							<FormComponents
								name="addressLine2"
								label="Address Line 2"
								control="input"
								type="text"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<FormComponents
								name="country"
								label="Country"
								control="autocomplete"
								options={[
									...new Set(
										countryData?.flatMap((obj) => {
											return {
												key: obj?.name,
												value: obj?.name
											}
										})
									)
								]}
								disabled={values?.state !== ''}
								required
							/>
						</div>
						<div className="col-12 col-md-6">
							<FormComponents
								name="state"
								label="State"
								control="autocomplete"
								options={
									values?.country !== ''
										? [
												...new Set(
													countryData
														?.filter(
															(country) => country?.name === values?.country
														)[0]
														?.states?.flatMap((obj) => {
															return {
																key: obj?.name,
																value: obj?.name
															}
														})
												)
										  ]
										: []
								}
								disabled={values?.city !== ''}
								required
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<FormComponents
								name="city"
								label="City"
								control="autocomplete"
								options={
									values?.country !== '' && values?.state !== ''
										? [
												...new Set(
													countryData
														?.filter(
															(country) => country?.name === values?.country
														)[0]
														?.states?.filter(
															(state) => state?.name === values?.state
														)[0]
														?.cities?.flatMap((obj) => {
															return {
																key: obj,
																value: obj
															}
														})
												)
										  ]
										: []
								}
								required
							/>
						</div>
						<div className="col-12 col-md-6">
							<FormComponents
								name="zipCode"
								label="Zip code"
								control="input"
								type="number"
								required
							/>
						</div>
					</div>
					<div className="d-flex flex-row align-items-center justify-content-evenly">
						<button
							type="button"
							className="common-btn bg-white light"
							onClick={() => {
								close(false)
							}}
						>
							Cancel
						</button>
						<ButtonComponent
							text={isEdit ? 'Save' : 'Add Payee'}
							type="submit"
							variant="dark"
							click={handleSubmit}
						/>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default AddPayee
