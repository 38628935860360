import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const addCheck = async (body) => {
	const res = await postRequest('/checks/check', body)
	return res
}

const bulkaddCheck = async (body) => {
	const res = await postRequest('/checks/bulk/check', body)
	return res
}


const updateCheck = async (body, id) => {
	const res = await putRequest(`/checks/check/${id}`, body)
	return res
}

const getAllChecks = async () => {
	const res = await getRequest('/checks')
	return res
}

const getUserCheckById = async (id) => {
	const res = await getRequest(`/checks/check/${id}`)
	return res
}

const deleteCheck = async (id) => {
	const res = await deleteRequest(`/checks/check/${id}`)
	return res
}

const validateCheck = async (checkNumber,bankId) => {
	const res = await getRequest(`/checks/validate-check-number/${checkNumber}/${bankId}`)
	return res
}
const getCheckNumber = async (id) => {
	const res = await getRequest(`/users/user/next-available-check-number/${id}`)
	return res
}


export {
	addCheck,
	updateCheck,
	getAllChecks,
	deleteCheck,
	getUserCheckById,
	validateCheck,
	getCheckNumber,
	bulkaddCheck
}
