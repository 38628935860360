import { Button } from '@mui/material'
import React from 'react'

const ButtonComponent = (props) => {
	const { variant, icon, text, click, ...rest } = props

	return (
		<Button
			className={`common-btn d-flex align-items-center justify-content-center text-capitalize ${variant} ${
				props?.extraClass || ''
			}`}
			onClick={click}
			{...rest}
			sx={{
				'&.MuiButton-root': {
					'&.common-btn': {
						'&.light': {
							'&.Mui-disabled': {
								color: '#BDBDBD',
								borderColor: '#BDBDBD'
							}
						}
					},
					'& svg': {
						width: '16px',
						height: '16px'
					}
				}
			}}
		>
			{icon ? <span className="me-2">{icon}</span> : null}
			{text}
		</Button>
	)
}

export default ButtonComponent
