import {
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select
} from '@mui/material'
import ExpensesChart from './ExpensesChart'
import { useState } from 'react'

const Graphs = ({ type }) => {
	const [period, setPeriod] = useState('Weekly')

	return (
		<div
			className="container"
			style={{
				borderRadius: '12px',
				borderStyle: 'solid',
				borderColor: '#bbbbbb',
				borderWidth: '0.5px',
				padding: '20px',
				height: '450px'
			}}
		>
			<div className="d-flex align-items-center justify-content-between">
				<p
					className="fs-4 fw-semibold"
					style={{
						marginLeft: '10px'
					}}
				>
					Stats
				</p>

				<div className="d-flex flex-row-reverse bd-highlight">
				
				<div class="p-2 bd-highlight"></div>
				<div class="p-2 bd-highlight">
					<FormControl sx={{ minWidth: 120 }} size="small">
						<Select
							id="period"
							margin="normal"
							variant="outlined"
							input={<OutlinedInput style={{ borderColor: 'green' }} />}
							inputProps={{ 'aria-label': 'Without label' }}
							defaultValue={'Weekly'}
							value={period}
							sx={{
								'& .MuiSelect-select': {
									fontSize: '14px',
									minHeight: 'auto',
									padding: '7px 14px'
								}
							}}
							onChange={(e) => {
								setPeriod(e.target.value)
							}}
							MenuProps={{
								sx: {
									'MuiMenuItem-root': {
										'& .Mui-selected': {
											background: '#EAFBE7'
										},
										'& .Mui-focused': {
											background: '#FFF8DE'
										}
									}
								}
							}}
							color="success"
						>
							<MenuItem
								value={'Weekly'}
								defaultChecked
								className="menu-item"
								sx={{
									':hover': {
										backgroundColor: 'rgba(0,128,0, 0.1)'
									},
									'&.Mui-selected': {
										backgroundColor: 'rgba(0,128,0, 0.1)',
										'&:hover': {
											backgroundColor: 'rgba(0,128,0, 0.1)'
										}
									}
								}}
							>
								Weekly
							</MenuItem>
							<MenuItem
								value={'Monthly'}
								className="menu-item"
								sx={{
									':hover': {
										backgroundColor: 'rgba(0,128,0, 0.1)'
									},
									'&.Mui-selected': {
										backgroundColor: 'rgba(0,128,0, 0.1)',
										'&:hover': {
											backgroundColor: 'rgba(0,128,0, 0.1)'
										}
									}
								}}
							>
								Monthly
							</MenuItem>
							<MenuItem
								value={'Yearly'}
								className="menu-item"
								sx={{
									':hover': {
										backgroundColor: 'rgba(0,128,0, 0.1)'
									},
									'&.Mui-selected': {
										backgroundColor: 'rgba(0,128,0, 0.1)',
										'&:hover': {
											backgroundColor: 'rgba(0,128,0, 0.1)'
										}
									}
								}}
							>
								Yearly
							</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			</div>
			

			<ExpensesChart period={period} type={type} />
		</div>
	)
}

export default Graphs
