import React, { useEffect, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import ButtonComponent from '../../shared/ButtonComponent'
import { dataUriToImg, getUserData } from '../../../utils/helper'
import { postSignature } from '../../../API/UserAPI'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../../../redux/snackbarState'

const AddSignature = ({ onClose }) => {
	const signRef = useRef(null)
	const dispatch = useDispatch()

	const [submitted, setSubmitted] = useState(false)

	const handleSubmit = async (e) => {
		e.preventDefault()
		setSubmitted(true)
		let file = dataUriToImg(
			signRef.current.getTrimmedCanvas().toDataURL('image/png')
		)

		let body = new FormData()
		body.append('signature', file)

		try {
			const res = await postSignature(body)

			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: 'Signature saved successfully.'
				})
			)
			getUserData(dispatch)
			setSubmitted(false)
			onClose()
		} catch (err) {
			setSubmitted(false)
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Unable to save successfully.'
				})
			)
		}
	}

	return (
		<div className="signature-canvas-container">
			<div className="d-flex align-items-center justify-content-between mb-3">
				<p className="fs-14 m-0">Create your signature to sign checks.</p>
				<ButtonComponent
					text="Clear Signature"
					type="button"
					variant="light"
					extraClass="me-3"
					onClick={() => {
						signRef?.current?.clear()
					}}
				/>
			</div>
			<div
				className="signature-canvas border-1"
				style={{
					border: '1px solid rgba(0,0,0,13%)',
					borderRadius: '6px',
					padding: '1rem',
					marginBottom: '2rem'
				}}
			>
				<SignaturePad
					ref={signRef}
					canvasProps={{
						style: { width: '100%', height: '270px' }
					}}
				/>
			</div>
			<div className="d-flex align-items-center justify-content-center pb-2">
				<ButtonComponent
					text="Cancel"
					type="button"
					variant="light"
					extraClass="me-3"
					onClick={onClose}
				/>
				<ButtonComponent
					text={submitted ? 'Saving...' : 'Save'}
					//disabled={signRef?.current === null ? true : false}
					type="submit"
					variant="dark"
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}

export default AddSignature
