import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import { AddOutlined, HelpOutlineOutlined } from '@mui/icons-material'

import ButtonComponent from '../components/shared/ButtonComponent'
import GenericTable from '../components/shared/GenericTable/GenericTable'
import { updateSnackbar } from '../redux/snackbarState'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { getAllPayments } from '../API/TransactionAPI'

const PaymentLinks = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [data, setData] = useState([])

	const [isLoading, setIsLoading] = useState(true)

	const [modifiedData, setModifiedData] = useState([])

	useEffect(() => {
		fetchpayments()
	}, [])

	const fetchpayments = async () => {
		try {
			const res = await getAllPayments()
			setData(res?.data || [])
			if (res?.data?.length !== 0) {
				makeTableData(res?.data)
			} else {
				setIsLoading(false)
			}
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Unable to fetch payments info.'
				})
			)
		}
	}

	const expandedColumnData = [
		{
			key: 'Purpose',
			value: 'purpose'
		},
		{
			key: 'Transaction ID',
			value: 'transactionId'
		}
	]

	const columnData = [
		{
			key: 'Recipient name',
			value: 'recipientName',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Recipient Email',
			value: 'recipientEmail',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Stripe Account ID',
			value: 'stripeAccountId',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Invoice',
			value: 'invoiceNumber',
			colWidth: '10%',
			align: 'center'
		},
		{
			key: 'Amount',
			value: 'amount',
			colWidth: '5%',
			align: 'center'
		},
		{
			key: 'Status',
			value: 'status',
			colWidth: '5%',
			align: 'center'
		}
	]

	const makeTableData = (data) => {
		const temp = []

		if (data && data.length !== 0) {
			data.forEach((item, index) => {
				let obj = {}

				obj = {}

				obj.invoiceNumber = item?.invoiceNumber

				obj.amount =
					item?.amount && item.amount - Math.floor(item.amount) !== 0
						? `$${item.amount}`
						: `$${item.amount}.00`

				obj.purpose = item?.purpose
				obj.stripeAccountId = item?.stripeAccountId
				obj.recipientName = item?.recipientName
				obj.recipientEmail = item?.recipientEmail
				obj.status = item?.status
				obj._id = item._id
				obj.transactionId = item.transactionId
				temp.push(obj)
			})

			setModifiedData([...temp])

			setIsLoading(false)
		} else {
			setModifiedData([])
			setIsLoading(false)
		}
	}

	return (
		<div className="container">
			<div className="page-header d-flex align-items-center justify-content-between mb-4 pb-4">
				<div className="d-flex align-items-center justify-content-start">
					<h3
						className="fs-5 fw-semibold m-0"
						style={{
							color: '#12221fbf'
						}}
					>
						My Payment Links
					</h3>
				</div>

				<Tooltip
					title={
						<span>
							Please contact at{' '}
							<a
								href="mailto:support@synccos.com"
								target="blank"
								rel="no-referrer"
								className="fw-semibold text-white"
							>
								support@synccos.com
							</a>{' '}
							for queries and support
						</span>
					}
				>
					<HelpOutlineOutlined />
				</Tooltip>
			</div>
			<div className=" d-flex align-items-center justify-content-between mt-4">
				<p className="regular-txt fs-14 mb-0">
					Below is the list of all your payment links.
				</p>
				<div className="d-flex align-items-center justify-content-center">
					<ButtonComponent
						text="New"
						variant="dark"
						icon={<AddOutlined />}
						type="button"
						click={() => {
							// history.push('/dashboard/create-payments')
							history.push('/dashboard/create-payment-links')
						}}
					/>
				</div>
			</div>
			<div className="generic-table-container">
				<GenericTable
					columnData={columnData}
					expandedColumnData={expandedColumnData}
					modifiedData={modifiedData}
					isLoading={isLoading}
					noDataProps={{
						text: 'All the payments created will be stored here. Create your first payment!',
						head: 'No Payments',
						click: () => {
							// history.push('/dashboard/create-payments')
							history.push('/dashboard/create-payment-links')
						},
						btnText: 'New Payment'
					}}
				/>
			</div>
		</div>
	)
}

export default PaymentLinks
