import { deleteRequest, getRequest, postRequest, putRequest } from "."

const addOrganization = async (body) => {
    const res = await postRequest('/users/user/createOrganization', body)
    return res
}

const getAllOrganization = async () => {
    const res = await getRequest('/users/user/getAllOrganizations')
    return res
}

const updateOrganization = async (id, body) => {
    const res = await putRequest(`/users/user/updateOrganization/${id}`, body)
    return res
}

const deleteOrganization = async (id) => {
    const res = await deleteRequest(`/users/user/deleteOrganization/${id}`)
    return res
}

export { addOrganization, getAllOrganization, updateOrganization, deleteOrganization } 