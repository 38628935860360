import {
	FormControl,
	MenuItem,
	OutlinedInput,
	Select,

	Modal,
	Box

} from '@mui/material'
import CheckStats from '../components/views/CheckStats'
import Graphs from '../components/views/Graphs'
import RecentChecks from '../components/views/RecentChecks'
import { useState } from 'react'

import { useLocation } from 'react-router'

import ButtonComponent from '../components/shared/ButtonComponent'

import appLogoDark from '../assets/images/app-logo-dark.png'
import { welcomeSvg } from '../assets/svg'


const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	borderRadius: '8px',
	width: '65vw'
}

const Dashboard = () => {
	const location = useLocation()


	const [type, setType] = useState('Total Expenses')
	const [showWelcomeModal, setShowWelcomeModal] = useState(
		location?.state?.showModal || false
	)

	return (
		<>
			<div className="container">
				<div className="page-header d-flex align-items-center justify-content-between mb-4 pb-4">
					<h3
						className="fs-5 fw-semibold m-0"
						style={{
							color: '#12221fbf'
						}}
					>
						Dashboard
					</h3>
					<FormControl sx={{ minWidth: 120 }} size="small">
						<Select
							id="graph"
							margin="normal"
							variant="outlined"
							input={<OutlinedInput style={{ borderColor: 'green' }} />}
							inputProps={{ 'aria-label': 'Without label' }}
							defaultValue={'Total Expenses'}
							sx={{
								'& .MuiSelect-select': {
									fontSize: '14px',
									minHeight: 'auto',
									padding: '7px 14px'
								}
							}}
							MenuProps={{
								sx: {
									'&.Mui-selected': {
										backgroundColor: '#EAFBE7'
									},
									'&.Mui-focused': {
										backgroundColor: '#FFF8DE'
									}
								}
							}}
							color="success"
							onChange={(e) => {
								setType(e.target.value)
							}}
						>
							<MenuItem
								value={'Total Expenses'}
								defaultChecked
								className="menu-item"
								sx={{
									':hover': {
										backgroundColor: 'rgba(0,128,0, 0.1)'
									},
									'&.Mui-selected': {
										backgroundColor: 'rgba(0,128,0, 0.1)',
										'&:hover': {
											backgroundColor: 'rgba(0,128,0, 0.1)'
										}
									}
								}}
							>
								View By:
								<span style={{ fontWeight: 'bold' }}> Expenses</span>
							</MenuItem>
							<MenuItem
								value={'Total Checks'}
								className="menu-item"
								sx={{
									':hover': {
										backgroundColor: 'rgba(0,128,0, 0.1)'
									},
									'&.Mui-selected': {
										backgroundColor: 'rgba(0,128,0, 0.1)',
										'&:hover': {
											backgroundColor: 'rgba(0,128,0, 0.1)'
										}
									}
								}}
							>
								View By:
								<span style={{ fontWeight: 'bold' }}> Checks</span>
							</MenuItem>
						</Select>
					</FormControl>
				</div>
				<CheckStats type={type} />
				<div className="row" style={{ marginTop: '50px' }}>
					<div className="col-6">
						{/* {console.log(type,"type")} */}
						<Graphs type={type} />
					</div>
					<div className="col-6">
						<RecentChecks />
					</div>
				</div>
			</div>
			<Modal
				open={showWelcomeModal}
				onClose={() => {
					setShowWelcomeModal(false)
				}}
			>
				<Box
					sx={{
						...modalStyle,
						'&:focus-visible': {
							outline: 'none'
						}
					}}
				>
					<div className="welcome-modal-container position-relative">
						<div className="logo position-absolute">
							<img src={appLogoDark} alt="app-logo" />
						</div>
						<div className="row m-0 p-0">
							<div
								className="col-12 col-md-6 col-lg-5 text-center"
								style={{
									borderRight: '1px solid #e3e5e8',
									padding: '5rem 0'
								}}
							>
								{/* <img src={welcomeModalImg} alt="welcome-img" /> */}
								{welcomeSvg}
							</div>
							{/* <div
								className="col-0 col-md-1"
								style={{
									borderLeft: '1px solid #e3e5e8'
								}}
							></div> */}
							<div
								className="col-12 col-md-6 col-lg-7 d-flex align-items-start justify-content-center flex-column"
								style={{
									padding: '0 5rem'
								}}
							>
								<h3 className="fs-3 fw-bold">Welcome to Synccos</h3>
								<p
									className="fs-14"
									style={{
										color: '#757575'
									}}
								>
									Thank you for being a part of our amazing new venture. We
									apologize for any performance issues as we build and cater to
									our early adopters' needs. Your feedback is crucial as we
									shape the future. Please sign in or sign up to join the
									discussion on how we can best suit your needs. Let's create
									something extraordinary together! <b>#ItsYourSynccos</b>
								</p>
								<ButtonComponent
									click={() => {
										setShowWelcomeModal(false)
									}}
									text="Continue"
									extraClass="mt-5"
									variant="dark"
								/>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	)
}

export default Dashboard
