import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GenericTable from '../components/shared/GenericTable/GenericTable'
import PageHeader from '../components/shared/PageHeader'
import AddBank from '../components/views/forms/AddBank'
import {
	AddOutlined,
	DeleteOutlineOutlined,
	EditOutlined,
	MoreVert
} from '@mui/icons-material'
import ButtonComponent from '../components/shared/ButtonComponent'
import { Menu, MenuItem, IconButton } from '@mui/material'
import { deleteBank } from '../API/BankAPI'
import { updateSnackbar } from '../redux/snackbarState'
import { getBanks } from '../utils/helper'
import FormModalMUI from '../components/shared/Modals/FormModalMUI'

const ContextMenu = ({ rowData }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [isEditBankModalOpen, setEditBankModalOpen] = useState(false)
	const [isModalOpen, setModalOpen] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)
	const [showWarning, setWarning] = useState(false)

	const [isDirty, setIsDirty] = useState(false)

	const setDirty = (dirty) => {
		setIsDirty(dirty)
	}

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const openEditBank = () => {
		setEditBankModalOpen(true)
		handleClose()
	}

	const closeEditBank = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setEditBankModalOpen(false)
			setWarning(false)
		}
	}

	const dispatch = useDispatch()

	const handleDeleteModal = () => {
		setModalOpen(!isModalOpen)
	}

	const deleteUserBank = async () => {
		if (!isSubmitting) {
			setSubmitting(true)
			try {
				const res = await deleteBank(rowData._id)

				dispatch(
					updateSnackbar({
						open: true,
						message: 'Record Deleted.',
						severity: 'success'
					})
				)
				getBanks(dispatch)
			} catch (error) {
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Unable to delete.',
						severity: 'error'
					})
				)
			}
			setSubmitting(false)
		}
	}

	return (
		<>
			<IconButton
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				sx={{
					minWidth: 300
				}}
			>
				<MenuItem
					sx={{
						display: 'flex',
						justifyContent: 'start',
						alignItems: 'center'
					}}
				>
					<button
						className="d-flex align-items-center justify-content-start w-100 p-0 border-0 bg-transparent"
						onClick={openEditBank}
					>
						<EditOutlined
							sx={{
								marginRight: '20px'
							}}
						/>
						Edit
					</button>
				</MenuItem>
				<MenuItem
					sx={{
						display: 'flex',
						justifyContent: 'start',
						alignItems: 'center'
					}}
					onClick={() => {
						handleClose()
						handleDeleteModal()
					}}
				>
					<DeleteOutlineOutlined
						sx={{
							marginRight: '20px'
						}}
					/>
					Delete
				</MenuItem>
			</Menu>

			<FormModalMUI
				title="Edit Bank Account Details"
				open={isEditBankModalOpen}
				maxWidth="md"
				onClose={() => closeEditBank(false)}
			>
				<AddBank
					onClose={closeEditBank}
					isEdit={true}
					bankData={rowData}
					setDirty={setDirty}
					warning={showWarning}
					setWarning={setWarning}
				/>
			</FormModalMUI>

			{isModalOpen && (
				<FormModalMUI
					onClose={handleDeleteModal}
					open={isModalOpen}
					maxWidth="sm"
					// onSave={onSubmit}
				>
					<div className="container">
						<div className="row">
							<div className="row txt-danger pt-3">
								<DeleteOutlineOutlined sx={{ fontSize: '80px' }} />
							</div>
							<div className="col d-flex justify-content-center">
								<div className="row">
									<h3>
										<p>
											<b>Confirm Deletion?</b>
										</p>
									</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col d-flex justify-content-center">
								<div className="row text-center">
									<p>
										Are you sure you want to delete bank details for{' '}
										<b>{rowData.accountName}</b> with account number:{' '}
										<b>{rowData.accountNumber}</b>?
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex align-items-center justify-content-center mt-3 mb-5">
						<ButtonComponent
							text="Cancel"
							type="button"
							variant="light"
							click={handleDeleteModal}
							extraClass="me-3"
						/>
						<ButtonComponent
							text={isSubmitting ? 'Deleting...' : 'Delete'}
							disabled={isSubmitting}
							type="submit"
							variant="danger"
							click={deleteUserBank}
						/>
					</div>
				</FormModalMUI>
			)}
		</>
	)
}

const Banks = () => {
	let banks = useSelector((state) => state.appData.banks)
	const [isLoading, setIsLoading] = useState(true)

	const [modifiedData, setModifiedData] = useState([])
	const [isAddBankModalOpen, setAddBankModalOpen] = useState(false)
	const [showWarning, setWarning] = useState(false)

	useEffect(() => {
		if (banks.data.length !== 0) {
			setIsLoading(true)
			makeTableData(banks.data)
		} else {
			setIsLoading(banks.isLoading)
			setModifiedData([])
		}
	}, [banks])

	const [isDirty, setIsDirty] = useState(false)

	const setDirty = (dirty) => {
		setIsDirty(dirty)
	}

	function amountFix(amount){
		return amount
			? amount < 0 && amount !== 0
				? `-$${Math.abs(amount).toFixed(2)}`
				: `$${amount?.toFixed(2)}`
			: `$${amount?.toFixed(2)}`
	}

	const makeTableData = (data) => {
		const temp = []
		if (data && data.length !== 0) {
			data.forEach((item, index) => {
				let obj = {}
				const accountNumber = item.accountNumber.toString()

				obj = { ...item }

				obj.accountNumber = (
					<p className="generic-table-data">
						{`XXXXXXX${accountNumber?.substring(
							accountNumber.length - 4,
							accountNumber.length
						)}`}
					</p>
				)
				obj.balance = `${amountFix(item.balance)}`;
				obj.contextMenu = (
					<>
						<ContextMenu
							rowData={item}
							setWarning={setWarning}
							setDirty={setDirty}
							showWarning={showWarning}
						/>
					</>
				)
				temp.push(obj)
			})

			setModifiedData([...temp])
			setIsLoading(false)
		} else {
			setModifiedData([])
			setIsLoading(false)
		}
	}

	const columnData = [
		{
			key: 'Name',
			value: 'accountName',
			colWidth: '10%'
		},
		{
			key: 'Nick Name',
			value: 'accountNickName',
			colWidth: '10%'
		},
		{
			key: 'Bank Name',
			value: 'bankName',
			colWidth: '10%'
		},
		{
			key: 'Account Number',
			value: 'accountNumber',
			colWidth: '10%'
		},
		{
			key: 'Available Balance',
			value: 'balance',
			colWidth: '10%'
		},
		{ key: 'Actions', value: 'contextMenu', colWidth: '9%' }
	]

	const openAddBank = () => {
		setAddBankModalOpen(true)
	}

	const closeAddBank = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setAddBankModalOpen(false)
			setWarning(false)
		}
	}

	return (
		<>
			<div className="container">
				<PageHeader
					text="Bank Accounts"
					info="Please contact at support@synccos.com for queries and support"
				/>
				<div className="generic-table-container">
					<div className="d-flex align-items-center justify-content-end">
						<ButtonComponent
							text="New"
							variant="dark"
							icon={<AddOutlined />}
							type="button"
							onClick={openAddBank}
							extraClass="ms-3"
						/>
					</div>
					<GenericTable
						columnData={columnData}
						modifiedData={modifiedData}
						count={banks?.data?.length || 0}
						isLoading={isLoading}
						noDataProps={{
							text: 'All the banks created will be stored here. Create your first bank!',
							head: 'No banks',
							click: () => {
								openAddBank()
							},
							btnText: 'New Bank'
						}}
					/>
				</div>
			</div>

			<FormModalMUI
				title="Add new bank account"
				open={isAddBankModalOpen}
				maxWidth="md"
				onClose={() => closeAddBank(false)}
			>
				<AddBank
					onClose={closeAddBank}
					setDirty={setDirty}
					warning={showWarning}
					setWarning={setWarning}
				/>
			</FormModalMUI>
		</>
	)
}

export default Banks
