import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormComponents from '../../shared/forms'
import FormButton from '../../shared/forms/FormButton'
import FormModal from '../../shared/Modals/FormModal'
import ButtonComponent from '../../shared/ButtonComponent'
import { addpayee, updatepayee } from '../../../API/PayeeAPI'
import { updateSnackbar } from '../../../redux/snackbarState'
import { useDispatch, useSelector } from 'react-redux'
import { getPayees } from '../../../utils/helper'
import countryData from '../../../utils/countryStateCity.json'
import { ErrorOutlineOutlined } from '@mui/icons-material'
import { AddOutlined } from '@mui/icons-material'
import { addDeposit } from '../../../API/CheckRegisterAPI'
import { addQuickBook } from '../../../API/IntegrationsAPI'
const AddQuickBook = ({
	onClose,
	isEdit,
	payeeData
	// setWarning,
	// setDirty,
	// warning
}) => {
	const dispatch = useDispatch()


	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)
	const userData = useSelector((state) => state.appData.userData)

	// console.log(userData)
//    console.log(selectedOrganization)
	// console.log(!selectedOrganization || selectedOrganization == "No Org"  ? "Not selected" : "Selected")
	const validationSchema = Yup.object({
		clientId: Yup.string().required('Required!'),
		clientsecret: Yup.string().required('Required!')

	})

	const banks = useSelector((state) => state.appData.banks)

	const onSubmit = async (values, { resetForm }) => {
		let body = {}

		body.clientId = values.clientId
		body.clientSecret = values.clientsecret
		if (selectedOrganization && selectedOrganization !== "No Org") {
			body.organizationId = selectedOrganization;
		} else {
			body.userId = userData.data._id; // Assuming userId is available in the scope
		}
   
		console.log(body,"body")

		try {
			const res = await addQuickBook(body)
			const { data } = res
			console.log(data,"Data is here")
			const url = data;
			if (url) {
				window.open(url, '_blank');
				// window.location.href = url;
			}
			close()
		}catch(err){
			const { response } = err
			const { data } = response
			dispatch(
				updateSnackbar({
					open: true,
					message:  'Unknown Error.',
					severity: 'error'
				})
			)
		}


	}

	const close = (forced) => {
		onClose(forced)
	}

	const bankOptions = banks?.data.map((bank, index) => {
		return { key: bank.bankName, value: bank._id }
	})


	return (
		<Formik
			initialValues={{
				clientId: '',
				clientsecret:  '',
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ handleSubmit, setFieldValue, values, dirty }) => (
				<Form onSubmit={handleSubmit} className="px-3 pt-3 pb-3">
					<div className="row">
						<div className="col-12 col-md-12">
							<div className="d-flex align-items-start justify-content-center w-100">
								<FormComponents
									name="clientId"
									type="text"
									label="Client Id"
									control="input"
									required
									autoComplete="off"
								/>
							</div>
						</div>
						<div className="col-12 col-md-12">
							<div className="d-flex align-items-start">
								<FormComponents
										name="clientsecret"
										label="Client Secret"
										control="input"
										type="password"
										required
										autoComplete="off"
									/>
							</div>
						</div>
					</div>
					<div className="d-flex flex-row align-items-center justify-content-evenly">
						<button
							type="button"
							className="common-btn bg-white light"
							onClick={() => {
								close(false)
							}}
						>
							Cancel
						</button>
						<ButtonComponent
							text={isEdit ? 'Save' : 'Add'}
							type="submit"
							variant="dark"
							click={handleSubmit}
						/>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default AddQuickBook
