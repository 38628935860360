import { getRequest, putRequest, postRequest, deleteRequest } from '.'

const updateUser = async (body) => {
	const res = await putRequest(`/users/user`, body)
	return res
}

const getUser = async () => {
	const res = await getRequest('/users/user')
	return res
}

const addAddress = async (body) => {
	const res = await postRequest('/users/user/address', body)
	return res
}

const updateAddress = async (body, id) => {
	const res = await putRequest(`/users/user/address/${id}`, body)
	return res
}

const getAllAddresss = async () => {
	const res = await getRequest('/users/user/address')
	return res
}

const deleteAddress = async (id) => {
	const res = await deleteRequest(`users/user/address/${id}`)

	return res
}

const updatePassword = async (body) => {
	const res = await postRequest('/auth/update-password', body)
	return res
}

const resetPassword = async (body) => {
	const res = await postRequest('/auth/reset-password')
	return res
}

const sendOTP = async (body) => {
	const res = await postRequest('/verify/email/send-otp', body)
	return res
}

const verifyOTP = async (body) => {
	const res = await postRequest('/verify/email/verify-otp', body)
	return res
}

const checkUser = async (email) => {
	const res = await getRequest(`/auth/user?email=${email}`)
	return res
}

const postSignature = async (body) => {
	const res = await postRequest('/users/user/signatureUpload', body)
	return res
}

const subscriptionAPI = async (id) => {
	const res = await getRequest(id ? `/managesubscription?isTrialPeriod=${id}` : '/managesubscription', {
    });
	return res
}


export {
	subscriptionAPI,
	updateUser,
	getUser,
	addAddress,
	updateAddress,
	deleteAddress,
	getAllAddresss,
	updatePassword,
	resetPassword,
	sendOTP,
	verifyOTP,
	checkUser,
	postSignature
}
