import React from 'react'
import { Link } from 'react-router-dom'

import appLogoDark from '../../assets/images/app-logo-dark.png'
import checkImg from '../../assets/images/checks.png'
import bannerImg from '../../assets/images/bannerImg.png'
import AuthRoutes from '../../routes/AuthRoutes'

const AuthLayout = () => {
	return (
		<div className="container-fluid p-0">
			<div className="auth-container">
				<div className="row m-0">
					<div className="col-12 col-md-5 p-0">
						{/* <div className="auth-banner">
							<div className="app-logo">
								<Link to="/">
									<img src={appLogoDark} alt="" />
								</Link>
							</div>
							<div className="text-center">
								<h1 className="auth-header">
									Smart and simple <br />
									check writer
								</h1>
								<p className="headline-txt mt-5">
									Create and print checks with ease using Synccheck.
								</p>
							</div>

							<img className="check-img" src={checkImg} alt="check-img" />
						</div> */}
						<div className="auth-banner1">
							{/*{/ <img  className="image-size"  src={bannerImg} alt="check-img" /> /}*/}
							<img className="image-size" src={bannerImg} alt="check-img" style={{ height: '100%',width:"100%",objectFit: 'content' }}/>
						</div>
						<div className="auth-banner1mobile">
							{/*{/ <img  className="image-size"  src={bannerImg} alt="check-img" /> /}*/}
							<img className="image-size" src={appLogoDark} alt="check-img" style={{ height: '100%',width:"100%",objectFit: 'content' }}/>
						</div>
					</div>
					<div className="col-12 col-md-7">
						<AuthRoutes />
					</div>
				</div>
			</div>
		</div>
	)
}

export default AuthLayout
