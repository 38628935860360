import { ArrowOutwardOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const StatsIndicator = ({ color, textColor, title, data }) => {
	const history = useHistory()

	const moveToMyChecks = () => {
		history.push('/dashboard/my-checks/' + title.replace(' ', ''))
	}

	return (
		<div
			style={{
				width: '350px',
				height: '130px',
				backgroundColor: color,
				color: textColor,
				borderRadius: '12px'
			}}
		>
			<div className="row justify-content-between">
				<div className="col-auto">
					<p
						style={{
							marginBottom: 0,
							fontSize: '12px',
							fontWeight: '600',
							marginTop: '20px',
							marginLeft: '20px',
							color: textColor,
							opacity: '50%'
						}}
					>
						{title}
					</p>
				</div>
				<div className="col-auto">
					<IconButton
						onClick={() => {
							moveToMyChecks()
						}}
						sx={{
							marginTop: '10px',
							marginRight: '10px'
						}}
					>
						<ArrowOutwardOutlined
							style={{ fontSize: '18px', color: textColor, opacity: '50%' }}
						/>
					</IconButton>
				</div>
			</div>
			<div className="row">
				<h1 style={{ color: textColor, marginLeft: '20px', fontWeight: '600' }}>
					{data}
				</h1>
			</div>
		</div>
	)
}

export default StatsIndicator
