import React from 'react'
import Cookies from 'js-cookie'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
	const isLoggedin = Cookies.get('user-status') ? true : false
	return (
		<Route
			{...rest}
			render={(props) =>
				isLoggedin === true ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/auth/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	)
}

export default PrivateRoute