import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSnackbar } from '../../../redux/snackbarState'
import { Formik, Field, ErrorMessage } from 'formik'
import FormComponents from '../../shared/forms'
import { ChromePicker, TwitterPicker } from 'react-color'
import ButtonComponent from '../../shared/ButtonComponent'
import { fetchGroups, fetchTags } from '../../../utils/helper'
import { Button, Icon, Popover } from '@mui/material'
import { Done, GridViewOutlined } from '@mui/icons-material'
import colorSwatch from '../../../assets/images/color-swatch.png'
import { addGroup, updateGroup } from '../../../API/GroupAPI'
import { updateTag } from '../../../API/TagsAPI'

const EditGroup = (props) => {
	const groupData = useSelector((state) => state.appData.groups)
	const [submitted, setSubmitted] = useState(false)
	const dispatch = useDispatch()
	const ref = useRef(null)

	const popover = {
		position: 'absolute',
		marginTop: '160px',
		marginLeft: '235px',
		zIndex: '8'
	}

	const cover = {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px'
	}

	const colors = [
		'#FFD480',
		'#FFB873',
		'#FF8C64',
		'#FF5733',
		'#C7F2A7',
		'#008000',
		'#41E2BA',
		'#80C1FF',
		'#6699FF',
		'#5489FF',
		'#9370DB',
		'#BA55D3',
		'#FF69B4'
	]

	const [selectedColor, setSelectedColor] = useState(
		props.edit ? props.groupColor : '#6699FF'
	)

	const [displayColorPicker, setDisplayColorPicker] = useState(false)
	const [changedColor, setChangedColor] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)

	const openColorPicker = () => {
		setDisplayColorPicker(true)
	}

	const close = (res) => {
		if (res != null) {
			props.onClose(res.data)
		} else {
			props.onClose(null)
		}
	}

	const initialValues = {
		group_name: props.edit ? props.groupName : ''
	}

	const validate = (values) => {
		const errors = {}

		if (!values.group_name) {
			errors.group_name = '*Group name is required'
		}

		if(groupData.data.some(obj => obj.name === values.group_name)) {
			errors.group_name = '*Group with same name already exists.'
		}

		return errors
	}

	const changeSelectedColor = (color) => {
		if (props.edit) {
			if (props.color !== selectedColor) {
				setChangedColor(true)
			} else {
				setChangedColor(false)
			}
		}
		setSelectedColor(color)
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSubmit = async () => {
		if (!submitted) {
			setSubmitted(true)
			let group_name = ref.current.values['group_name']
			let color = selectedColor
			let body = {
				name: group_name,
				color: color
			}

			try {
				let res
				res = props.edit
					? await updateGroup(props.groupId, body)
					: await addGroup(body)
				let group_id = res?.data?._id
				if (!props?.edit) {
					console.log(group_id)
					let body = {
						group: group_id
					}
					console.log(body)
					let res = await updateTag(props.tag._id, body)
					console.log(res)
				}

				dispatch(
					updateSnackbar({
						open: true,
						severity: 'success',
						message: `${'Group added successfully.'}`
					})
				)
				fetchGroups(dispatch)
				close(res)
			} catch (err) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'error',
						message: err.response.data.error.userMessage
							? err.response.data.error.userMessage
							: 'Unable to update group.'
					})
				)
			}
			setSubmitted(false)
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={handleSubmit}
			innerRef={ref}
		>
			{({ handleSubmit, values, dirty, isValid }) => (
				<div className="container p-5">
					<div className=" d-flex align-items-start flex-row">
						<Button
							className="color-box me-3 d-flex align-items-center justify-content-center"
							onClick={handleClick}
							sx={{
								minWidth: 'auto'
							}}
						>
							<div className="grid-view-icon">
								<div
									className="dot"
									style={{
										backgroundColor: selectedColor || 'transparent'
									}}
								></div>
								<div
									className="dot"
									style={{
										backgroundColor: selectedColor || 'transparent'
									}}
								></div>
								<div
									className="dot"
									style={{
										backgroundColor: selectedColor || 'transparent'
									}}
								></div>
								<div
									className="dot"
									style={{
										backgroundColor: selectedColor || 'transparent'
									}}
								></div>
							</div>
							{/* <GridViewOutlined
								style={{
									color: selectedColor,
									fontSize: '22px',
									fontWeight: 'bold'
								}}
								className="group_color_picker"
								onClick={openColorPicker}
							/> */}
						</Button>
						<Popover
							id="simple-popover"
							open={anchorEl !== null ? true : false}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
						>
							<TwitterPicker
								colors={colors}
								onChange={(color) => {
									changeSelectedColor(color.hex)
									handleClose()
								}}
								color={selectedColor}
							/>
						</Popover>
						<div className="col-auto" style={{ width: '420px' }}>
							<FormComponents
								name="group_name"
								type="text"
								label="Group Name"
								control="input"
							/>
						</div>
					</div>
					<div className="row">
						<div className="row">
							<div className="d-flex align-items-center justify-content-center mt-3 mb-0">
								<ButtonComponent
									text="Cancel"
									type="button"
									variant="light"
									extraClass="me-3"
									onClick={() => {
										close(null)
									}}
								/>
								<ButtonComponent
									text={submitted ? 'Saving...' : props.title}
									type="submit"
									variant="dark"
									disabled={!((dirty && isValid) || changedColor)}
									onClick={handleSubmit}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	)
}

export default EditGroup
