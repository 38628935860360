import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import FormComponents from '../../shared/forms'

import { updateSnackbar } from '../../../redux/snackbarState'
import ButtonComponent from '../../shared/ButtonComponent'
import { updatePassword } from '../../../API/UserAPI'

const UpdatePassword = () => {
	const dispatch = useDispatch()

	const userData = useSelector((state) => state.appData.userData)
	const [submitted, setSubmitted] = useState(false)

	const validationSchema = Yup.object({
		oldPassword: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			)
			.required('Required!'),
		confirmPassword: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			)
			.required('Required!'),
		newPassword: Yup.string()
			.min(8, 'Password must be at least 8 characters')
			.max(16, 'Password must be less than or equal to 16 characters')
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
				'Password must contain at least one letter, one number and one special character'
			)
			.required('Required!')
	})

	const initialValues = {
		oldPassword: '',
		confirmPassword: '',
		newPassword: ''
	}

	const onSubmit = async (values) => {
		if (!submitted) {
			setSubmitted(true)
			if (values.newPassword === values.oldPassword) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'warning',
						message: 'Old and New password must be different.'
					})
				)
				return false
			}

			if (values.newPassword !== values.confirmPassword) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'warning',
						message: 'Password Mismatch.'
					})
				)
				return false
			}

			let body = { ...values }
			body.email = userData.data.email
			delete body.confirmPassword

			try {
				const res = await updatePassword(body)
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'success',
						message: 'Password update successfully.'
					})
				)
			} catch (error) {
				dispatch(
					updateSnackbar({
						open: true,
						severity: 'error',
						message: 'Fail to update pasword.'
					})
				)
			}

			setSubmitted(false)
		}
	}

	return (
		<>
			<div className="container-fluid p-0 mt-5">
				<h4 className="fs-6 fw-semibold text-black mt-4 mb-2">
					Password Reset
				</h4>
				<p className="fs-14 mb-3">
					To change your password, fill out the two password fields.{' '}
				</p>
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ dirty, values }) => {
					return (
						<Form>
							<div className="row">
								<div className="col-12 col-md-4">
									<FormComponents
										name="oldPassword"
										label="Old Password"
										control="input"
										type="password"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4">
									<FormComponents
										name="newPassword"
										label="New Password"
										control="input"
										type="password"
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4">
									<FormComponents
										name="confirmPassword"
										label="Confirm Password"
										control="input"
										type="password"
									/>
								</div>
							</div>

							<div className="d-flex align-items-center justify-content-end mt-3 mb-5">
								<ButtonComponent
									text="Update Password"
									type="submit"
									variant="dark"
									click={() => onSubmit(values)}
									disabled={(dirty || submitted) ? false : true}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	)
}

export default UpdatePassword
