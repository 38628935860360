import React from 'react'
import { useHistory } from 'react-router'
import ButtonComponent from '../components/shared/ButtonComponent'
import noInternetImg from '../assets/images/noInternetImg.png'

const NoInternetConnection = () => {
	const history = useHistory()

	return (
		<div className="container-fluid p-0 m-0 position-relative">
			<div
				className="d-flex align-items-center justify-content-center flex-column"
				style={{
					height: '100vh'
				}}
			>
				<img
					className="not-found-img mb-4"
					src={noInternetImg}
					alt="not-found"
				/>
				<h2
					className="fs-3"
					style={{
						color: '#212121'
					}}
				>
					Page Not found
				</h2>
				<p
					className="fs-6"
					style={{
						color: '#757575'
					}}
				>
					The page/data you were searching for couldn’t be found.
				</p>
				<ButtonComponent
					text={'Return to home'}
					variant="dark"
					type="button"
					click={() => {
						history.push('/')
					}}
				/>
			</div>
		</div>
	)
}

export default NoInternetConnection
